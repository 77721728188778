import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../../components/utilities/cookie/cookie";
import { getAllFavourites } from "../../../redux/actions/customer";
import { Helmet, HelmetProvider } from "react-helmet-async";

import UserLayout from "../../../components/layout/UserLayout";
import Heading from "../../../components/utilities/Heading";
import RestuantContent from "../../Home/Restaurants/ResturantContent";

export default function UserFavourite({ setOpenModal, restaurant }) {
  const dispatch = useDispatch();
  const token = getCookie("token");
  const customerData = JSON.parse(localStorage.getItem("customer"));
  const id = customerData._id;
  useEffect(() => {
    getAllFavourites(dispatch, id, token);
  }, [dispatch, id, token]);
  const { customer: favouriteRestaurant } = useSelector((state) => state.customer);
console.log(favouriteRestaurant);
  return (
    <HelmetProvider>
        <Helmet>
            <title>FoodApps | Favourite</title>
        </Helmet>
        <UserLayout>
          <Heading>Favourite</Heading>
          <div className="w-full p-4 shadow-md">
            <div className="bg-white rounded-lg w-full overflow-hidden">
              {favouriteRestaurant && favouriteRestaurant.length > 0 ?
                favouriteRestaurant.map((element, index) => (
                  <div className="flex flex-wrap justify-between items-center border-solid border-2 border-gray-400 rounded shadow-lg mt-5">
                    <RestuantContent
                      restaurant={element}
                      key={index}
                    />
                    {/* <FavouriteRestaurantContent element={element} key={index} />
                    <RestuantButton setOpenModal={setOpenModal} restaurant /> */}
                  </div>
                )
                ): null}

              {/* <div className="flex flex-wrap justify-between items-center border-solid border-2 border-gray-400 rounded shadow-lg mt-5">
                <FavouriteRestaurantContent />
                <RestuantButton setOpenModal={setOpenModal} restaurant />
              </div> */}
            </div>
          </div>
        </UserLayout>
    </HelmetProvider>
  );
}
