import React from "react";
import { FiUser } from "react-icons/fi";

const CheckoutTimeSchedule = () => {
  return (
    <div>
      <div>
        <div className="flex items-center px-2">
          <FiUser className="rounded-full w-10 h-10 flex items-center justify-center bg-prim text-white p-2" />
          <div className="px-4 flex-grow">
            <p className="text-lg sm:text-2xl md:text-2xl">Time Schedule</p>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-5">
        <div className="bg-nat md:p-8 p-4">
          <h3 className="font-semibold">Normal Delivery</h3>
          <h3 className="text-gray-700">45 Mins</h3>
        </div>
        <div className="bg-nat md:p-8 p-4 mx-2">
          <h3 className="font-semibold">Busy Time</h3>
          <h3 className="text-gray-700">60 Mins</h3>
        </div>
        <div className="bg-nat md:p-8 p-4">
          <h3 className="font-semibold">Extreme Busy</h3>
          <h3 className="text-gray-700">90 Mins</h3>
        </div>
      </div>
    </div>
  );
};

export default CheckoutTimeSchedule;
