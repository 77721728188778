import React from 'react'

export default function RoundedImage(props) {
    return (
        <div style={props.containerStyle} className={`max-w-fit h-16 md:h-28 m-2 p-2 rounded-lg box-border  shadow-lg shadow-full border-2 border-gray-100 hover:border-prim  hover:shadow-inner ${props.containerClasses}`}>
            <div style={props.imgContainerstyle} className={`w-full h-full rounded-lg bg-white overflow-hidden  ${props.imgContainerClasses}`}>
                <img style={props.imgStyle} className={`max-w-full mx-auto h-full object-cover ${props.imgClasses}`} src={props.src} alt={props.alt} />
            </div>
        </div>
    )
}            
