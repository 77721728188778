import {
    GET_NOTIFICATION_FAILURE,
    GET_NOTIFICATION_SUCCESS,
    GET_ALL_NOTIFICATIONS_SUCCESS,
    DELETE_NOTIFICATION_SUCCESS,
    REMOVE_NOTIFICATION_SUCCESS
} from "../types/notification/type";

const initialState = {
    notification: {},
    notifications: [],
    status: null,
    error: "",
    loading: true,
    message: "",
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                notification: action.payload,
                error: "",
            };
        case REMOVE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                message: action.payload,
                error: "",
            };
        case GET_ALL_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                notifications: action.payload,
                error: "",
            };
        case DELETE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                error: "",
                notifications: action.payload
            };

        case GET_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                status: "Failed",
                error: action.payload
            };
        default:
            return state;
    }
};

export default reducer;
