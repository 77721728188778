import React, { useState } from "react";
import { FiUser, FiEdit2, FiX } from "react-icons/fi";
import Icon from "./../utilities/Icon";
import CheckoutCustomerInfo from "./CheckoutCustomerInfo";

export default function CheckoutHeader({ title, order, setOrder }) {
  const [isEdit, setIsEdit] = useState(false);
  return (
    <div>
      <div className="flex items-center px-2">
        <FiUser className="rounded-full w-10 h-10 flex items-center justify-center bg-prim text-white p-2" />
        <div className="px-4 flex-grow">
          <p className="text-lg sm:text-2xl md:text-2xl">{title}</p>
        </div>
        {!isEdit  && (
          <Icon onClick={() => setIsEdit(true)} icon={FiEdit2} />
        )}

        {isEdit && (
          <Icon onClick={() => setIsEdit(false)} icon={FiX} />
        )}
      </div>
      <CheckoutCustomerInfo setIsEdit={setIsEdit} isEdit={isEdit} order={order} setOrder={setOrder} />
    </div>
  );
}
