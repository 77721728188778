import React from 'react';

import ProfileInfoCard from '../../../components/utilities/cards/ProfileInfoCard';
import Heading from '../../../components/utilities/Heading';
import UserAddressUpdate from './UserAddressUpdate';

export default function UserProfileAddress({customer}) {
    
    return (
        
        <>
            <Heading classes="mt-16">Delivery Address</Heading>   
            <div className="sm:flex md:flex">
                {
                    customer.address?(
                        <ProfileInfoCard
                        title={customer.address.addressType}
                        value={[customer.address.address, customer.address.postCode]}
                        />
                    ):(
                        <ProfileInfoCard
                        title={"Set Address Title"}
                        value={["Set Address", "Set Post Code"]}
                        />
                    )
                }

                <UserAddressUpdate customer={customer}/>

            </div>
        </>
    )
}