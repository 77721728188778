import React, { useState } from "react";
import CheckoutHeader from "./../../../components/checkout/CheckoutHeader";
import CheckoutTimeSchedule from "./CheckoutTimeSchedule";
import CheckoutContact from "./CheckoutContact";
import { useHistory } from 'react-router-dom';

const CheckoutBookATable = () => {
  const history = useHistory();
  const restaurantId = JSON.parse(localStorage.getItem('order')).restaurant;
  const [order, setOrder] = useState(JSON.parse(localStorage.getItem('order')));
  return (
    <div className="bg-nat">
      <div className="mx-12 py-5">
        <button
          className="bg-prim px-5 py-2 rounded text-white"
          onClick={() => history.push("/order/" + restaurantId)}
        >
          Back
        </button>
      </div>
      <div className="py-16 sm:px-32 md:px-32">
        <div className="w-full sm:flex md:flex">
          <div className="w-full sm:w-2/3 md:w-2/3 h-full p-6">
            <div className="w-full h-full">
              <div className=" flex items-center">
                <div className="w-full text-center">
                  <h1 style={{ fontSize: "2em" }} className="mb-4">
                    Book a table
                  </h1>
                </div>
              </div>
              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                <CheckoutHeader title="Customer Name" />
              </div>
              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                <CheckoutTimeSchedule />
              </div>
              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                <CheckoutContact order={order} setOrder={setOrder} />
              </div>
              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                <button className="mt-6 bg-prim text-white w-full rounded p-2">
                  Proceed to book a table
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutBookATable;
