import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import OutsideClickHandler from 'react-outside-click-handler';

import { FiLogIn, FiShoppingCart } from "react-icons/fi";
import { getCookie } from '../../../../../components/utilities/cookie/cookie';
import { BiLogOut } from "react-icons/bi";

import Icon from '../../../../../components/utilities/Icon';
import ContactUsButton from './ContactUsButton';
import CustomerMenu from './CustomerMenu';
import PartnerOrCustomer from './PartnerOrCustomer';
import NotificationsButton from '../../../../../components/utilities/buttons/NotificattionsButton';
import { useJwt } from 'react-jwt';
import { customerLogOut } from '../../../../../redux/actions/customer';
import { useDispatch } from 'react-redux';

export default function NormalNavbarMenu() {
    const [openSubmenu, setOpenSubmenu] = useState(false);
   const {isExpired}= useJwt(getCookie('token'))
    const history = useHistory();
    const dispatch=useDispatch();
    const logout = () => {
        customerLogOut(dispatch,history)
        
    }

    const name = localStorage.getItem('customer') ? JSON.parse(localStorage.getItem('customer'))?.name?JSON.parse(localStorage.getItem('customer'))?.name.split(" ")[0] : "Profile" : "Profile"

    const cart = JSON.parse(localStorage.getItem('cart'));
    const uniId =  JSON.parse(localStorage.getItem('order'))?.restaurantUniId;
    const handleCart = () => {
        if(cart.foodItems.length > 0) {
            history.push('/order/' + uniId)
        } else {
            alert("You have no items in your cart!");
        }
    }
    return (
        <div className="flex items-center pl-10">
           <ContactUsButton />
            <PartnerOrCustomer />
            <Link to="/sign-in" className={(!isExpired) ? "hidden" : "block"} >
                <FiLogIn className="inline text-prim" /> <span className='font-semibold '>Login</span>
            </Link>
            <button className={(!isExpired) ? "block" : "hidden"} onClick={logout}><BiLogOut className="inline text-prim" /> Logout</button>
            {
                (!isExpired)? (
                    <div className="px-4">
                        <div className="flex items-end">
                            <NotificationsButton iconColor="text-prim" />
                            <div className="relative flex items-center">
                                <div onClick={() => setOpenSubmenu(!openSubmenu)} className="flex">
                                    <p className="cursor-pointer text-prim hover:text-red-900 trans">{name}</p>
                                </div>
                                {openSubmenu &&
                                    <OutsideClickHandler
                                        onOutsideClick={() => {
                                            setOpenSubmenu(false)
                                        }}
                                    >
                                        <CustomerMenu />
                                    </OutsideClickHandler>
                                }
                            </div>
                        </div>
                    </div>
                ) : null
            }
        
            {cart &&
                <div onClick={handleCart} className="px-1 sm:px-3 md:px-3 cursor-pointer">
                    <div className="relative">
                        {cart.foodItems.length > 0 && 
                        <div className="bg-prim w-3 h-3 flex items-center justify-center absolute p-2 top-0 right-0 rounded-full -mt-2 -mr-2 text-white text-xs">
                            {cart.foodItems.length}
                        </div>}
                        <Icon icon={FiShoppingCart} />
                    </div>
                </div>
                }
        </div>
    )
}