import React, { useEffect } from "react";
//
import { Helmet, HelmetProvider } from "react-helmet-async";
//
import Booking from "../../../components/booking";
import UserLayout from "../../../components/layout/UserLayout";
import Heading from "../../../components/utilities/Heading";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerBookingOrders } from "../../../redux/actions/order";
import { getCookie } from "../../../components/utilities/cookie/cookie";

export default function UserMyBooking() {

  const { orders } = useSelector(state => state.order);
  const dispatch = useDispatch();
  const customer = JSON.parse(localStorage.getItem("customer"));
  const customerId = customer._id;
  const token = getCookie('token');

  useEffect(() => {
    getCustomerBookingOrders(dispatch, customerId, token)
  }, [dispatch, customerId, token])

  return (
    <HelmetProvider>
      <Helmet>
        <title>FoodApps | My Bookings</title>
      </Helmet>
      <UserLayout>
        <Heading>My Bookings</Heading>
        <div>
          <div className="mt-4">
            <div className="sm:flex md:flex items-center justify-between">
              <Link className="bg-prim text-white text-sm px-3 py-2 font-semibold rounded-lg" to="/">
                New Booking
              </Link>
            </div>
          </div>
          <div className="mt-4 w-full">
            {orders && orders.length > 0 ? (
              orders.map((el, index) => (
                <Booking booking={el} key={index} />
              ))
            ) : (
              <span>No Booking is found</span>
            )}
          </div>
        </div>

      </UserLayout>
    </HelmetProvider>
  );
}
