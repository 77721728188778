import React from 'react';

export default function ErrorMessage({ classes, message }) {
    return (
        <div className={`py-3 px-4 rounded-lg bg-prim text-white  flex items-center justify-between ${classes}`}>
            <p className="font-semibold">{message}</p>
            {/* <Icon onClick={() => setClose(true)} icon={FiX} color="text-white" /> */}
        </div>
    )
}
