import React from "react";

import { FiStar, FiShoppingBag } from "react-icons/fi";
import { RiEBike2Line } from "react-icons/ri";
import { GoLocation } from "react-icons/go";
import { useSelector } from "react-redux";
import { IoIosRestaurant } from "react-icons/io";
import { BsClockFill } from "react-icons/bs";

export default function RestaurantDeliveryInfo({ restaurant }) {
  const { cuisines } = useSelector((state) => state?.cuisines);
  let cuisineType = [];
  const cuisineId = restaurant?.cuisines?.length;
  for (let i = 0; i < cuisineId; i++) {
    const findCuisine = cuisines?.find(
      (item) => item._id === restaurant?.cuisines[i]
    );
    cuisineType = [...cuisineType, findCuisine];
  }

  return (
    <div className="truncate px-2 w-full flex justify-center">
      <div className="flex flex-col">
        <div className="mx-auto md:mx-0 flex ">
          {restaurant.speciality?.map((special, i) => (
            <div key={i} className="flex items-center ml-1">
              <FiStar className="text-yellow-600" />
              <p className="text-sm text-gray-700 mt-1"> {special}</p>
            </div>
          ))}
        </div>
        <div className="mx-auto md:mx-0 flex ">
          {cuisineType?.map((cuisine, i) => (
            <div key={i} className="flex items-center ml-1">
              <IoIosRestaurant className="text-yellow-600" />
              <p className="text-sm text-gray-700 mt-1"> {cuisine?.type}</p>
            </div>
          ))}
        </div>
        <div className="w-full text-prim flex items-center mt-2">
          <FiShoppingBag className="text-prim" />
          <p className="px-2 text-xs font-bold">
            {restaurant.restaurantTiming &&
              restaurant.restaurantTiming.restaurantTiming.collectionTime}{" "}
            Min
          </p>
          <RiEBike2Line className="text-prim" />
          <p className="px-2 text-xs font-bold">
            {restaurant?.restaurantDelivery?.deliveryCharge
              ? "£" + restaurant.restaurantDelivery.deliveryCharge
              : "Free"}
          </p>
          <BsClockFill />
          <p className="px-2 text-xs font-bold">
            {restaurant.restaurantTiming &&
              restaurant.restaurantTiming.restaurantTiming.deliveryTimes}{" "}
            Min
          </p>
          {restaurant?.restaurantDelivery?.isDeliveryAvailable && <>
            <GoLocation className="text-prim text-sm" />
            <p className="px-2 text-xs font-bold">
              {restaurant.restaurantDelivery &&
                restaurant.restaurantDelivery.areaLimit}{" "}
              Mile
            </p>
          </>
          }
        </div>
      </div>
    </div>
  );
}
