import React from "react";
import { useHistory } from 'react-router-dom'

import Modal from "react-modal";
import { useSelector } from "react-redux";

import Heading from "../../../components/utilities/Heading";
import OrderTimeLine from "./OrderTimeLine";
import OrderSummuryItems from "./OrderSummuryItems";
import { customStyles } from "../../../components/layout/PopupLayout";

export default function OrderSummaryPopup({ openModal, setOpenModal }) {
	const history = useHistory();
    const { order } = useSelector(state => state.order)
	
	let cart = order?.cartItems ? JSON.parse(order.cartItems) : {};
	
    if(openModal) {
        setTimeout(() => {            
            history.push("/");
        }, 5000)
    }
    
    return (
        <Modal
            isOpen={openModal}
            style={customStyles}
            className="w-full outline-none absolute border border-none z-100"
            ariaHideApp={false}
            >
            <div className="w-full h-screen">
                <div className="w-full h-full flex items-center justify-center">
                    <div className="w-full md:w-9/12 bg-white rounded-lg mx-2 p-3 md:p-6 mt-10 md:mt-4"
                    >
                <Heading>Order Details</Heading>
				<div className="flex justify-between">
					<div className="flex items-end">
						<p className="mt-5 font-semibold ">
						{order.orderNumber} <span className="text-gray-600 ml-5 text-sm ">Order Type:</span>
							<span className="text-red-600 ml-2 text-sm">{order.orderType}</span>
						</p>
						<p className="mt-5 font-semibold ">
							<span className="text-gray-600 ml-5 text-sm ">Payment Type:</span>
							<span className="text-red-600 ml-2 text-sm">{order.paymentMethod}</span>
						</p>
						
					</div>
					{order?.orderType !== "Dine In" &&
						<OrderTimeLine order={order} />}
				</div>

				<div className="mt-5 border-b-2 border-gray-400" />

				<div className="flex justify-between mt-5">
					<div>
					{order?.orderType !== "Dine In" &&
					<>
						<p className="font-semibold text-xl pb-1">{order.restaurant?.name}</p>
						{/* <p>{order?.tableNo}</p> */}
						<p className="font-medium">
							{order.restaurant?.address?.address}
						</p>
					</>}
					</div>
					<div className="">
						<p className="font-semibold text-xl text-right ">{order?.customerName}</p>
						<p className="font-semibold text-xl text-right ">{order?.mobileNo}</p>
						<p className="font-semibold text-right"><span className="text-gray-600">Table NO: </span>{order?.tableNo}</p>
						<p className="font-semibold text-right text-sm text-gray-600">{order.orderStatus}</p>
					</div>
				</div>

				{order.kitchenNotes ? (
					<p className="mt-5 text-sm font-semibold">Kitchen Notes: <span className="text-gray-600">{order.kitchenNotes}</span></p>
				) : null}

				{cart.foodItems && cart.foodItems.length > 0 ? (
					cart.foodItems.map((el, index) => (
						<OrderSummuryItems value={el.price} itemName={el.name} key={index} quantity={el.quantity} />
					))
				) : null}

				<div className="mt-5 border-b-2 border-gray-600" />

				<OrderSummuryItems value={order.subTotal} itemName="Sub Total" quantity={0} />

				{
					order.discount ? <OrderSummuryItems value={order.discount?.discountValue} itemName="Discount" isNegative={true} quantity={0} /> : null
				}
				{
					order.coupon && <OrderSummuryItems itemName="Coupon" value={order.coupon?.amount} quantity={0} isNegative={true} />
				}
				<OrderSummuryItems value={order.serviceCharge} quantity={0} itemName="Service Charge" />

				<OrderSummuryItems value={order.deliveryCharge} quantity={0} itemName="Delivery Charge" />


				<div className="mt-5 border-b-2 border-gray-600" />

				<OrderSummuryItems value={order.totalPrice} itemName="Total" quantity={0} />

                    </div>
                </div>
            </div>
        </Modal>
    )
}