import React from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useHistory } from "react-router";

const Faq = () => {
  const history = useHistory();
  return (
    <div className="p-10">
      <div className="mx-12 py-5 flex justify-between">
        <button
          className="bg-prim px-5 py-2 rounded text-white cursor-pointer"
          onClick={() => history.push("")}>
          <FiArrowLeft className="inline-block mr-1" />
          Back</button>
          
        <div></div>
      </div>
      <div>
        <h1 className="text-center text-4xl font-bold">FAQs</h1>
      </div>
      <h3 className="text-center text-2xl font-bold">(USER)</h3>
      <p className="px-12 py-3 text-base md:text-lg">
        1. How may I know the progress of my ordered delivery? <br />
        Answer: After placing an order there in your order details the tracker
        will show the real-time progress of order received, on the way &
        delivered status.
        <br />
        <br />
        2. If I don’t receive my delivery how may I complain? <br />
        Answer: From the contact us button on our web you can open a ticket by
        mentioning your order number.
        <br />
        <br />
        3. How to cancel an order? <br />
        Answer: After an order is placed you can cancel that before it is
        accepted by the restaurant/ takeaway. You can get help from live support
        regarding this issue.
        <br />
        <br />
        <h3 className="text-center text-2xl font-bold">(PARTNER)</h3>
        1. If the rider doesn’t show up in time what to do? <br />
        Answer: You can wait or reassign it. And contact with the customer that
        there might be a little delay. For more help contact live support.
        <br />
        <br />
        2. How to cancel if an out of stock order is accepted mistakenly? <br /> 
        Answer: First,
        turn off the item from the menu list. Then contact live support to
        cancel the mistakenly accepted order.
      </p>
    </div>
  );
};

export default Faq;
