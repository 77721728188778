import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import UserLayout from '../../../components/layout/UserLayout';
import Button from '../../../components/utilities/buttons/Button';
import Heading from '../../../components/utilities/Heading';
import MyOrder from '../../../components/myOrder'

import { getCustomerOrders } from '../../../redux/actions/order';
import { getCookie } from '../../../components/utilities/cookie/cookie';


export default function UserMyOrder() {

    const customer = JSON.parse(localStorage.getItem("customer"));

    const customerId = customer._id;

    const dispatch = useDispatch();

    const token = getCookie("token");

    useEffect(() => {
        getCustomerOrders(dispatch, customerId, token)
    }, [dispatch, customerId, token])

    const { orders } = useSelector(state => state.order)
    return (
        <HelmetProvider>    
            <Helmet>
                <title>FoodApps | My Orders</title>
            </Helmet>
            <UserLayout>
                <Heading>My Orders</Heading>
                <div className="mt-4">
                    <div className="sm:flex md:flex items-center justify-between">
                        <Link to="/home">
                            <Button classes="bg-prim text-white text-sm px-4" text="Order Now" />
                        </Link>
                    </div>
                </div>
                <div className="flex flex-wrap items-center mt-4">

                    {orders && orders.length > 0 ? (
                        orders.map((order, index) => (
                            <MyOrder key={index} order={order} />
                        ))
                    ) : <span className="text-red-700 font-bold text-lg">No orders is found!</span>
                    }
                </div>
            </UserLayout>
        </HelmetProvider>
    )
}