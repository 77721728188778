import React, { useState } from 'react'
import { useHistory, Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { FiMail, FiLock } from 'react-icons/fi';

import { getCustomerLogin } from '../../redux/actions/customer';

import Image from "../../components/utilities/images/Image";
import LoginFormHeader from "./LoginFormHeader";
import LoginFormFooter from "./LoginFormFooter";
import ErrorMessage from "../../components/utilities/form/ErrorMessage";

const SignIn = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [formData, setFormData] = useState({
        credential: "",
        password: "",
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        await getCustomerLogin(dispatch, formData, history);
    }


    const { authError } = useSelector(state => state.customer);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    return (
        <div>
            <div className="w-full h-screen bg-nat">

                <div className="relative w-full h-full flex items-center justify-center">

                    <div className="absolute top-0 right-0 w-4/12 mt-10 hidden sm:block md:block">
                        <Image
                            src="https://foodapps.uk/images/backgrounds/food4.png"
                            alt=""
                        />
                    </div>
                    <div className="absolute bottom-0 left-0 w-4/12 mt-10 hidden sm:block md:block">
                        <Image
                            src="https://foodapps.uk/images/backgrounds/food1.png"
                            alt=""
                        />
                    </div>

                    <div className="rounded-lg w-full mx-4 sm:mx-0 md:mx-0 p-4 bg-white shadow-md sm:1/3 md:w-1/3">
                        {authError && <ErrorMessage message={authError} />}
                        <LoginFormHeader />

                        <form className="py-2" onSubmit={handleSubmit}>
                            <div id="sign-in-button"></div>
                            <div className="py-2">
                                <div className="bg-white shadow flex items-center p-3 rounded-lg">
                                    <FiMail className="text-lg" />
                                    <input name="credential" onChange={handleChange} value={formData.credential} type="text" className="outline-none px-3 w-full" placeholder="Mobile Number Or Email" />

                                </div>
                                <div className="bg-white shadow flex items-center p-3 rounded-lg mt-3">
                                    <FiLock className="text-lg" />
                                    <input name="password" onChange={handleChange} value={formData.password} type="password" className="outline-none px-3 w-full" placeholder="Password" />

                                </div>

                            </div>
                            <button
                                type="submit"
                                className="w-full font-semibold rounded-lg py-3 px-6 bg-red-700 text-white outline-none mt-1"
                            >
                                Login
                            </button>
                            <div className='w-full'>
                                <Link to="/guest-sign-up"
                                    className="block w-full font-semibold rounded-lg py-3 px-6 bg-red-200 text-red-700 border-red-700 border-2 outline-none mt-3 hover:bg-red-500 hover:text-white transition text-center"
                                >
                                    Join As A Guest
                                </Link>
                            </div>
                        </form>
                        <LoginFormFooter type="signup" />
                        <div>
                        <div className="text-center text-gray-700 text-xs font-bold">
                            <Link to="/forget-password" className="cursor-pointer text-prim font-bold outline-none">Reset
                            </Link>
                            <span> Your Password</span>
                        </div>
                        </div>
                    </div>

                    <div className="absolute bottom-0 right-0 w-4/12 mt-10 hidden sm:block md:block">
                        <Image
                            src="https://foodapps.uk/images/backgrounds/food5.png"
                            alt=""
                        />
                    </div>
                    <div className="absolute top-0 left-0 mt-10 w-4/12 hidden sm:block md:block">
                        <Image
                            src="https://foodapps.uk/images/backgrounds/food2.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignIn