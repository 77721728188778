import React from 'react'

export default function SocialButton(props) {
    return (
        <div className="mx-1">
            <a rel="noreferrer" href={props.url} target="_blank">
                <props.icon
                    style={{ ...props.style, backgroundColor: props.bgColor }}
                    className={`text-white bg-${props.bgColor} p-1 text-2xl rounded ${props.classes}`}
                />
            </a>
        </div>
    )
}
