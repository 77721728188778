import React from 'react'

export default function CartCheckout(props) {
    return (
        <button onClick={props.onClick} style={props.containerStyle} className={`cursor-pointer min-w-full flex justify-between items-center h-12 bg-gray-700 font-bold py-2 px-2 pl-4 rounded-full ${props.containerClasses}`}>
            <p className="text-white">{props.buttonText}</p>
            <span style={props.price} className="bg-white text-gray-700 py-2 px-6 rounded-full truncate">£{Number.parseFloat(props.totalPrice).toFixed(2)}</span>
        </button>
    )
}
