import React, { useState} from 'react';
import { Link, useHistory } from 'react-router-dom';

import { FiSearch } from 'react-icons/fi';
import { useDispatch} from 'react-redux';

import { getRestaurantsByPostCode } from '../../../../redux/actions/restaurantInfo';

function ScrolledNavbar({ setRestHeading }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        postCode: ""
    })
    
    const handleClick = async () => {
        await getRestaurantsByPostCode(dispatch, formData);
        setRestHeading("Results Nearby")
    }
    const handleSearch = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    return (
        <>
            {/* <MobileNavbar />    */}
            <div className="hidden sm:hidden md:block">
                <div>
                    <div>
                        <div className="flex flex-wrap fixed top-0 items z-10 bg-white w-full shadow-lg">
                            <div className="pl-10 flex-grow  py-2 justify-between">
                                <Link to='/'>
                                    <img alt="foodapps.uk" className="h-16 cursor-pointer" src="https://foodapps.uk/images/logos/logo.png" />
                                </Link>
                            </div>
                            {(history.location.pathname === "/" || history.location.pathname === "/home")  &&
                            <div className="w-1/2  py-2">
                                <div className="w-full bg-nat rounded-lg flex items-center">
                                    <div className="px-2">
                                        <FiSearch className="text-gray-500 text-lg" />
                                    </div>
                                    <input className="px-1 p-2 flex-grow outline-none bg-nat"  name="postCode" value={formData.search} type="text" onChange={handleSearch} placeholder="Enter your post code" />

                                    <a href="#restaurants"  onClick={handleClick} type="button" className="bg-prim text-white font-semibold rounded-r-lg p-4 outline-none">Search</a>
                                </div>
                            </div>}
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ScrolledNavbar;