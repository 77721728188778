import React from 'react'

export default function AppCard(props) {
    return (
        <a href={props.url} rel="noreferrer" target="_blank">
            <div style={props.containerStyle} className={`flex items-center bg-black rounded md:ml-2 mt-2 md:mt-0 ${props.containerClasses}`}>
                <div className="w-10">
                    <img style={props.imgStyle} className={`w-full h-full object-cover ${props.imgClasses}`} src={props.img} alt={props.imgAlt} />
                </div>
                <div style={props.textStyle} className={`text-white pl-2 ${props.textClasses}`}>
                    <p className="text-xs">Download on</p>
                    <p className="font-bold">{props.text}</p>
                </div>
            </div>
        </a>
    )
}
