import React, { useState } from "react";
import {FiChevronDown } from 'react-icons/fi';
import OutsideClickHandler from "react-outside-click-handler";
import ContactusDropdown from "./ContactusDropdown";
const dropdown = [
    {
      title: "Telephone: 0191 691 8791",
    },
    {
      title: "Email: support@foodapps.uk",
    },
    
  ];
export default function ContactUsButton(props) {
    const [openDropdown, setOpenDropdown] = useState(false);
  const handleClick = (e) => {
    // setBecome(e.target.value)
    setOpenDropdown(false);
  };
  const handleTabDropDown = () =>{
    if(window.innerWidth >= 768){
      setOpenDropdown(true);
    }
  }
    return (
        <div style={props.containerStyle} className="px-4">
            <div className="flex items-center">
                {/* {props.icon && <FiPhone className="text-red-600" />} */}
                <div
                onMouseEnter={() => setOpenDropdown(true)}
                onMouseLeave={() => setOpenDropdown(false)}
                onClick={()=> handleTabDropDown()}
                className={`ml-1 items-center rounded trans cursor-pointer flex hover:text-prim hover:trans highlight-remove`}
              >
                <div className="menu-dropdown relative text-base hover:text-prim hover:trans">
                  Contact us
                </div>
                <FiChevronDown className="inline-block text-lg font-bold" />
              </div>
            </div>
            <OutsideClickHandler
          onOutsideClick={() => {
            setOpenDropdown(false);
          }}
        >
          <ContactusDropdown className="right-90"
            setOpenDropdown={setOpenDropdown}
            onClick={handleClick}
            openDropdown={openDropdown}
            items={dropdown}
          />
        </OutsideClickHandler>
           
        </div>
    )
}