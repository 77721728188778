import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { FiChevronDown } from 'react-icons/fi';
import useClickOutside from '../../Hooks/useOutsideClick';

export default function OrderTypeDropdown(props) {
    let { options, setOpenModal } = props;
    const history = useHistory();
    const tableNo  = useLocation()?.search.includes("tableNo");
    const [openOptions, setOpenOptions] = useState(false)
    const [selectedType, setSelectedType] = useState(tableNo? options[1] :localStorage.getItem('order') ? JSON.parse(localStorage.getItem('order'))?.orderType : options[1]);

    useEffect(() => {
        setOrderObj(localStorage.getItem('order') ? JSON.parse(localStorage.getItem('order')).orderType : options[0])
    }, [options])

    const handleSetOrderType = (type) => {
        if (type === "Book A Table") {
            if(localStorage.getItem('customer')){
                setOpenModal(true)
            } else {
                history.push('/sign-in')
            }
            
        }
        else {
            setOrderObj(type);
            setSelectedType(type)
        }
        setOpenOptions(false)

    }
    const setOrderObj = (type) => {
        let order = JSON.parse(localStorage.getItem('order'));
        const newOrder = { ...order };
        newOrder.orderType = type;
        order = { ...newOrder }
        localStorage.setItem("order", JSON.stringify(order));
    }
    let domNode = useClickOutside(() => {
        setOpenOptions(false);
    });

    return (
        <div ref={domNode} className="w-full mt-4 sm:mt-0 md:mt-0 sm:flex md:flex items-end justify-end">
            <div className="text-white flex items-center relative w-full md:w-auto">
                <p className=" p-3 bg-prim rounded-l-lg w-full ">{selectedType}</p>
                <button onClick={() => setOpenOptions(true)} className=" hover:bg-red-800 bg-prim rounded-r-lg p-3 border-l border-red-800 outline-none">
                    <FiChevronDown className="text-2xl" />
                </button>
                {openOptions &&
                    <button id="focus" className="-mt-20 absolute bg-gray-200 rounded-lg p-2 outline-none right-0">
                        {options.map((type, i) =>
                            <div key={i} onClick={() => { handleSetOrderType(type) }} className="p-2 px-6 cursor-pointer font-bold border-b hover:bg-gray-300 text-gray-800 text-sm">
                                <p>{type}</p>
                            </div>
                        )}
                    </button>}
            </div>
        </div>
    )
}