/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { getCookie } from "../../../components/utilities/cookie/cookie";
import { placeDineOrder } from "../../../redux/actions/order";

import PaymentOptionCard from "../../../components/checkout/PaymentOptionCard";
import AgreementCheck from "../../../components/utilities/form/AgreementCheck";
import YourOrder from "./YourOrder";
import CheckoutTableNumber from "./CheckoutTableNumber";
import CheckoutHeader from "../../../components/checkout/CheckoutHeader";
import CheckoutContact from "./CheckoutContact";
import ErrorMessage from '../../../components/utilities/form/ErrorMessage'
import OrderSummaryPopup from "../UserMyOrder/OrderSummuryPopup";

const CheckoutDineIn = () => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [clickable, setClickable] = useState(true);
  const restaurantId = JSON.parse(localStorage.getItem('order')).restaurant;
  
  const restaurantUniId = JSON.parse(localStorage.getItem('order')).restaurantUniId;
  const customer = JSON.parse(localStorage.getItem('customer'));
  const cart = JSON.parse(localStorage.getItem('cart'));
  const [check, setCheck] = useState(false)

  const [option, setOption] = useState("Counter")
  const dispatch = useDispatch();
  const [order, setOrder] = useState({
    "restaurant": restaurantId,
    "restaurantUniId": restaurantUniId,
    "customer": customer._id,
    "cartItems": JSON.stringify(cart),
    "discount": null,
    "coupon": null,
    "orderType": "Dine In",
    "tableNo": localStorage.getItem("tableNo") || "",
    "customerName": customer.name,
    "mobileNo": customer.mobileNo,
    "subTotal": cart.subTotal,
    "totalPrice": 0,
    "paymentMethod": option,
    "paymentStatus": "Pending",
    "kitchenNotes": "",
    "deliveredAt": "",
    "deliveryCharge": 0
  })
  const { error: orderError } = useSelector(state => state.order)


  const token = getCookie('token')

  useEffect(() => {
    localStorage.setItem('order', JSON.stringify(order))
  }, [])

  const [localCard, setLocalCard] = useState({
    total: Number.parseFloat(order.totalPrice).toFixed(2),
    customerId: ""
  });
 
  const placeDineInOrder = async (e) => {
    e.preventDefault();
      if(check) {
        if(localStorage.getItem("tableNo")){
          await placeDineOrder(dispatch, token, option, localCard, history, setOpenModal, setClickable);
        } else {
          alert("Please Input table Number")
        }
      } else {
        alert("Please accept the Terms and Conditions")
      }
  }
 
  return (
    <div className="bg-nat">
      <div className="mx-12 py-5">
        <button className="bg-prim px-5 py-2 rounded text-white" onClick={() => history.push("/order/" + restaurantUniId)}>Back</button>
      </div>
      <div className="py-16 sm:px-32 md:px-32">
        <div className="w-full sm:flex md:flex">
          <div className="w-full sm:w-2/3 md:w-2/3 h-full p-6">
            <div className="w-full h-full">
              <div className=" flex items-center">
                <div className="w-full text-center">
                  <h1 style={{ fontSize: "2em" }} className="mb-4">
                    Dine In
                  </h1>
                </div>
              </div>
              <div className="w-full sm:hidden sm:w-1/3 md:w-1/3 h-full p-6">
                <YourOrder order={order} setOrder={setOrder} />
              </div>
              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                <CheckoutHeader title="Customer Name" order={order} setOrder={setOrder} />
              </div>
              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                <CheckoutContact order={order} setOrder={setOrder} />
              </div>

              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                <CheckoutTableNumber />
              </div>
              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                <PaymentOptionCard setOption={setOption} options={["Card", "Counter"]} setLocalCard={setLocalCard} setCheck={setCheck} check={check} />
                <AgreementCheck
                  check={check}
                  setCheck={setCheck}
                  beforeLink="Before your order please make sure your "
                  link=" Food Allergy and T&amp;C."
                  afterLink=" If you have anything else, please contact us."
                />
                {orderError && <ErrorMessage message={orderError} />} 
                <button disabled={!clickable} className={`mt-6 bg-prim text-white w-full rounded p-2 ${clickable? "": "cursor-not-allowed"}`} onClick={placeDineInOrder}>
                  {clickable ? "Place Order" : "Proceeding..."}
                </button>
              </div>
              <div className=""></div>
            </div>
          </div>
          <div className="w-full hidden sm:block sm:w-1/3 md:w-1/3 h-full p-6">
            <YourOrder order={order} setOrder={setOrder} />
          </div>
        </div>
      </div>
      <OrderSummaryPopup openModal={openModal} setOpenModal={setOpenModal} />
    </div >
  );
};

export default CheckoutDineIn;
