import React from "react";

export default function Dropdown(props) {
  const user = JSON.parse(localStorage.getItem("customer"));

  return (
    <div>
      {props.openDropdown && (
        <div
          style={{ ...props.containerStyle }}
          className={`z-50 w-42 outline-none fixed ${
            user ? "right-78" : "right-40"
          } mt-1 ${props.containerClasses}`}
        >
          <div
            style={{ ...props.mainStyle }}
            className={`bg-gray-200 text-prim rounded-lg border shadow-lg ${props.mainClasses}`}
            onMouseEnter={() => props.setOpenDropdown(true)}
            onMouseLeave={() => props.setOpenDropdown(false)}
          >
            {props.items.map((tm, i) => (
              <a
                key={i}
                onClick={props.onClick}
                style={props.style}
                className={`py-2 px-5 w-full hover:text-white hover:bg-prim rounded trans cursor-pointer outline-none block text-sm font-bold text-center ${props.classes}`}
                href={tm.url}
                target="_blank"
                rel="noreferrer"
              >
                {tm.title}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
