import React, { useState } from "react";
import { FiUser, FiEdit2, FiX } from "react-icons/fi";
import Icon from "./../../../components/utilities/Icon";
import CheckoutContactField from "./CheckoutContactField";

const CheckoutContact = ({ order, setOrder }) => {
  const [isEdit, setIsEdit] = useState(false);
  return (
    <>
      <div className="flex items-center px-2">
        <FiUser className="rounded-full w-10 h-10 flex items-center justify-center bg-prim text-white p-2" />
        <div className="px-4 flex-grow">
          <p className="text-lg sm:text-2xl md:text-2xl">Contact</p>
        </div>
        {!isEdit && (
          <Icon onClick={() => setIsEdit(true)} icon={FiEdit2} />
        )}

        {isEdit && (
          <Icon onClick={() => setIsEdit(false)} icon={FiX} />
        )}
      </div>
      <CheckoutContactField order={order} setOrder={setOrder} isEdit={isEdit} setIsEdit={setIsEdit} />
    </>
  );
};

export default CheckoutContact;
