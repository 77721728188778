import React from 'react';

import { Link } from 'react-router-dom';

export default function LoginFormHeader() {
    return (
        <div className="flex justify-center mt-2">
            <Link to="/">
                <img className="h-16" src="https://foodapps.uk/images/logos/logo.png" alt="" />
            </Link>
        </div>
    )
}
