import React from 'react';
import { Link } from 'react-router-dom';
import { footerLinks } from './const';
export default function FooterLinks() {
    return (
        <div className="w-full md:w-auto px-4 flex items-center flex-wrap">
            {footerLinks.map((l, i) =>
                <div key={i} className="px-2 md:px-0 md:pr-2 shadow-full rounded md:text-white md:shadow-none py-2 mt-1 bg-red-400 md:bg-prim md:mt-0 md:py-0 border-b md:border-none w-full md:w-auto cursor-pointer trans text-white hover:text-black text-center">
                    <Link to={l.url}>
                        <button value={l.id}  className="text-lg md:text-sm outline-none">{l.text}</button>
                    </Link>
            </div>
            )}
        </div>
    )
}