import React from 'react'
import { useDispatch } from 'react-redux'
import { removeBookingOrder } from '../../../redux/actions/order'
import CrossButton from '../../utilities/buttons/CrossButton'
import { getCookie } from '../../utilities/cookie/cookie'
import BookingStatus from './BookingStatus'
import BookingTitle from './BookingTitle'
export default function BookingHeader({ booking }) {
    const dispatch = useDispatch();
    const orderId = booking._id;
    const token = getCookie("token");
    const handleRemoveOrder = () => {
        removeBookingOrder(dispatch, orderId, token);
    }
    return (
        <div className="w-full flex justify-between border-b border-gray-400 pb-1">
            <BookingTitle title={booking.rest} />
            <div className="flex items-center">
                <BookingStatus status={booking.orderStatus} containerClasses="bg-orange-400" />
                {
                    (booking.orderStatus === "Canceled" || booking.orderStatus === "Completed") ? <CrossButton onClick={handleRemoveOrder} /> : null
                }

            </div>
        </div>
    )
}
