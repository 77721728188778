import React, { useEffect } from 'react';
//
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
//
import UserLayout from '../../../components/layout/UserLayout';
import NotificationCard from '../../../components/utilities/cards/NotificationCard';
import Heading from '../../../components/utilities/Heading';
import { getCustomerNotifications, removeNotifications } from '../../../redux/actions/notification';
import { getCookie } from '../../../components/utilities/cookie/cookie';

export default function UserNotifications() {
    const { notifications } = useSelector(state => state.notifications);
    const dispatch = useDispatch();
    const token = getCookie("token");
    useEffect(() => {
        getCustomerNotifications(dispatch, token)
    }, [dispatch, token])

    const handleDelete = async (id) => {
        await removeNotifications(dispatch, id, token);
        getCustomerNotifications(dispatch, token);
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>FoodApps | Notifications</title>
            </Helmet>
            <UserLayout>
                <Heading>Notifications</Heading>
                <div className="mt-4 w-full">
                    {
                        notifications && notifications.length > 0 ? (
                            notifications.map((el, i) => (
                                <NotificationCard
                                    key={i}
                                    onClick={() => handleDelete(el._id)}
                                    title={el.title}
                                    desc={el.description}
                                    dateTime={el.createdAt}
                                />
                            ))
                        ) : <span className='text-red-700 font-bold text-center'>No Notification is found</span>
                    }

                </div>
            </UserLayout>
        </HelmetProvider>
    )
}