import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";
import {
  GET_RESTAURANT_DELIVERY_FAILURE,
  GET_RESTAURANT_DELIVERY_SUCCESS,
  GET_RESTAURANT_DELIVERY_CHARGE_SUCCESS
} from "../types/restaurantDelivery/type";

export const getOwnRestaurantDelivery = async (dispatch, token) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant-delivery/get-restaurant-own-delivery`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_RESTAURANT_DELIVERY_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_DELIVERY_FAILURE, payload: error });
  }
};

export const addRestaurantDelivery = async (dispatch, token, formData) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant-delivery/add-restaurant-delivery`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_RESTAURANT_DELIVERY_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_DELIVERY_FAILURE, payload: error });
  }
};

export const getRestaurantDeliveryById = async (dispatch, restaurantId) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant-delivery/get-restaurant-delivery-by-restaurant-id/${restaurantId}`,
    );
    if (data) {
      dispatch({ type: GET_RESTAURANT_DELIVERY_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_DELIVERY_FAILURE, payload: error });
  }
};

export const updateRestaurantDelivery = async (dispatch, token, formData) => {
  try {
    const {
      data: { data },
    } = await axios.put(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant-delivery/update-restaurant-delivery`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_RESTAURANT_DELIVERY_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_DELIVERY_FAILURE, payload: error });
  }
};

export const deleteRestaurantDelivery = async (
  dispatch,
  token,
  formData,
  restaurantId,
) => {
  try {
    const {
      data: { data },
    } = await axios.delete(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant-delivery/delete-restaurant-delivery/${restaurantId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_RESTAURANT_DELIVERY_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_DELIVERY_FAILURE, payload: error });
  }
};

export const getDeliveryCharge = async (dispatch, deliveryFormData) => {
  try {
    const response = await fetch(`${apiBaseUrl}${apiVersionPrefix}/restaurant-delivery/calculate-delivery-charge`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
      },
      body: JSON.stringify(deliveryFormData)
    })
    const data = await response.json();

    if (data) {
      dispatch({ type: GET_RESTAURANT_DELIVERY_CHARGE_SUCCESS, payload: data.data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_DELIVERY_FAILURE, payload: error });

  }

  // console.log(deliveryFormData)
};
