import React, { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { AiOutlineArrowLeft } from "react-icons/ai";

import { getCookie } from "../../../components/utilities/cookie/cookie";
import { getSingleOrder } from "../../../redux/actions/order";

import OrderSummuryItems from "./OrderSummuryItems";
import UserLayout from "../../../components/layout/UserLayout";
import Heading from "../../../components/utilities/Heading";

export default function OrderSummary() {
  const { order } = useSelector((state) => state.order);
  console.log(order);
  const { id } = useParams();
  const orderId = id;
  const token = getCookie("token");
  const dispatch = useDispatch();
  let cart = order?.cartItems ? JSON.parse(order.cartItems) : {};
  const discount =
    !(order.orderType === "Dine In") &&
    (order?.discount
      ? cart.subTotal * (order?.discount?.discountValue / 100)
      : 0);
  useEffect(() => {
    getSingleOrder(dispatch, orderId, token);
  }, [dispatch, orderId, token]);

  return (
    <HelmetProvider>
      <UserLayout>
        <Link to="/my-order">
          <Heading classes={"flex items-center pb-2"}>
            <AiOutlineArrowLeft /> <span className="ml-2">My Order</span>
          </Heading>
        </Link>
        <Heading>Order Details</Heading>

        <div className="flex justify-between">
          <div className="flex items-end">
            <p className="mt-5 font-semibold ">
              {order.orderNumber}{" "}
              <span className="text-gray-600 ml-5 text-sm ">Order Type:</span>
              <span className="text-red-600 ml-2 text-sm">
                {order.orderType}
              </span>
            </p>
            <p className="mt-5 font-semibold ">
              <span className="text-gray-600 ml-5 text-sm ">Payment Type:</span>
              <span className="text-red-600 ml-2 text-sm">
                {order.paymentMethod}
              </span>
            </p>
          </div>
          {/* {order?.orderType !== "Dine In" && <OrderTimeLine order={order} />} */}
        </div>

        <div className="mt-5 border-b-2 border-gray-400" />

        <div className="flex justify-between mt-5">
          <div>
            {order?.orderType !== "Dine In" && (
              <>
                <p className="font-semibold text-xl pb-1">
                  {order.restaurant?.name}
                </p>
                {/* <p>{order?.tableNo}</p> */}
                <p className="font-medium">
                  {order.restaurant?.address?.address}
                </p>
              </>
            )}
          </div>
          <div className="">
            <p className="font-semibold text-xl text-right ">
              {order?.customerName}
            </p>
            <p className="font-semibold text-xl text-right ">
              {order?.deliveredAt}
            </p>
            <p className="font-semibold text-xl text-right ">
              {order?.mobileNo}
            </p>
            {order?.orderType === "Dine In" && (
              <>
                <p className="font-semibold text-right">
                  <span className="text-gray-600">Table NO: </span>
                  {order?.tableNo}
                </p>
              </>
            )}

            <p className="font-semibold text-right text-sm text-gray-600">
              {order.orderStatus}
            </p>
          </div>
        </div>

        {order.kitchenNotes ? (
          <p className="mt-5 text-sm font-semibold">
            Kitchen Notes:{" "}
            <span className="text-gray-600">{order.kitchenNotes}</span>
          </p>
        ) : null}

        {cart.foodItems && cart.foodItems.length > 0
          ? cart.foodItems.map((el, index) => (
              <OrderSummuryItems
                value={el.price}
                itemName={el.name}
                key={index}
                quantity={el.quantity}
              />
            ))
          : null}

        <div className="mt-5 border-b-2 border-gray-600" />

        <OrderSummuryItems
          value={order.subTotal}
          itemName="Sub Total"
          quantity={0}
        />

        {order.discount ? (
          <OrderSummuryItems
            value={discount}
            itemName="Discount"
            isNegative={true}
            quantity={0}
          />
        ) : null}
        {order.coupon && (
          <OrderSummuryItems
            itemName="Coupon"
            value={order.coupon?.amount}
            quantity={0}
            isNegative={true}
          />
        )}
        <OrderSummuryItems
          value={order.serviceCharge}
          quantity={0}
          itemName="Service Charge"
        />

        {order?.orderType === "Home Delivery" && (
          <>
            <OrderSummuryItems
              value={order.deliveryCharge}
              quantity={0}
              itemName="Delivery Charge"
            />
          </>
        )}

        <div className="mt-5 border-b-2 border-gray-600" />

        <OrderSummuryItems
          value={order.totalPrice}
          itemName="Total"
          quantity={0}
        />
      </UserLayout>
    </HelmetProvider>
  );
}
