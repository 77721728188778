import React from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useHistory } from "react-router-dom";

export default function UserPolicy () {
  const history = useHistory();
  return (
    <div className="bg-nat">
      <button
          className="py-3 pr-4 pl-2 ml-4 md:ml-2 rounded cursor-pointer bg-prim text-white text-lg mt-2"
          onClick={() => history.goBack()}>
          <FiArrowLeft className="inline-block" />
          <small>Back</small>
          </button>
      <div className=" px-4 py-5">
        
        <h1 className="text-center text-4xl font-bold">User Policy </h1>
        <div></div>
      </div>
      
      
      <p className="px-12 py-3 text-lg">
        <strong>FoodApps</strong> tells you the terms and conditions on which food and drink is
        listed on this website and will be supplied by the listed Restaurants or
        Takeaways to you.
        <br />
        <br />
        Please read these terms and conditions carefully before complete the
        checkout through this site. By mark the read option you should
        understand that you are agree to be bound by these terms and conditions.
        If you refuse to accept these terms and conditions, you will not be able
        complete the checkout through this website.
        <br />
        <br />
        <a href="www.foodapps.uk" target='_blank'>www.foodapps.uk</a> operated by NETSOFTUK SOLUTION LTD which is trading name
        of this Company. We are registered in UK under company NO-12235478 and
        with our registered office- Unit-3, 2a Beaconsfield Street, Newcastle
        Upon Tyne, Tyne & Wear, United Kingdom, NE4 5JN
        <br />
        <br />
        <strong>FoodApps</strong> is only intended for use by people’s post code resident in
        their specified Areas by this website. The purpose of this website is
        able you to buy Foods from the Chosen Restaurants or Takeaways.
        <br />
        <br />
        By placing an order with us, you declare to the Restaurants or Takeaways
        you have selected that-
        <br />
        (a) You are legally capable of entering into binding contracts; and (b)
        You are at least 18 years old, If you are purchasing alcohol and appear
        to be under the age of 21 you will be required to prove that you are
        over 18. Only the following forms of I.D. will be accepted: Passport,
        Photo Driving License.
        <br />
        <br />
        Cancel Policy: Once an order is placed, you will be provided the option
        to cancel the order up until the point of acceptance from the Outlet.
        You will not have any right to cancel an order once accepted by the
        Outlet. <br />
        Delivery Policy: <br />
        The Outlet´s goal is to deliver or make available on-time i.e.
        delivering at the time it quotes. Unfortunately factors, such as,
        weather and traffic conditions occasionally prevent the Outlet from
        achieving this. The Outlet cannot be responsible for late delivery.{" "}
        <br />
        Payment Policy: <br />
        7.1 Payment for all Products must be by credit or debit card to be
        charged as specified in this website or by cash paid to the Outlet on
        delivery or collection. <br />
        7.3 If Payment is by credit or debit card then the order is only charged
        once the takeaway accepts the order. However before the order is sent to
        the takeaway we need to check and set aside the available funds in your
        bank <br />
        User Liability: <br />
        8.1 The Outlet´s liability for failing to deliver Products of
        satisfactory quality or otherwise breaking this contract is limited to
        the price of those Products. <br />
        8.2 Any complaints relating to your order should first be directed to
        NETSOFTUK SOLUTION LTD we are responsible for all customer service.
        <br />
        <br />
        Private Policy: <br />
        We are committed to safeguarding the privacy of our
        website visitors; this policy sets out how we will treat your personal
        information. Our website uses cookies. By using our website and agreeing
        to this policy, you consent to our use of cookies in accordance with the
        terms of this policy.
      </p>
    </div>
  );
};
