import React, { useEffect, useState } from "react";

import { FiShoppingBag } from "react-icons/fi";
import { FiStar } from "react-icons/fi";
import { RiEBike2Line } from "react-icons/ri";
import { GoLocation } from "react-icons/go";

import QRCode from "qrcode";
import OrderTypeDropdown from "./../../../components/dropdown/OrderTypeDropdown";
import { useSelector } from "react-redux";
import { IoIosRestaurant } from "react-icons/io";
import { BsClockFill } from 'react-icons/bs';

export default function OrderHeader({ restaurant, setOpenModal }) {
  const date = new Date();
  const url = window.location.href;
  const [src, setSrc] = useState("");
  useEffect(() => {
    QRCode.toDataURL(url).then(setSrc);
  }, [url]);

  const restaurantTiming = restaurant?.restaurantTiming?.restaurantTiming;
  const closeOrOpen = restaurantTiming?.available?.find((it) => it.day === date.getDay());
  const amOrPm = (time) => {
    const hours = time.split(':')[0];
    const minutes = time.split(':')[1];
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    return `${hours % 12 || 12}:${minutes} ${amOrPm}`;
  }
      const start=new Date(Date.now()).setHours(closeOrOpen?.startTime.split(':')[0],closeOrOpen?.startTime.split(':')[1]);
      const end=new Date(Date.now()).setHours(closeOrOpen?.endTime.split(':')[0],closeOrOpen?.endTime.split(':')[1]);
      const currentTime=new Date(Date.now());
      let available=false;
      (closeOrOpen?.isOpen)?(start<currentTime && end>currentTime)?available=true:available=false:available=false;

  const { cuisines } = useSelector((state) => state.cuisines);
  let cuisineType = [];
  const cuisineId = restaurant?.cuisines?.length;
  for (let i = 0; i < cuisineId; i++) {
    const findCuisine = cuisines.find(
      (item) => item._id === restaurant?.cuisines[i]
    );
    cuisineType = [...cuisineType, findCuisine];
  }
  let options = restaurant?.businessType?.orderTypes
  return (
    <div>
      <div className="overflow-hidden w-full bg-gray">
        <div className="relative order-banner h-56 overflow-hidden mt-16 sm:mt-0 md:mt-0">
          <img
            className="w-full h-full object-cover"
            src={restaurant.banner}
            alt={restaurant.name}
          />
          <div className="absolute bottom-0 left-0 w-24 h-24 mx-2 p-2 rounded-full bg-gray shadow-full  ml-10 sm:ml-20 md:ml-20 mb-2">
            <div className="w-full h-full rounded-lg bg-white overflow-hidden">
              <img
                className="w-full h-full object-cover"
                src={restaurant.logo}
                alt={restaurant.name}
              />
            </div>
          </div>

          <div className="absolute bottom-0 right-0 w-24 h-24 mx-5 p-2 pr-4 bg-gray shadow-full  ml-10 sm:ml-20 md:ml-20 mb-2">
            <div className="w-full h-full bg-white overflow-hidden">
              <img
                className="w-full rounded-lg h-full object-cover"
                src={src}
                alt="QR"
              />
            </div>
          </div>
        </div>
        <div className="mb-2 w-full bg-white  py-3 px-6 md:px-12">
          <div className="sm:flex md:flex items-center">
            <div className="w-full ml-2 sm:w-5/12 md:w-5/12 border-b sm:border-none md:border-none pb-2 sm:pb-0 md:pb-0">
              <div className="flex items-center">
                <div className="w-full pr-4 truncate">
                  <div className="flex items-center">
                    <p className="text-lg sm:text-3xl md:text-3xl font-semibold text-gray-800 truncate">
                      {restaurant.name}
                    </p>
                    {(restaurant.discount?.discountValue) &&
                      <p className="px-4 text-center text-2xl text-red-700">
                     {restaurant.discount?.discountValue}%
                      <span className="text-xl"> Off</span>
                    </p>}
                  </div>
                </div>
              </div>
              <p className="text-sm text-gray-600 mt-1 truncate">
                {restaurant.address?.address}, {restaurant.address?.postCode}
              </p>
              {available? (
                <p className="text-xs text-gray-600">
                  {amOrPm(closeOrOpen.startTime)} to {amOrPm(closeOrOpen.endTime)}
                </p>
              ) : (
                <p className="flex  font-bold text-xl text-prim">
                  Closed
                </p>
              )}
            </div>
            <div className="w-full sm:w-4/12 md:ml-20 md:w-4/12 border-b sm:border-none md:border-none pb-2 sm:pb-0 md:pb-0">
              <div className="flex items-center">
                <div className="w-full md:w-6/6 md:px-4 truncate">
                  <div className="w-full text-red-700 md:mx-10 flex flex-wrap items-center">
                    <div className="md:mx-6 w-full items-center md:justify-center">
                      {/* {localStorage.getItem("customer") ? (
                        <FiHeart
                          className="text-3xl cursor-pointer"
                          onClick={addFavourite}
                        />
                      ) : (
                        <FiHeart
                          className="text-3xl cursor-pointer"
                        />
                      )} */}
                      <div className="flex  items-center">
                        {restaurant.speciality?.map((special, i) => (
                          <div className="flex items-center mx-2" key={i}>
                            <FiStar className="text-yellow-600" />
                            <p className="text-sm text-gray-700 mt-1 ml-1">
                              {special}
                            </p>
                          </div>
                        ))}
                      </div>
                      <div className="mx-auto md:mx-0 flex ">
                        {cuisineType?.map((cuisine, i) => (
                          <div key={i} className="flex items-center ml-1">
                            <IoIosRestaurant className="text-yellow-600" />
                            <p className="text-sm text-gray-700 mt-1">
                              {" "}
                              {cuisine?.type}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex ml-1 md:ml-8 mt-2">
                      <FiShoppingBag className="text-prim text-sm" />
                      <p className="px-1 text-xs font-bold">
                        {restaurant.restaurantTiming &&
                          restaurant.restaurantTiming.restaurantTiming.collectionTime}{" "}
                        Min
                      </p>
                      <RiEBike2Line className="text-prim text-sm" />
                      <p className="px-2 text-xs font-bold">
                        {restaurant?.restaurantDelivery?.deliveryCharge
                          ? "£" + restaurant.restaurantDelivery.deliveryCharge
                          : "Free"}
                        ,{" "}
                      </p>
                      <BsClockFill />
                      <p className="px-2 text-xs font-bold">
                        {restaurant.restaurantTiming &&
                          restaurant.restaurantTiming.restaurantTiming.deliveryTimes}{" "}
                        Min
                      </p>
                      <GoLocation className="text-prim text-sm" />
                      <p className="text-xs font-bold">
                        {restaurant.restaurantDelivery &&
                          restaurant.restaurantDelivery.areaLimit}{" "}
                        Mile
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" w-full  flex justify-end">
              <OrderTypeDropdown
                options={options? options: [
                  "Collection",
                  "Dine In",
                  "Home Delivery",
                  "Book A Table",
                ]}
                setOpenModal={setOpenModal}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
