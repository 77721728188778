import {
    GET_ORDER_SUCCESS,
    GET_ORDER_FAILURE,
    GET_ALL_ORDERS_SUCCESS,
    DELETE_ORDER_SUCCESS,
    GET_MESSAGE_SUCCESS,
    GET_ORDER_LOADING
} from "../types/order/type";

const initialState = {
    order: {},
    orders: [],
    error: "",
    message: "",
    status: "",
    loading: false,
    orderSuccess: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                order: action.payload,
                error: ""
            };
        case GET_ORDER_LOADING:
            return {
                ...state,
                loading: true,
                error: ""
            };

        case GET_ALL_ORDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                error: "",
                orders: action.payload,
            };
        case DELETE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                error: "",
                orders: action.payload
            }

        case GET_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                error: "",
                message: action.payload,
                orderSuccess: true,
            }

        case GET_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                status: "Failed",
                error: action.payload,
                orderSuccess: false,
            };

        default:
            return state;
    }
};

export default reducer;