import React from 'react'
import Image from '../images/Image'

export default function FooterCard(props) {
    return (
        <div className="hidden md:flex justify-center w-full md:w-1/3 p-2 md:p-4 items-end">
            <div className="ml-6 md:ml-0 w-10 md:w-16">
                <Image classes="image-invert" src={props.img} alt={props.title} />
            </div>
            <div className="-ml-8 md:ml-0 flex md:block justify-center w-full md:w-auto ">
                <div className={`text-white mb-1 px-2 text-center mt-2 leading-3 ${props.textClasses}`}>
                    <p className="text-lg font-bold py-1">{props.title}</p>
                    <p>{props.desc}</p>
                </div>
            </div>
        </div>
    )
}
