import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import { BsFillBellFill } from "react-icons/bs";


export default function NotificationsButton({ iconColor }) {
    return (
        <div className="px-1 sm:px-3 md:px-3">
            <Link to='/notifications'>
                <div className="relative">
                    {/* <div className="bg-prim w-3 h-3 flex items-center justify-center absolute p-2 top-0 right-0 rounded-full -mt-2 -mr-2 text-white text-xs">
                        1
                    </div> */}
                    <Icon color={`${iconColor ? iconColor : "text-gray-700"}`} icon={BsFillBellFill} />
                </div>
            </Link>
        </div>
    )
}