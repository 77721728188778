import React from 'react'

export default function Dropdown(props) {
    const user = JSON.parse(localStorage.getItem('customer'))

    return (
        <div>
            {props.openDropdown &&
            <div style={{ ...props.containerStyle }} className={`z-50 w-32 outline-none fixed ${user? "right-60": "right-60"} mt-4  ${props.containerClasses}`}>
                <div style={{ ...props.mainStyle }} className={`bg-gray-200 text-prim rounded-lg border shadow-lg ${props.mainClasses}`}>
                {props.items.map((tm, i) => 
                    <a
                        key={i}
                        onClick={props.onClick}
                        style={props.style} className={`py-2 px-6 text-center w-full hover:text-white hover:bg-prim rounded trans cursor-pointer outline-none block text-xs font-bold ${props.classes}`}
                        href={tm.url}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {tm.title}
                    </a>
                )}       
                </div>
            </div>}
       </div>
    )
}
