import Home from '../pages/Home';
import TermsAndCondition from "../pages/TermsAndCondition/index";
import Faq from "../pages/Faq/index";
import ForgetPassParent from "../pages/LoginForm/ForgetPassParent";
import ResetPassword from "../pages/LoginForm/ResetPassword";
import SignIn from '../pages/LoginForm/SignIn';
import SignUp from '../pages/LoginForm/SignUp';
import GuestSignIn from '../pages/LoginForm/GuestSignIn';
import Packages from './../pages/Packages/index';
import UserTermsOfUse from '../pages/user/UserTermsOfUse';
import Order from '../pages/Order';
const userRoutes = [
    // {
    //     path: '*',
    //     exact: true,
    //     component: Home
    // },
    {
        path: '/',
        exact: true,
        component: Home
    },
    {
        path: '/home',
        exact: true,
        component: Home
    },
    {

        path: '/forget-password',
        exact: true,
        component: ForgetPassParent
    },
    {
        path: "/reset-password",
        exact: true,
        component: ResetPassword
    },
    {
        path: "/sign-in",
        exact: true,
        component: SignIn
    },
    {
        path: "/sign-up",
        exact: true,
        component: SignUp
    },
    {
        path: "/guest-sign-up",
        exact: true,
        component: GuestSignIn
    },
    {
        path: "/user-policy",
        exact: true,
        component: TermsAndCondition
    },
    {
        path: "/user-faq",
        exact: true,
        component: Faq
    },
    {
        path: "/our-packages",
        exact: true,
        component: Packages
    },
    {
        path: "/terms-of-use",
        exact: true,
        component: UserTermsOfUse
    },
    {
        path: "/order/:id",
        exact: true,
        component: Order
    }   
]

export default userRoutes;
