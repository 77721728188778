import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getCookie } from "../../../components/utilities/cookie/cookie";
import {
  createCustomerAddress,
  updateCustomerAddress,
} from "../../../redux/actions/customer";
import "./index.css";

const UserAddressUpdate = ({ customer }) => {
  const [isEditable, setIsEditable] = useState(false);
  const dispatch = useDispatch();
  const id = customer._id;

  const initialState = {
    address: "",
    postCode: "",
  };

  const [formData, setFormData] = useState(initialState);

  const token = getCookie("token");

  const handelChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { address, postCode } = formData;

  const handleSubmitForm = () => {
    if (customer.address) {
      updateCustomerAddress(dispatch, { token, id, formData });
      setIsEditable(false);
    } else if (!customer.address) {
      createCustomerAddress(dispatch, { token, formData });
      setIsEditable(false);
    }
  };

  return (
    <div className="bg-nat w-full sm:w-1/3 md:w-1/3 mr-8 border sm:mt-8 mt-2 rounded-md p-2 sm:p-4 md:p-4 flex-col h-24 sm:h-36">
      {!isEditable ? (
        <button
          className={!isEditable ? "update-button" : "update-button active"}
          onClick={() => setIsEditable(true)}
        >
          Update Address
        </button>
      ) : (
        <div className="textFields">
          <div className="textFields-top">
            <label className="address-label">Address</label>
            <button className="close" onClick={() => setIsEditable(false)}>
              X
            </button>
          </div>
          <textarea
            name="address"
            className="address-text"
            onChange={handelChange}
            value={address}
          ></textarea>
          <textarea
            name="postCode"
            className="postCode-text"
            onChange={handelChange}
            value={postCode}
          ></textarea>
          <button className="address-submit" onClick={handleSubmitForm}>
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default UserAddressUpdate;
