
import React, { useState } from "react";


const CheckoutTableNumberField = ({ setTableNo, isEdit, setIsEdit }) => {
  const [formData, setFormData] = useState({
    tableNo: ""
  })
  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, tableNo: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const order = JSON.parse(localStorage.getItem('order'));
    order.tableNo = formData.tableNo;
    localStorage.setItem('order', JSON.stringify(order))
    localStorage.setItem('tableNo', formData.tableNo)
    setTableNo(formData.tableNo)
    setIsEdit(false)
  }

  return (
    <div className="sm:flex md:flex flex-wrap items-center mt-8">
      <div className="p-2 w-full sm:w-2/3 md:2/3">
        {isEdit &&
        <div className="text-sec border border-red-700 rounded p-4">
          <p className="mt-3 text-gray-700">Enter Table Number</p>
          <input
            type="text"
            name="tableNo"
            onChange={handleChange}
            value={formData.tableNo}
            placeholder="Table Name"
            className={`w-full outline-none p-1 text-black border border-red-700 rounded`}
          />
          <div className="flex justify-end">
            <button className="bg-prim py-2 px-5 rounded text-white mt-2" onClick={handleSubmit}>Add Table</button>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default CheckoutTableNumberField;
