import React, { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import ProductItem from './ProductItem'
import { getFoodsByCategory } from "../../../redux/actions/foods";

export default function Products({ setCart, cart }) {
    const { category, loading } = useSelector(state => state.categories);
    const categoryId = category?._id;
    const { foods } = useSelector(state => state.foods);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!loading) {
            getFoodsByCategory(dispatch, categoryId)
        }
    }, [dispatch, categoryId, loading])

    return (
        <div className="w-full sm:w-7/12 md:w-6/12 h-full mr-2 bg-white shadow-lg rounded-lg category-scrollbar">
            <div className="px-5 pb-5 pt-3 mb-6 md:mb-0">
                <div className="w-full">
                    <p className="hidden sm:block text-xl md:uppercase font-bold text-center border-b pt-3 pb-3 md:pb-6 text-gray-700 ">
                        {category.name}
                    </p>
                    {foods && foods.length > 0 ? (
                        foods.map((food, index) => (
                            <ProductItem
                                food={food}
                                key={index}
                                name={food.name}
                                price={food.price}
                                setCart={setCart}
                                cart={cart}
                            />
                        ))
                    ) : (
                        <span className="text-red-700 font-bold text-lg">No Food is found!</span>
                    )}
                </div>
            </div>
        </div>
    )
}
