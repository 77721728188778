import React from 'react';
import FbLikeButton from '../../../components/utilities/buttons/FbLikeButton';
import SocialButton from '../../../components/utilities/buttons/SocialButton';
import FooterCard from '../../../components/utilities/cards/FooterCard';
import { footerCardData, socialMedia } from './const';
import FooterBottom from './FooterBottom';
import FooterLinks from './FooterLinks';

export default function Footer() {
    return (
        <div className="w-full">
            <div className="bg-prim w-full flex justify-center flex-wrap items-center">
                {footerCardData.map((data) => 
                    <FooterCard
                        key={data.id}
                        title={data.title}
                        desc={data.desc}
                        img={data.img}
                    />
                )}
                <div className="w-full p-4 flex flex-wrap items-end justify-between">
                    <FooterLinks />
                    <div className="w-full md:w-auto px-20 md:px-4 mt-6 md:mt-0 flex items-center justify-between">
                         <FbLikeButton />
                        {socialMedia.map((so, i) =>
                            <SocialButton
                                key={i}
                                style={{}}
                                classes=""
                                bgColor={so.bg}
                                icon={so.icon}
                                url={so.url}
                            />
                        )}
                    </div>
                </div>
                <FooterBottom />
            </div>
            {/* <ContactUsPopup /> */}
        </div>
    )
}
