import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import BookingPopup from "../../../components/popup/BookingPopup";

import RoundedImage from "../../../components/utilities/images/RoundedImg";
import RestaurantDeliveryInfo from "./RestaurantDeliveryInfo";
import RestaurantDetails from "./RestuarantDetails";
import RestuantButton from "./RestuarantsButton";

export default function RestuantContent({ restaurant }) { 
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();

  const handleOrderRedirect = (restaurant) => {
    history.push(`/order/${restaurant.uniId}`);
    localStorage.setItem("restaurant",restaurant._id);
  }
  return (
    <div className="w-full p-2">
      <div className="bg-white rounded-lg w-full overflow-hidden">
        <div className="flex flex-wrap justify-between items-center">
          <div className="cursor-pointer w-full md:w-2/5 flex-grow md:flex items-center p-3 md:py-5" onClick={() => handleOrderRedirect(restaurant)}>
            <div className="w-auto md:w-44 flex justify-center">
                <RoundedImage src={`${restaurant.logo}`} />
            </div>
            <RestaurantDetails restaurant={restaurant} />
          </div>
          <div className="cursor-pointer w-full md:w-2/5 flex flex-col items-center justify-center  px-6 h-full pb-4 md:pb-0" onClick={() => handleOrderRedirect(restaurant)}>
            <RestaurantDeliveryInfo restaurant={restaurant} />
            {restaurant.distance ? (
              <div className="w-full"> distance:{restaurant.distance}</div>
            ) : null}

          </div>
          <RestuantButton setOpenModal={setOpenModal} restaurant={restaurant} />
        </div>
      </div>
      <BookingPopup openModal={openModal} setOpenModal={setOpenModal} restaurant={restaurant} />
    </div>
  );
}
