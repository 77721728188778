import firebase from 'firebase/app'
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyA1WaeiLSzCFXsy3IoxuXWYwgYojfQNZUg",
    authDomain: "food-apps-uk-otp.firebaseapp.com",
    projectId: "food-apps-uk-otp",
    storageBucket: "food-apps-uk-otp.appspot.com",
    messagingSenderId: "857777921200",
    appId: "1:857777921200:web:2d7d6f4170a1c171a7ef75"
  };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase
