import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCookie } from '../../../components/utilities/cookie/cookie';
import { applyCustomerCoupon } from '../../../redux/actions/coupon';
import { getSingleRestaurant } from "../../../redux/actions/restaurantInfo";
import { getServiceCharge } from "../../../redux/actions/serviceCharge";

export default function YourOrder() {

    const dispatch = useDispatch();

    // const [order, serOrder] = useState(JSON.parse(localStorage.getItem('order')))

    const restaurantId = JSON.parse(localStorage.getItem('order')).restaurantUniId;

    const orderLocal = JSON.parse(localStorage.getItem('order'));

    const cart = JSON.parse(localStorage.getItem('cart'));

    const { restaurantInfo } = useSelector(state => state.restaurantInfo);

    let { coupon, couponError } = useSelector(state => state.coupon);


    const [showCuponInput, setShowCouponInput] = useState(false);

    useEffect(() => {
        getSingleRestaurant(dispatch, restaurantId)
        getServiceCharge(dispatch)
    }, [dispatch, restaurantId])

    const [formData, setFormData] = useState({
        "coupon": '',
        "orderAmount": cart.subTotal
    })

    const token = getCookie('token');

    let { serviceCharge } = useSelector(state => state.serviceCharge);

    let couponAmount = coupon?.amount ? coupon?.amount : 0;

    serviceCharge = serviceCharge?.serviceCharge ? serviceCharge.serviceCharge : 0;

    let order = JSON.parse(localStorage.getItem('order'))
    const discount = !(order.orderType === "Dine In") && (restaurantInfo?.discount ? (cart.subTotal * (restaurantInfo?.discount?.discountValue / 100)) : 0);
    const total = cart.subTotal - discount + serviceCharge - couponAmount + orderLocal.deliveryCharge || 0;
    useEffect(() => {
        let order = JSON.parse(localStorage.getItem('order'))
        order.cartItems = JSON.stringify(cart);
        const updatedOrder = { ...order };
        updatedOrder.totalPrice = total;
        updatedOrder.subTotal = cart.subTotal;
        if(!(order.orderType === "Dine In")){
            updatedOrder.discount = restaurantInfo.discount ? restaurantInfo.discount._id : "";
        }
        

        localStorage.setItem("order", JSON.stringify(updatedOrder))
    }, [cart, restaurantInfo, total])

    const submitCoupon = async (e) => {
        e.preventDefault();
        await applyCustomerCoupon(dispatch, formData, token);
        setShowCouponInput(false)

    }


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    return (
        <div className="w-full h-full">
            <div className="w-full text-center mb-3 ">
                <span className='text-xs font-semibold text-gray-500'>Do Have Any Coupon Code??</span>
                <button className='text-xs ml-2 text-sm text-red-700 font-bold' onClick={() => setShowCouponInput(true)}>Apply Coupon</button>
            </div>
            {
                couponError ? (
                    <div className="w-full text-center">
                        <span className='text-red-700 font-bold'>{couponError}</span>
                    </div>
                ) : null
            }
            {
                showCuponInput ? (
                    <div className="w-full flex items-center mt-2 mb-3 flex-col">
                        <input type="text" name="coupon" value={formData.coupon} onChange={handleChange} className='w-3/4 border-2 border-red-700 rounded py-2 px-3 focus:ring-2 focus:ring-red-700' />
                        <button className='w-3/4 text-center py-3 mt-2 bg-red-700 text-white rounded font-semibold' onClick={submitCoupon}>Enter Coupon</button>
                    </div>
                ) : null
            }
            <div className="w-full text-center">
                <p className="font-semibold text-sec">Your Order</p>
            </div>
            {
                cart.foodItems?.map((el, index) => (
                    <div className="flex py-1 text-sm text-gray-600 px-2" key={index}>
                        <div className="flex flex-grow w-2/3">
                            <p className="text-sec font-semibold">{el.quantity}</p>
                            <p className="px-3">x</p>
                            <div className="flex flex-col">
                                <p className="text-sec font-semibold">{el.name}</p>
                                <p className="px-1">{el.options.toString()}</p>
                            </div>

                        </div>
                        <div className="w-1/3 text-right font-semibold">
                            <p>£ {Number.parseFloat(el.price).toFixed(2)}</p>
                        </div>
                    </div>
                ))
            }
            <div className="w-full p-2">
                <div className="border-b border-gray-400 border-2"></div>
            </div>
            <div className="p-2 flex text-sm text-sec font-semibold items-center justify-between">
                <p className="">Sub Total</p>
                <p className="">£ {Number.parseFloat(cart.subTotal).toFixed(2)}</p>
            </div>
            {
                restaurantInfo && restaurantInfo.discount && discount ? (
                    <div className="p-2 flex text-sm text-gray-600 items-center justify-between">
                        <p className="">Discount</p>
                        <p className="">- £ {Number.parseFloat(discount).toFixed(2)}</p>
                    </div>
                ) : null
            }


            <div className="w-full px-2">
                <div className="mt-3 flex text-sm text-gray-600 items-center justify-between">
                    <p className="">Service Charges</p>
                    <p className="">+ £ {serviceCharge}</p>
                </div>
                {orderLocal.orderType === "Home Delivery" && (
                    <div className="mt-3 flex text-sm text-gray-600 items-center justify-between">
                        <p className="">Delivery Charges</p>
                        <p className="">+ £ {orderLocal.deliveryCharge || 0}</p>
                    </div>
                )}


                {couponAmount !== 0 ? (
                    <div className="mt-3 flex text-sm text-gray-600 items-center justify-between">
                        <p className="">Coupon</p>
                        <p className="">- £ {couponAmount}</p>
                    </div>
                ) : null}
                <div className="w-full mt-3">
                    <div className="border-b border-gray-400 border-2"></div>
                </div>

                <div className="p-2 px-0 flex text-sm text-sec font-semibold items-center justify-between">
                    <p className="">Total</p>
                    <p className="">£ {Number.parseFloat(total).toFixed(2)}</p>
                </div>
                <div>
                    <p className="font-semibold text-sec">Allergy note</p>
                    <p className="text-gray-600">
                        {cart.note}
                    </p>
                </div>
            </div>
        </div>
    )
}