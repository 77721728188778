import React, { useState, useEffect } from "react";

const CheckoutContactField = ({ order, setOrder, setIsEdit, isEdit }) => {
  const [formData, setFormData] = useState({
    mobileNo: ""
  })

  useEffect(() => {
    const customer = JSON.parse(localStorage.getItem('customer'))
    let localOrder = JSON.parse(localStorage.getItem('order'));
    localOrder = {
      ...localOrder, mobileNo: customer.mobileNo ? customer.mobileNo : ""
    };
    localStorage.setItem('order', JSON.stringify(localOrder));
    setOrder(localOrder)
  }, [setOrder])

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, mobileNo: e.target.value });
  }

  const handleClick = (e) => {
    e.preventDefault();
    let localOrder = JSON.parse(localStorage.getItem('order'));
    localOrder = {
      ...localOrder, mobileNo: formData.mobileNo
    };
    localStorage.setItem('order', JSON.stringify(localOrder));
    setOrder(localOrder)
    setIsEdit(false)
  }
  return (
    <div className="sm:flex md:flex flex-wrap items-center mt-3">
      <div className="p-2 w-full sm:w-2/3 md:2/3">
        <h1 className="mx-2 font-semibold text-xl">{order.mobileNo ? order.mobileNo : "Enter A Contact No"}</h1>
        {!isEdit ? (
          null
        ) : (
          <>
            <div className="sm:flex">
              <input value={formData.mobileNo} onChange={handleChange} type="text" className="border-2 border-prim rounded py-2 px-2 mx-2" placeholder="Add another contact" />
              <button onClick={handleClick} className="bg-prim px-5 py-2 rounded m-2 text-white">Add</button>
            </div>       
          </>
        )}
      </div>
    </div>
  );
};

export default CheckoutContactField;
