import React from 'react';
import { Link } from 'react-router-dom';

export default function BannerCard(props) {
  const {
    backgroundColor,
    title,
    buttonTextColor,
    buttonText,
    alt,
    img,
    route,
  } = props;

  const buttonColorDepth = parseInt(backgroundColor.split('-')[2]) + 200;
  let buttonColor = backgroundColor.split('-');
  buttonColor.splice(2, 1, buttonColorDepth.toString());
  buttonColor = buttonColor.join('-');

  const handleClick = async () => {
    window.innerWidth > 400
      ? window.scrollTo({ top: 850, behavior: 'smooth' })
      : window.scrollTo({ top: 480, behavior: 'smooth' });
  };

  return (
    <div
      className={`justify-between w-3/5  items-center py-8 px-6 flex h-58 rounded-lg  mx-3 ${backgroundColor}`}
    >
      <div>
        <p className="text-white text-md pb-8 font-semibold">{title}</p>
        {route !== "" ? (
          <button
          className={`bg-white text-xs font-bold hover:${buttonColor} hover:text-white shadow-lg w-32 py-2 px-3 rounded-full  ${buttonTextColor}`}
        >
          <Link to={`${route}`}>
            <p>{buttonText}</p>
          </Link>
        </button>
        ) : (
          <button
          onClick={handleClick}
          className={`bg-white text-xs font-bold hover:${buttonColor} hover:text-white shadow-lg w-32 py-2 px-3 rounded-full  ${buttonTextColor}`}
        >
          <Link to={`${route}`}>
            <p>{buttonText}</p>
          </Link>
        </button>
        )}
        
      </div>
      <img
        alt={alt}
        className="w-28 h-24"
        src={`${process.env.REACT_APP_API_BASE_URL}/${img}`}
      />
    </div>
  );
}
