import React from 'react'
import { useHistory } from 'react-router-dom'
import CartAllergyNote from './CartAllergyNote'
import CartCheckout from './CartCheckout'
import CartHeader from './CartHeader'
import CartItem from './CartItem'

export default function Cart({ cart, setCart, isOpen }) {

    const customer = localStorage.getItem("customer") ? JSON.parse(localStorage.getItem("customer")) : "";

    const history = useHistory();

    const handleClick = () => {

        const order = JSON.parse(localStorage.getItem("order"));
        if(isOpen){
            if (!customer) {
                if (order.orderType !== "Dine In") {
                    history.push("/sign-in")
                }
                else {
                    history.push("/guest-sign-up")
                }
                localStorage.setItem("currentPage", "checkout")
            }
            else if (customer) {
                setCart({ ...cart, customer: customer._id })
                if (order.orderType === "Collection") {
                    history.push('/checkout-collection')
                }
                else if (order.orderType === "Home Delivery") {
                    history.push('/checkout-home-delivery')
                }
                else {
                    history.push('/checkout-dine-in')
                }
            }
        } else {
            alert("Sorry, we are closed now!Try again later.");
        }
        
        localStorage.setItem("cart", JSON.stringify(cart));
    }

    return (
        <div className="h-full">
            <div className="bg-white h-full rounded-lg pb-6 overflow-hidden">
                <CartHeader count={cart.foodItems?.length} />
                <div className="w-full scroll border-b overflow-y-scroll h-64">
                    {cart.foodItems && cart.foodItems?.length > 0 ? (
                        cart.foodItems?.map((el, index) => (
                            <CartItem key={index} cart={cart} item={el} setCart={setCart} index={index} />
                        ))
                    ) : (
                        <div className='flex items-center justify-center h-full'>
                            <p className='text-gray-600 font-semibold text-lg px-5 py-3 border-2 bg-gray-100 border-gray-900 rounded-lg'>No Food is Added to cart</p>
                        </div>
                    )}

                </div>

                <div className="text-md text-gray-800 w-full pt-2 px-4 text-center">
                    <CartAllergyNote setCart={setCart} cart={cart} />
                    {
                        cart.foodItems?.length === 0 ? (
                            <CartCheckout buttonText="Add Food to To Cart" totalPrice={0} />
                        ) : <CartCheckout onClick={handleClick} buttonText="Checkout" totalPrice={cart.subTotal} />
                    }

                </div>
            </div>
        </div>

    )


}
