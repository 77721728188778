import React, { useState } from 'react';
import Field from './../utilities/form/Field';


const CardInfo = () => {
    const [focus, setFocus] = useState("");
    const [expTime, setExpTime] = useState("");
    const customerInfo = JSON.parse(localStorage.getItem("customer"));
    const [formData, setFormData] = useState({
        email: "",
        mobileNo: "",
        cardNumber: "",
        name: "",
        exp_month: "",
        exp_year: "",
        cvc: "",
        walletId: customerInfo.wallet
    });

    const handleChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    console.log(focus, expTime)
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log(formData);
    // };
    return (
        <div div className="sm:flex md:flex flex-wrap items-center mt-0">
            <div className="p-2 w-full sm:w-2/3 md:w-full">
                <div className="text-sec rounded">
                    <p className="mt-3 text-gray-700">Card Number</p>
                    <Field classes="pl-3"
                        name="cardNumber"
                        placeholder="Enter Card Number"
                        onChange={handleChange}
                        onFocus={(e) => setFocus(e.target.name)}
                    />
                </div>
            </div>
            <div className="p-2 w-2/4">
                <div className="text-sec rounded">
                    <p className="mt-3 text-gray-700">MM/YY</p>
                    <Field classes="pl-3"
                        name="exp_year"
                        placeholder="MM/YY Expiry"
                        onBlur={(e) => setExpTime(e.target.value)}
                    />
                </div>
            </div>
            <div className="p-2 w-2/4">
                <div className="text-sec rounded">
                    <p className="mt-3 text-gray-700">CVC</p>
                    <Field classes="pl-3"
                        name="cvc"
                        placeholder="CVC"
                        onChange={handleChange}
                        onFocus={(e) => setFocus(e.target.name)} />
                </div>
            </div>
            {/* <div className="p-2 w-full sm:w-2/3 md:w-full">
                <div className="text-sec rounded">
                    <p className="mt-3 text-gray-700">Name</p>
                    <Field classes="pl-3"
                        name="name"
                        placeholder="Name"
                        onChange={handleChange}
                        onFocus={(e) => setFocus(e.target.name)} />
                </div>
            </div> */}
            {/* <input type="checkbox" class="appearance-none checked:bg-blue-600 checked:border-transparent" /> */}
            
        </div>
    );
};

export default CardInfo;