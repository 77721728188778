import React from 'react'
import { Link } from 'react-router-dom'
import Checkbox from './Checkbox'
export default function AgreementCheck(props) {
    return (
        <div style={props.containerStyle} className={`mx-4 flex items-start cursor-pointer mt-2 ${props.containerClasses}`}>
            <Checkbox onClick={() => props.setCheck(!props.check)} />
            <div className="px-2">
              <p style={props.textStyle} className={`text-sec ${props.textClasses}`}>{props.beforeLink}<span style={props.linkStyle} className={`text-prim ${props.linkClasses}`}><Link to="/terms-of-use">{props.link}</Link></span>{props.afterLink}</p>
            </div>
        </div>
    )
}
