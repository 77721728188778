import React from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useHistory } from "react-router-dom";

const TermsAndCondition = () => {
  const history = useHistory();
  return (
    <div className="bg-nat">
      <div className="mx-12 py-5 flex justify-between">
        <button
          className="bg-prim px-5 py-2 rounded text-white cursor-pointer"
          onClick={() => history.push("")}
        >
          <FiArrowLeft className="inline-block mr-1" />
          Back
        </button>
        <h1 className="text-center text-4xl font-bold">Terms & Conditions</h1>
        <div></div>
      </div>

      <p className="px-12 py-3 text-base md:text-lg">
        <strong>FoodApps</strong> tells you the terms and conditions on which
        food and drink are listed on this website and will be supplied by the
        listed Restaurants or Takeaways to you.
        <br />
        <br />
        Please read these terms and conditions carefully before completing the
        checkout through this site. By marking the read option you should
        understand that you are agreeing to be bound by these terms and
        conditions. If you refuse to accept these terms and conditions, you will
        not be able to complete the checkout through this website.
        <br />
        <br />
        <a href="www.foodapps.uk" target="_blank" className="text-blue-500">
          www.foodapps.uk
        </a>{" "}
        operated by NETSOFTUK SOLUTION LTD which is a trading name of this
        Company. We are registered in the UK under company NO-
        <strong>12235478</strong> and with our registered office- Unit-3, 2a
        Beaconsfield Street, Newcastle Upon Tyne, Tyne & Wear, United Kingdom,
        NE4 5JN
        <br />
        <br />
        <strong>FoodApps</strong> is only intended for use by people’s postcode
        residents in their specified Areas by this website. The purpose of this
        website is able you to buy Foods from the Chosen Restaurants or
        Takeaways.
        <br />
        <br />
        By placing an order with us, you declare to the Restaurants or Takeaways
        you have selected that-
        <br />
        You are legally capable of entering into binding contracts; and
        <br />
        <strong>1.</strong> You are at least 18 years old, If you are purchasing
        alcohol and appear to be under the age of 21 you will be required to
        prove that you are over 18. Only the following forms of I.D. will be
        accepted: Passport, Photo Driving License.
        <br />
        <strong>2. Cancel Policy:</strong>
        <br />
        Once an order is placed, you will be provided with the option to cancel
        the order up until the point of acceptance from the Outlet. You will not
        have any right to cancel an order once accepted by the Outlet.
        <br />
        <strong>Delivery Policy:</strong>
        <br />
        The Outlet´s goal is to deliver or make available on-time i.e.
        delivering at the time it quotes. Unfortunately factors, such as,
        weather and traffic conditions occasionally prevent the Outlet from
        achieving this. The Outlet cannot be responsible for late delivery.{" "}
        <br />
        <strong>3. Delivery Policy:</strong>
        <br />
        The Outlet´s goal is to deliver or make available on time i.e.
        delivering at the time it quotes. Unfortunately, factors, such as
        weather and traffic conditions occasionally prevent the Outlet from
        achieving this. The Outlet cannot be responsible for late delivery.
        <br />
        <strong>4. Payment Policy:</strong>
        <br />
        <strong>4.1</strong> Payment for all Products must be by credit or debit
        card to be charged as specified on this website or by cash paid to the
        Outlet on delivery or collection.
        <br />
        <strong>4.2</strong> If Payment is by credit or debit card then the
        order is only charged once the takeaway accepts the order. However,
        before the order is sent to the takeaway we need to check and set aside
        the available funds in your bank
        <br />
        <strong>User Liability:</strong>
        <br />
        <strong>5.1</strong> The Outlet´s liability for failing to deliver
        Products of satisfactory quality or otherwise breaking this contract is
        limited to the price of those Products.
        <br />
        <br />
        <strong>5.2</strong> Any complaints relating to your order should first
        be directed to NETSOFTUK SOLUTION LTD we are responsible for all
        customer service.
        <br />
      </p>
      <h1 className="text-center font-bold mt-4 text-2xl">
        Business Owner Policy
      </h1>
      <p className="px-12 py-3 text-base md:text-lg">
        <strong>FoodApps UK</strong> tells you the terms and conditions on which
        food and drink will be collected from the listed Restaurants or
        Takeaways and delivered to the allocated customers.<br></br>
        Please read these terms and conditions carefully before completing the
        registration as <br></br>
        <strong>FoodApps Partner</strong> by marking the read option you should
        understand that you are agreeing to be bound by these terms and
        conditions. If you refuse to accept these terms and conditions, you will
        not be able to complete the registration through this website.<br></br>
        <strong> www.foodapps.uk</strong> operated by NETSOFTUK SOLUTION LTD
        which is the trading name of this Company. We are registered in the UK
        under company NO-12235478 and with our registered office- Unit-3, 2a
        Beaconsfield Street, Newcastle Upon Tyne, Tyne & Wear, United Kingdom,
        NE4 5JN<br></br>
        <strong>6. Owner Policy</strong>
        <br></br>
        <strong>FoodApps</strong> is only intended for{" "}
        <strong>Business owners</strong> by people’s postcode residents in their
        specified Ares by this website. The purpose of this website can deliver
        the Foods Food from the Chosen Restaurants or Takeaways. <br></br>
        <strong>6.1 Cancel Policy:</strong>
        <br></br>
        Once an order is placed, you will be provided with the option to cancel
        the order up until the point of acceptance from the Outlet. You will not
        have any right to cancel an order once accepted by the Outlet.<br></br>
        <strong>6.2. Delivery Policy:</strong>
        <br></br>
        The Outlet´s goal is to deliver or make available on time i.e.
        delivering at the time it quotes. Unfortunately, factors, such as
        weather and traffic conditions occasionally prevent the Outlet from
        achieving this. The Outlet cannot be responsible for late delivery.
        <br></br>
        <strong> 6.3. Right to Business: </strong>
        <br></br>
        It’s the <strong>Business owner's</strong> responsibility to comply with
        all legal requirements with the local council as well as health & safety
        policy to run the Food Business. We are not liable for any
        responsibility if they fail to maintain any points of legal points.
        <br></br>
        <strong>6.4. Not show policy:</strong>
        <br></br>
        All drivers need to inform Restaurants/ Takeaways if any Customers do No
        Show their visibility to pick up their Food. They have to wait at least
        10 minutes to confirm the NO SHOW message to customer Support and return
        the Food to Restaurants/ Takeaways.<br></br>
        <strong>6.5 Terminate by the Restaurants/ Takeaways:</strong>
        <br></br>
        Restaurants/ Takeaways can terminate the Customer’s order due to payment
        failure or anti-social behaviour by the Customers.<br></br>
        <strong>6.6 Payment Policy:</strong>
        <br></br>
        Payment for all <strong>Business owners</strong> will be weekly payment
        via direct payments to their own Bank. Other payment option needs to
        inform by the customer support team. Bank charges are 2% of the price
        plus 20 pens per order.
      </p>
      <h1 className="text-center font-bold mt-4 text-2xl">Rider Policy</h1>
      <p className="px-12 py-3 text-base md:text-lg">
        <strong>FoodApps UK</strong> tells you the terms and conditions on which
        food and drink will be collected from the listed Restaurants or
        Takeaways and delivered to the allocated customers.<br></br>
        Please read these terms and conditions carefully before completing the
        registration as <strong>FoodApps Rider</strong> by marking the read
        option you should understand that you are agreeing to be bound by these
        terms and conditions. If you refuse to accept these terms and
        conditions, you will not be able to complete the registration through
        this website.<br></br>
        <strong> www.foodapps.uk</strong> operated by NETSOFTUK SOLUTION LTD
        which is the trading name of this Company. We are registered in the UK
        under company NO-12235478 and with our registered office- Unit-3, 2a
        Beaconsfield Street, Newcastle Upon Tyne, Tyne & Wear, United Kingdom,
        NE4 5JN<br></br>
        <strong>7. FoodApps</strong> is only intended for Drivers by people’s
        postcode residents in their specified Ares by this website. The purpose
        of this website can deliver the Foods Food from the Chosen Restaurants
        or Takeaways.<br></br>
        <strong>7.1 Cancel Policy:</strong>
        <br></br>Once an order is placed, you will be provided with the option
        to cancel the order up until the point of acceptance from the Outlet.
        You will not have any right to cancel an order once accepted by the
        Outlet.<br></br>
        <strong>7.2. Delivery Policy:</strong>
        <br></br>The Outlet´s goal is to deliver or make available on time i.e.
        delivering at the time it quotes. Unfortunately, factors, such as
        weather and traffic conditions occasionally prevent the Outlet from
        achieving this. The Outlet cannot be responsible for late delivery.
        <br></br>
        <strong>7.3. Right to work:</strong>
        <br></br>we will check the driver’s right to work in the UK according to
        the Home office guideline.<br></br>
        <strong>7.4. Vehicle Information: </strong>
        <br></br>All drivers need to update their Vehicle information regularly
        depending on their changes. <br></br>
        <strong>7.5. Driving licence: </strong>
        <br></br>All drivers need to update their Driving licence information
        regularly depending on their changes. <br></br>
        <strong>7.6. Not show policy:</strong>
        <br></br>All drivers need to inform Restaurants/ Takeaways if any
        Customer not showing their visibility to pick up their Food. They have
        to wait at least 10 minutes to confirm the NO SHOW message to customer
        Support and return the Food to Restaurants/ Takeaways. <br></br>
        <strong>7.7. Terminate by the</strong>
        <br></br>Restaurants/ Takeaways: Restaurants/ Takeaways can terminate
        the Customer’s order due to payment failure or anti-social behaviour by
        the Customers. <br></br>
        <strong>7.8 </strong>
        <br></br>Payment for all Drivers will be weekly payment via direct
        payments to their own Bank. Other payment option needs to inform by the
        customer support team.<br></br>
        <strong>8. Private Policy</strong>
        <br></br>
        <strong>8.1. User: </strong>
        We are committed to safeguarding the privacy of our website visitors;
        this policy sets out how we will treat your personal information. Our
        website uses cookies. By using our website and agreeing to this policy,
        you consent to our use of cookies under the terms of this policy.
        <br></br>
        <strong>8.2. Owner:</strong>
        We are committed to safeguarding the privacy of our website visitors;
        this policy sets out how we will treat your personal information. Our
        website uses cookies. By using our website and agreeing to this policy,
        you consent to our use of cookies under the terms of this policy.
        <br></br>
        <strong>8.3. </strong>
        Rider: We are committed to safeguarding the privacy of our website
        visitors; this policy sets out how we will treat your personal
        information. Our website uses cookies. By using our website and agreeing
        to this policy, you consent to our use of cookies under the terms of
        this policy.
        <br></br>
      </p>
      <h1 className="text-center font-bold mt-4 text-2xl">Employee Policy </h1>
      <p className="px-12 py-3 text-base md:text-lg">
        <strong>FoodApps UK </strong>tells you the terms and conditions on
        joining with us a Dedicated team member to work with us from anywhere to
        contribute our goal as a world-class company with a mission of Highly
        professional attitude, Zero tolerance, Dedication, Honesty, Targeting,
        Time Keeping, Secure company digital asset, Respecting Colleagues and
        value the Company. <br></br>
        <strong>9.1 </strong> the employee must read these terms and conditions
        carefully from our website before receiving any joining letter as a
        Freelancer (all type), Part-Time and Full-time In-office or Remote. They
        also have to hand over their responsibilities, complete the assigned
        task to the designated employee and sign the No claim declaration form
        when they decide to leave the company. Unauthorised access to our server
        during the transitional period will consider as a trace pass of the
        company data.<br></br> The company is not going to inform individuals to{" "}
        <strong>read and sign</strong> employee policy to join our team. If you
        have any issues or do not agree with our company policy which is
        reserved for the employee, highly recommended not to join us. Once an
        employee with any sort of contribution from internee to full time will
        be considered as our team member and they have to accept company
        policies to guide them on their roles and responsibilities, as well as
        the company’s overarching business principles, ethics and beliefs for
        compliance reasons and to ensure a healthy company culture.<br></br>{" "}
        Failing to do so the company will take further legal action according to
        their position and responsibility by NETSOFTUK SOLUTION LTD which is the
        trading name of this Company.
        <br></br>
        <strong>9.2</strong>
        Maintain agreed working hours, code of conduct, terms of payment rates,
        advanced day leave or sick leave.
        <br></br>
        <strong>9.3</strong>
        Honesty to use the employee's home address and authentic form of
        identification to check the verification. False information and fake
        identification will consider a violation of company policy.
        <br></br>
        <strong>9.4</strong>
        Use their PC or Laptop if are assigned as remote employees and maintain
        authorised tools to keep the records and digital safety.
        <br></br>
        <strong>9.5</strong>
        Establish what’s acceptable and what’s not in regards to the Internet,
        email, and social media usage for personal purposes at work or tracking
        periods for remote employees.
        <br></br>
        <strong>9.6</strong>
        Protect the company and data to use the user’s or partner’s data by
        establishing a policy that encourages transparency and trust with our
        customers and partners. Without the permission of any sort of data,
        access will accuse of criminal prosecution.
        <br></br>
        <strong>9.7</strong>
        Set an acceptable amount of time for payment, and establish consequences
        when payment is overdue or not received. Without informing any formal
        email or complaint we will consider employee received their payment.
        <br></br>
        <strong>9.8</strong>
        Protect sensitive information, and be sure to cover relationships with
        customers and other partners.
        <br></br>
        <strong>9.9</strong>
        Make sure employees have no personal retaliation against the company
        policy which is harmful to employees and the company.
        <br></br>
        <strong>9.10</strong>
        We will assign the role of every employee, including their level of
        responsibility, amount of authority for decision-making, overarching
        goals and specific tasks. We will identify clear methods for monitoring
        performance and developing employees through training and meeting.
        <br></br>
        <strong>9.11 Document and Records Retention Policies–</strong>
        Employees will not store company data or any documents in their local
        store after leaving the company, they have to delete all their data and
        records to avoid further consequences to damage the company’s
        reputation.
        <br></br>
      </p>
    </div>
  );
};

export default TermsAndCondition;
