import React from 'react'

import Bookingvalue from './BookingValue'
export default function BookingBody({ booking }) {

    return (
        <div className="w-full p-2 sm:flex md:flex flex-wrap justify-between">
            <div className="w-full sm:w-2/3 md:w-2/3">
                <Bookingvalue title="Name" value={booking.customerName} classes="mt-2" />
                <Bookingvalue title="Booking for" value={booking.bookingFor} classes="mt-2" />
                <Bookingvalue title="Contact No" value={booking.mobileNo} classes="mt-2" />
                <Bookingvalue title="Restaurant" value={booking.restaurant?.name} classes="mt-2" />
            </div>
            <div className="w-full sm:w-1/3 md:w-1/3 sm:text-right md:text-right sm:flex md:flex sm:flex-col md:flex-col justify-between">
                <Bookingvalue title="Guest No" value={booking.guestNo} classes="mt-2 sm:mt-0 md:mt-0" />
                <Bookingvalue title="Reservation" value={booking.bookingDate} classes="mt-2 sm:mt-0 md:mt-0" />
                <Bookingvalue title="Time" value={booking.bookingTime} classes="mt-2 sm:mt-0 md:mt-0" />
            </div>
        </div>
    )
}
