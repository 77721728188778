import {
    GET_ALL_CUISINE_SUCCESS, GET_ALL_CUISINE_FAILURE
  } from "../types/cuisine/type";
  
  import { apiBaseUrl, apiVersionPrefix } from "../../config";
  import axios from "axios";
  
  export const getAllCuisines = async (dispatch, type) => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${apiBaseUrl}${apiVersionPrefix}/cuisine/get-all-cuisines`,
      );
      if (data) {
        dispatch({ type: GET_ALL_CUISINE_SUCCESS, payload: data });
      }
    } catch (err) {
    
      const {
        response: {
          data: { error },
        },
      } = err;
      dispatch({ type: GET_ALL_CUISINE_FAILURE, payload: error });
    }
  };
  