import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { HelmetProvider } from "react-helmet-async";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';

import UserLayout from "../../../components/layout/UserLayout/index";
import Heading from "../../../components/utilities/Heading";
import Button from "../../../components/utilities/buttons/Button";
import ShowCard from './ShowCard'

import { getCookie } from '../../../components/utilities/cookie/cookie';
import { getCards } from "../../../redux/actions/wallet";

const UserMyWallet = () => {
  const dispatch = useDispatch();
  const token = getCookie('token');
  const customer = JSON.parse(localStorage.getItem("customer"))._id
  useEffect(() => {
    getCards(dispatch, customer, token);
  }, [dispatch, customer, token])

  const { cards, error } = useSelector(state => state.wallet)
  useEffect(() => {
    toast.error(error);
  }, [error])

  return (
    <HelmetProvider>
      <Helmet>
        <title>FoodApps | My Wallet</title>
      </Helmet>
      <UserLayout>
        <ToastContainer />
        <Heading>My Wallet</Heading>
        <ShowCard cards={cards} deletable />

        <div className="flex justify-end mt-3">
          <Link to="add-user-card">
            <Button>Add Payment Method</Button>
          </Link>
        </div>
      </UserLayout>
    </HelmetProvider>
  );
};

export default UserMyWallet;
