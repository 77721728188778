import React, { useState } from 'react'

import { IoBagCheckSharp } from 'react-icons/io5'
import CartPopup from './CartPopup';

export default function MobileCart({cart, setCart, isOpen }) {
    const [openModal, setOpenModal] = useState(false);
    return (
        <>
            <div onClick={() =>  setOpenModal(true)} className="flex mx-4 justify-between fixed bottom-0 left-0 right-0 bg-prim cursor-pointer p-1 sm:p-3 text-white rounded-l-lg rounded-r-lg sm:hidden">
                <div className="flex sm:justify-between items-center">
                    <IoBagCheckSharp className="text-lg" />
                    {cart.foodItems.length > 0 ?
                        <p className="font-semibold">{cart.foodItems.length} Items</p>
                    :
                        <p className="font-semibold">0 Items</p>
                    }
                </div><br />
                <div className="py-3 px-5 rounded-l-lg rounded-r-lg bg-white text-prim">
                    <p>₤{Number.parseFloat(`${cart.foodItems.length > 0 ?cart.subTotal: "00"}`).toFixed(2)}</p>
                </div>
            </div>
            <CartPopup openModal={openModal} setOpenModal={setOpenModal} cart={cart} setCart={setCart} isOpen={isOpen} />
        </>
    )
}