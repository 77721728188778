/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import { getRestaurantCategories } from '../../redux/actions/categories';
import { getSingleRestaurant } from '../../redux/actions/restaurantInfo';

import Cart from '../../components/cart';
import Categories from './categories';
import Products from './products';
import Navbar from '../../pages/Home/Navbar';
import OrderHeader from './OrderHeader';
import BookingPopup from "../../components/popup/BookingPopup";
import MobileCart from '../../components/cart/MobileCart';

export default function Order() {

    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);

    const { id } = useParams();

    const restaurantId = id;

    const { categories, category } = useSelector(state => state.categories);

    const { restaurantInfo } = useSelector(state => state.restaurantInfo);
    //
    const restaurantTiming = restaurantInfo?.restaurantTiming?.restaurantTiming;
    const date = new Date();
    const closeOrOpen = restaurantTiming?.available?.find((it) => it.day === date.getDay());
    const start=new Date(Date.now()).setHours(closeOrOpen?.startTime.split(':')[0],closeOrOpen?.startTime.split(':')[1]);
      const end=new Date(Date.now()).setHours(closeOrOpen?.endTime.split(':')[0],closeOrOpen?.endTime.split(':')[1]);
      const currentTime=new Date(Date.now());
      let available=false;
      (closeOrOpen?.isOpen)?(start<currentTime && end>currentTime)?available=true:available=false:available=false;
    //
  
    localStorage.setItem("restaurant",restaurantInfo._id);
    const tableNo  = useLocation()?.search.includes("tableNo");
    
    const [order, setOrder] = useState(
        {
            "restaurant": localStorage.getItem("restaurant"),
            "restaurantUniId": restaurantId,
            "orderType": tableNo? "Dine In" : JSON.parse(localStorage.getItem('order'))?.orderType? JSON.parse(localStorage.getItem('order'))?.orderType:"Dine In",
            "discount": null,
            "coupon": null,
            "customer": localStorage.getItem("customer") ? JSON.parse(localStorage.getItem('customer'))._id : "",
            "customerName": localStorage.getItem("customer") ? JSON.parse(localStorage.getItem('customer')).name : "",
            "mobileNo": localStorage.getItem("customer") ? JSON.parse(localStorage.getItem('customer')).mobileNo : "",
            "subTotal": 0,
            "shippingFee": 0,
            "totalPrice": 0,
            "paymentMethod": "",
            "pickupTime": "",
            "kitchenNotes": "",
            "deliveredAt": "",
            "riderService": restaurantInfo?.restaurantDelivery?.isRiderService,
            "driver": null,
            "cartItems": "",
            "serviceCharge": 0,
            "address": localStorage.getItem("customer") ? JSON.parse(localStorage.getItem('customer')).address?.address : "",
            "postCode": localStorage.getItem("customer") ? JSON.parse(localStorage.getItem('customer')).address?.postCode : "",
            "deliveryCharge": 0
        }
    )
    localStorage.setItem("order", JSON.stringify({...order, 
        orderType: JSON.parse(localStorage.getItem('order'))?.orderType? JSON.parse(localStorage.getItem('order'))?.orderType:"Dine In",
        restaurant:localStorage.getItem("restaurant")}));
    const [isAdded, setIsAdded] = useState({
        name: "",
        add: false
    })

    let [cart, setCart] = useState({
        customer: "",
        restaurant: restaurantId,
        foodItems: [],
        count: 0,
        subTotal: 0,
        note: ""
    })

    useEffect(() => {

        localStorage.setItem("order", JSON.stringify(order));

        localStorage.setItem("currentPage", "orderPage")

        const myCart = JSON.parse(localStorage.getItem('cart'));

        if (myCart && myCart.restaurant === restaurantId) {
            setCart({ ...cart, foodItems: myCart.foodItems, subTotal: myCart.subTotal })
        } else {
            setCart({
                customer: "",
                restaurant: restaurantId,
                foodItems: [],
                count: 0,
                subTotal: 0,
            })
        }
    }, [restaurantId])

    useEffect(() => {
        const abortController = new AbortController()
        getRestaurantCategories(dispatch, restaurantId);
        return () => abortController.abort()
    }, [dispatch, restaurantId])

    useEffect(() => {
        getSingleRestaurant(dispatch, restaurantId);
        setOrder({ ...order, restaurant: restaurantInfo._id, restaurantUniId: restaurantId })
        localStorage.setItem("order", JSON.stringify(order));
    }, [dispatch, restaurantInfo._id, restaurantId])



    const location = useLocation().search;

    if (location) {
        const params = location?.split("?")[1].split("=")[0];
        if (params === "tableNo") {
            localStorage.setItem("tableNo", location.split("?")[1].split("=")[1]);
        }
    }
    else {
        localStorage.removeItem("tableNo");
    }
    
    return (
        <div className="bg-nat">
            <Navbar setCart={setCart} isOpen={closeOrOpen?.isOpen} />
            <OrderHeader setOpenModal={setOpenModal} restaurant={restaurantInfo} />
            <div className="container mx-auto mt-8 sm:flex md:flex px-0 bg-gray pb-8 bg-white">
                {
                    category && categories ? (
                        <>
                            <Categories />
                            <Products setCart={setCart} cart={cart} />
                            <div className="hidden md:block w-full sm:w-3/12 md:w-3/12 mt-10 sm:mt-0 md:mt-0 shadow-lg">
                                <Cart cart={cart} setCart={setCart} isAdded={isAdded} setIsAdded={setIsAdded} isOpen={available} />
                            </div>
                        </>
                    ) : <span>Loading</span>
                }
            </div>
            <MobileCart cart={cart} setCart={setCart} isOpen={available} />
            <BookingPopup openModal={openModal} setOpenModal={setOpenModal} restaurant={restaurantInfo} />
        </div>
    )

}