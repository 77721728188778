import {
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAVOURITE_SUCCESS,
  GET_CUSTOMER_FAILURE,
  GET_CUSTOMER_FAVOURITE_FAILURE,
  GET_OTP_SUCCESS,
  GET_OTP_FAILURE,
  CUSTOMER_LOGOUT_SUCCESS,
  CUSTOMER_LOGOUT_FAILURE
} from "../types/customer/types";

const initialState = {
  customer: {},
  customers: [],
  status: null,
  authError: null,
  loading: true,
  otp: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        customer: action.payload,
        authError: null,
      };

    case GET_CUSTOMER_FAVOURITE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        customer: action.payload,
        authError: null,
      };

    case GET_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        status: "Failed",
        authError: action.payload,
        customer: {},
      };

    case GET_CUSTOMER_FAVOURITE_FAILURE:
      return {
        ...state,
        loading: false,
        status: "Failed",
        authError: action.payload,
        customer: {},
      };

    case GET_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        otp: "success",
        authError: null,
        customer: {},
      };

    case GET_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        otp: "Failed",
        error: action.payload,
        customer: {},
      };
      case CUSTOMER_LOGOUT_SUCCESS:
        return{
          loading: false,
          status: "success",
          customer: {},
          authError: null,
        };
        case CUSTOMER_LOGOUT_FAILURE:
          return{
            ...state,
            authError:action.playload
          }

    default:
      return state;
  }
};

export default reducer;
