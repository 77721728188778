import {
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAVOURITE_SUCCESS,
  GET_CUSTOMER_FAILURE,
  GET_CUSTOMER_FAVOURITE_FAILURE,
  GET_OTP_SUCCESS,
  GET_OTP_FAILURE,
  CUSTOMER_LOGOUT_SUCCESS,
  CUSTOMER_LOGOUT_FAILURE,
} from "../types/customer/types";
import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";
import { deleteCookie, setCookie } from "../../components/utilities/cookie/cookie";

export const getCustomerLogin = async (dispatch, formData, history) => {
  try {
    const {
      data: { data, token },
    } = await axios.post(`${apiBaseUrl}${apiVersionPrefix}/customer/signin`, formData);

    if (data) {
      setCookie("token", token, 360000);

      localStorage.setItem("customer", JSON.stringify(data));

      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });

      if (localStorage.getItem('currentPage') === "checkout") {

        const order = JSON.parse(localStorage.getItem("order"));

        if (order.orderType === "Collection") {
          history.push('/checkout-collection')
        }
        else if (order.orderType === "Home Delivery") {
          history.push('/checkout-home-delivery')
        }
        else if (order.orderType === "Dine In") {
          history.push('/checkout-dine-in')
        }
      } else if(localStorage.getItem('currentPage') === "orderPage") {
        const order = JSON.parse(localStorage.getItem("order"));
        history.push(`/order/${order.restaurantUniId}`)
      } else {
        history.push("/");
      }
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    // console.log(error)
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};

export const getCustomerSignup = async (dispatch, formData, history) => {
  try {
    const { data: { data, token } } = await axios.post(`${apiBaseUrl}${apiVersionPrefix}/customer/signup`, formData);

    if (data) {
      setCookie("token", token);
      localStorage.setItem("customer", JSON.stringify(data));

      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });

        if (localStorage.getItem('currentPage') === "checkout") {

          const order = JSON.parse(localStorage.getItem("order"));
  
          if (order.orderType === "Collection") {
            history.push('/checkout-collection')
          }
          else if (order.orderType === "Home Delivery") {
            history.push('/checkout-home-delivery')
          }
          else if (order.orderType === "Dine In") {
            history.push('/checkout-dine-in')
          }
        } else if(localStorage.getItem('currentPage') === "orderPage") {
          const order = JSON.parse(localStorage.getItem("order"));
          history.push(`/order/${order.restaurantUniId}`)
        } else {
          history.push("/");
        }

    }

  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};
export const customerLogOut=async(dispatch,history)=>{
try {
  const data=await axios.get(
    `${apiBaseUrl}${apiVersionPrefix}/customer/logout`)
    if (data) {
      dispatch({ type: CUSTOMER_LOGOUT_SUCCESS, payload: data });
       deleteCookie('token')
        window.localStorage.removeItem("customer");
        history.push('/');
    }
} catch (err) {
  console.log(err);
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: CUSTOMER_LOGOUT_FAILURE, payload: error });
}
}

export const getLoggedCustomerInfo = async (dispatch, token) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/customer/get-customer-own`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });
    }
  } catch (err) {
    console.log(err);
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};

export const createCustomerAddress = async (dispatch, obj) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${apiBaseUrl}${apiVersionPrefix}/address/create-customer-address`,
      obj.formData,
      {
        headers: {
          Authorization: `Bearer ${obj.token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};

export const updateCustomerAddress = async (
  dispatch,
  { token, id, formData }
) => {
  try {
    const {
      data: { data },
    } = await axios.put(
      `${apiBaseUrl}${apiVersionPrefix}/address/update-customer-address/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data) {
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });
      localStorage.setItem("customer", JSON.stringify(data));
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};

export const updateCustomer = async (dispatch, { token, formData }) => {
  try {
    const {
      data: { data },
    } = await axios.put(`${apiBaseUrl}${apiVersionPrefix}/customer/update-customer`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (data) {
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });
      localStorage.setItem("customer", JSON.stringify(data));
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};

export const getCustomerByPhone = async (dispatch, formData) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${apiBaseUrl}${apiVersionPrefix}/customer/get-customer-by-phone`,
      formData
    );
    localStorage.setItem("customer", JSON.stringify(data));
    if (data) {
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });

    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};

export const resetNewPassword = async (dispatch, formData) => {
  try {
    const {
      data: { data },
    } = await axios.put(`${apiBaseUrl}${apiVersionPrefix}/customer/set-new-password`, formData);

    if (data) {
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};

export const guestCustomerLogin = async (dispatch, formData, history) => {
  try {
    const {
      data: { data, token },
    } = await axios.post(`${apiBaseUrl}${apiVersionPrefix}/guest/guest-signup`, formData);
    if (data) {
      setCookie("token", token);
      localStorage.setItem("customer", JSON.stringify(data));

      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });

      if (localStorage.getItem('currentPage') === "checkout") {

        const order = JSON.parse(localStorage.getItem("order"));

        if (order.orderType === "Collection") {
          history.push('/checkout-collection')
        }
        else if (order.orderType === "Home Delivery") {
          history.push('/checkout-home-delivery')
        }
        else if (order.orderType === "Dine In") {
          history.push('/checkout-dine-in')
        }
      }
      else if(localStorage.getItem('currentPage') === "orderPage") {
        const order = JSON.parse(localStorage.getItem("order"));
        history.push(`/order/${order.restaurantUniId}`)
      } else {
        history.push("/");
      }

    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    // console.log(error)
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};

export const checkGuestMobile = async (dispatch, formData) => {
  try {
    const {
      data: { data },
    } = await axios.post(`${apiBaseUrl}${apiVersionPrefix}/guest/check-guest-mobile`, formData);
    if (data) {
      dispatch({ type: GET_OTP_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    // console.log(error)
    dispatch({ type: GET_OTP_FAILURE, payload: error });
  }
};

export const getAllFavourites = async (dispatch, id, token) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/customer/get-favourite-restaurant/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });
    }
  } catch (err) {
    // const {
    //   response: {
    //     data: { error },
    //   },
    // } = err;
    // // console.log(error)
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: err });
  }
};

export const addCustomerFavourite = async (dispatch, id, token, { restaurantId }) => {
  try {
    const {
      data: { data },
    } = await axios.put(
      `${apiBaseUrl}${apiVersionPrefix}/customer/add-favourite-restaurant/${id}`, { restaurantId },
      {
        // method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data) {
      dispatch({ type: GET_CUSTOMER_FAVOURITE_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_CUSTOMER_FAVOURITE_FAILURE, payload: error });
  }
};
