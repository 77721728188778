import React from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { cancelOrder, getCustomerBookingOrders } from '../../redux/actions/order';
import { getCookie } from '../utilities/cookie/cookie';
import BookingBody from './BookingBody.js/index.js';
import BookingHeader from './bookingHeader';

toast.configure();

export default function Booking({ booking }) {

    const dispatch = useDispatch();
    const orderId = booking._id;
    const token = getCookie("token");
    const customer = JSON.parse(localStorage.getItem("customer"));
    const customerId = customer._id;

    const handleCancel = () => {
        cancelOrder(dispatch, orderId, token);
        getCustomerBookingOrders(dispatch, customerId, token);
        toast.success("Booking is Canceled", { position: toast.POSITION.TOP_CENTER })
    }

    return (
        <div className="py-2">
            <ToastContainer style={{ fontSize: "1rem" }} />
            <div className="w-full rounded-lg bg-white shadow-lg border p-2 flex flex-wrap justify-between">
                <BookingHeader booking={booking} />
                <BookingBody booking={booking} />
                {booking.orderStatus &&
                    <div className={(booking.orderStatus === "Canceled" || booking.orderStatus === "Accepted") ? "hidden" : "flex items-center px-2"}>
                        <span className='font-semibold text-red-700'>Do You want to Cancel this Booking?</span>
                        <button onClick={handleCancel} className="hover:bg-red-700 hover:text-white border-red-700 border-2 text-red-700 px-4 py-2 mx-2 rounded-lg font-semibold ">Cancel Booking</button>
                    </div>}
            </div>
        </div>
    )
}
