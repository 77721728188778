import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom'

import { FiMenu, FiShoppingCart } from 'react-icons/fi';

import IncogMobileNavbar from './IncogMobleNavbar';
import Icon from '../../../../components/utilities/Icon'
import CartPopup from '../../../../components/cart/CartPopup';

function MobileNavbar({ setCart, isAvailable }) {
    const history = useHistory()
    const [openModal, setOpenModal] = useState(false);
    const [openMenu, setOpenMenu] = useState(false)
    const cart =  JSON.parse(localStorage.getItem('cart'));

    const uniId =  JSON.parse(localStorage.getItem('order'))?.restaurantUniId;

    const handleCart = () => {
        if(cart.foodItems.length > 0) {
            if(history.location.pathname === "/" || history.location.pathname === "/home") {
                history.push(`order/${uniId}`)
            } else {
                setOpenModal(true);
                }
            
        } else {
            alert("You have no items in your cart!");
        }
    }
    return (
        <>
            <div className="block sm:block md:hidden lg:hidden items z-100 bg-gray center w-full shadow-lg">
                <div className="w-full flex items-center justify-between px-5  py-2 fixed z-50  bg-white">
                    <Link to='/'>
                        <img alt="foodapps.uk" className="h-10 cursor-pointer" src="https://foodapps.uk/images/logos/logo.png" />
                    </Link>
                    <div className="flex">
                    {cart &&
                        <div onClick={handleCart} className="px-1 sm:px-3 md:px-3 cursor-pointer">
                            <div className="relative">
                                {cart.foodItems.length > 0 && 
                                <div className="bg-prim w-3 h-3 flex items-center justify-center absolute p-2 top-0 right-0 rounded-full -mt-2 -mr-2 text-white text-xs">
                                    {cart.foodItems.length}
                                </div>}
                                <Icon icon={FiShoppingCart} />
                            </div>
                        </div>
                        }
                        <FiMenu onClick={() => setOpenMenu(!openMenu)} className="cursor-pointer text-lg mt-1" />
                    </div>
                    
                </div>
                <div className="w-full">
                    { openMenu &&
                    <div className="fixed z-50 w-full mt-12">
                        <IncogMobileNavbar />
                    </div>}
                </div>
            </div>
            <CartPopup openModal={openModal} setOpenModal={setOpenModal} cart={cart} setCart={setCart} isAvailable={isAvailable} />
        </>
    )
}

export default MobileNavbar
