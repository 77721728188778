import {
  GET_ALL_CAROUSEL_SUCCESS,
} from "../types/carousel/type";

import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";

export const getAllCarousels = async (dispatch, type) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/carousel/get-all-carousel?type=${type}`,
    );
    if (data) {
      dispatch({ type: GET_ALL_CAROUSEL_SUCCESS, payload: data });
    }
  } catch (err) {

    // const {
    //   response: {
    //     data: { error },
    //   },
    // } = err;
    // dispatch({ type: GET_CAROUSEL_FAILURE, payload: error });
  }
};
