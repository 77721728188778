import React, { useState } from 'react';

import { FiSearch } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { getRestaurantsByPostCode } from '../../../redux/actions/restaurantInfo';

export default function HomeHeader({ setRestHeading }) {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        postCode: "",
    })
    
    const handleSearch = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleClick = async (e) => {
        e.preventDefault();
        await getRestaurantsByPostCode(dispatch, formData);
        window.innerWidth > 400 ? window.scrollTo({top: 850, behavior: 'smooth'}): window.scrollTo({top: 480, behavior: 'smooth'});
        setRestHeading("Results Nearby")
    }
    

    // let [locationData, setLocation] = useState({
    //     latitude: 0,
    //     longitude: 0
    // });

    // useEffect(() => {
    //     navigator.geolocation.getCurrentPosition((position) => {
    //         setLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude })
    //     });

    // }, [])

    // const handleLocaiton = async () => {
    //     await getRestaurantsByLocaiton(dispatch, locationData);
    // }

    return (
        <div className="overflow-hidden w-full half-screen bg-nat z-50 pt-3">
            <div className="relative w-full h-full mt-16 sm:mt-16 md:-mt-16">
                <img alt="food apps" className="hidden md:block absolute bottom-0 w-1/2 sm:w-1/2 md:w-1/3 md:-ml-20 md:-mb-24" src="https://foodapps.uk/images/backgrounds/food3.png" />

                <img alt="food apps" className="hidden md:block absolute top-1  right-0 w-4/12 sm:w-4/12 md:w-3/12 mt-16" src="https://foodapps.uk/images/backgrounds/food2.png" />

                <div className="absolute bottom-0 right-0 -mb-10 mr-10">
                    <div className="relative"></div>
                </div>
                <div className="w-full h-full z-10">
                    <div className="w-full h-full flex justify-center items-center">
                        <div className="w-full sm:w-full md:w-1/2 mt-2 md:mt-20">
                            <div className="text-center">
                                <h1 className="text-2xl md:text-5xl font-bold text-sec">Find Your Favourite</h1>
                                <h2 className="text-md font-thin text-gray-700 mt-3">Dine within your nearest area or order online</h2>
                            </div>
                            <div className="py-20 sm:ml-20 ">
                                <div className="w-full bg-white rounded-lg flex items-center">
                                    <div className="sm:px-2">
                                        <FiSearch className="text-gray-500 text-xl" />
                                    </div>
                                    <input className="px-1 p-2 flex-grow outline-none" name="postCode" value={formData.search} type="text" onChange={handleSearch} placeholder="Enter your post code" onKeyPress={(e) => (e.key === "Enter"? handleClick(): null)} />

                                    {/* <button className="text-red-700 text-lg font-semibold rounded-r-lg sm:p-4 outline-none" onClick={handleLocaiton}><BiCurrentLocation /></button> */}

                                    <button  className="bg-red-700 text-white text-xm sm:font-semibold rounded-r-lg sm:p-4 p-1 outline-none" onClick={handleClick}>Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
