import AddUserCard from "../pages/user/AddUserCard";
import Checkout from "../pages/user/Checkout";
import CheckoutBookATable from "../pages/user/Checkout/CheckoutBookATable";
import CheckoutCollection from "../pages/user/Checkout/CheckoutCollection";
import CheckoutDineIn from "../pages/user/Checkout/CheckoutDineIn";
import OrderReceived from "../pages/user/OrderReceived";
import UserFavourite from "../pages/user/UserFavourite";
import UserMyBooking from "../pages/user/UserMyBooking";
import UserMyOrder from "../pages/user/UserMyOrder";
import OrderSummary from "../pages/user/UserMyOrder/OrderSummary";
import UserMyWallet from "../pages/user/UserMyWallet";
import UserNotifications from "../pages/user/UserNotifications";
import UserProfile from "../pages/user/UserProfile";

const userPrivateRoute = [
    // {
    //     path: '*',
    //     exact: true,
    //     component: Home
    // },
    {
        path: '/order-summary/:id',
        exact: false,
        component:OrderSummary
    },
    {
        path: '/checkout-home-delivery',
        exact: true,
        component: Checkout
    },
    {

        path: '/checkout-collection',
        exact: true,
        component: CheckoutCollection
    },
    {
        path: "/checkout-dine-in",
        exact: true,
        component: CheckoutDineIn
    },
    {
        path: "/checkout-book-a-table",
        exact: true,
        component: CheckoutBookATable
    },
    {
        path: "/order-received",
        exact: true,
        component: OrderReceived
    },
    {
        path: "/favourite",
        exact: true,
        component: UserFavourite
    },
    {
        path: "/user",
        exact: true,
        component: UserProfile
    },
    {
        path: "/my-order",
        exact: true,
        component: UserMyOrder
    },
    {
        path: "/booking",
        exact: true,
        component: UserMyBooking
    },
    {
        path: "/my-wallet",
        exact: true,
        component: UserMyWallet
    },
    {
        path: "/add-user-card",
        exact: true,
        component: AddUserCard
    },
    {
        path: "/notifications",
        exact: true,
        component: UserNotifications
    }
]

export default userPrivateRoute;
