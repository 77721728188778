import React, { useEffect, useState } from "react";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { getAllRestaurants } from "../../redux/actions/restaurantInfo";

import AppSection from "./AppSection";
import Banner from "./Banner";
import CuisineSlider from "./CuisineSlider";
import Footer from "./Footer.js";
import HomeHeader from "./HomeHeader";
import Navbar from "./Navbar";
import Restaurants from "./Restaurants";

export default function Home() {
  const [restHeading, setRestHeading] = useState("All Restaurants");
  const [allRestaurants, setAllRestaurants] =  useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    getAllRestaurants(dispatch);
  }, [dispatch]);

  const { restaurants, error, status } = useSelector((state) => state.restaurantInfo);
  useEffect(() => {
    setAllRestaurants(restaurants);
  },[restaurants])
  
  return (
    <HelmetProvider>
      <Helmet>
        <title>FoodApps | Order food online or book your table</title>
      </Helmet>
      <Navbar setRestHeading={setRestHeading} />
      <HomeHeader setRestHeading={setRestHeading} />
      <div className="bg-white py-12">
        <Banner />
        <CuisineSlider allRestaurants={restaurants}  setAllRestaurants={setAllRestaurants} />
      </div>
      <Restaurants restHeading={restHeading} restaurants={allRestaurants} error={error} status={status} />
      <div className="w-full">
        <div className="w-full flex items-center flex-wrap">
          <AppSection />
          <Footer />
        </div>
      </div>
    </HelmetProvider>
  );
}
