import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { getFoodsByCategory } from "../../../redux/actions/foods";
import { getSingleCategory } from "../../../redux/actions/categories";

export default function Categories(props) {
  let { categories } = useSelector((state) => state.categories);

  const dispatch = useDispatch();
  const [selectedCate, setSelectedCate] = useState(null);

  const handleClick = (categoryId) => {
    getSingleCategory(dispatch, categoryId);
    getFoodsByCategory(dispatch, categoryId);
    setSelectedCate(categoryId);
  };

  useEffect(() => {
    categories.map((data, index) => {
      if (index === 0) {
        setSelectedCate(data._id);
      }
    });
  }, [categories]);

  // setInterval(function(){
  //   //   if(element && element.length>0){
  //     //     rect = element.getBoundingClientRect();
  //     //     console.log(rect)
  //     //   } else {
  //       //     console.log("error")
  //       //   }
  //     const element = document.querySelector(".react-multi-carousel-list");
  //     const rect = element.getBoundingClientRect();
  //     // console.log(rect);
  //   },1000)

  // document.addEventListener('scroll', function(e) {

  // });

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 150,
    },
  };
  return (
    <div className="w-full sm:w-2/12 h-full md:w-3/12 mr-2 my-3 md:my-0  ">
      <div className="text-center">
        {/* <p className="text-xl font-bold border-b py-3 md:py-6 text-gray-700">CATEGORIES</p> */}
      </div>
      <div className="nav-item hidden md:block flex-grow text-start category-scrollbar px-2">
        {categories &&
          categories.map((cate, i) => (
            <a
              key={i}
              onClick={() => handleClick(cate._id)}
              className={`${
                selectedCate === cate._id ? "active" : "bg-white"
              } nav-link
              block
              category-item
              relative
              font-medium
              text-sm
              leading-tight
              uppercase
              border-x-0 border-t-0 border-b-2 border-transparent
              px-6
              py-3
              my-2
              tracking-wider
              cursor-pointer
              hover:bg-gray-100
              focus:border-transparent`}
            >
              {cate.name}
            </a>
          ))}
      </div>
      <Carousel
          className="md:hidden"
          swipeable={true}
          responsive={responsive}
          arrows={false}
          slidesToSlide={1}
          autoPlaySpeed={4000}
          partialVisible={true}
        afterChange={(previousSlide, { currentSlide, onMove }) => {
          categories.map((data, index) => {
            if (index === currentSlide) {
              handleClick(data._id);
            }
          });
        }}
      >
        {categories &&
          categories.map((data, index) => (
            <div
              className="w-full h-full md:p-3 flex justify-center"
              key={index}
              onClick={() => handleClick(data._id)}
            >
              <button
                className={`w-full mr-2 md:mr-0 text-xs md:text-lg font-semibold rounded-lg text-white py-3 px-2 ${
                  selectedCate === data._id ? "bg-prim" : "bg-gray-600"
                }`}
              >
                {data.name}
              </button>
            </div>
          ))}
      </Carousel>
    </div>
  );
}
