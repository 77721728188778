import React from 'react'
import moment from 'moment'

const OrderTimeLine = ({ order }) => {
    return (
        <div>
            {
                <div>
                    <div className="flex items-center justify-between mb-2">
                        <div className="text-xs">
                            <p className={"text-prim"}>{order.recievedTime}</p>
                        </div>
                        <div className="text-xs">
                            <p className={"text-prim"}>{moment().format("hh:mm A")}</p>
                        </div>
                        <div className="text-xs">
                            <p className={order.orderStatus === "Delivered" || order.orderStatus === "Completed" ? "text-prim" : "text-gray-600"}>{order.deliveredAt}</p>
                        </div>
                    </div>

                    <div className="flex items-center mt-2 md:mt-0">
                        <div className="w-5 h-5 rounded-full border-2 border-red-700 p-1">
                            <div className="w-full h-full rounded-full bg-prim"></div>
                        </div>
                        <div className="w-20 md:w-32 border-t-2 border-red-700"></div>
                        <div className="w-5 h-5 rounded-full border-2 border-red-700 p-1">
                            {
                                order.orderStatus !== "delivered" ?
                                    <div className="w-full h-full rounded-full bg-prim"></div>
                                    : null
                            }
                        </div>
                        <div className="w-20 md:w-32 border-t-2 border-red-700"></div>
                        <div className="w-5 h-5 rounded-full border-2 border-red-700 p-1">
                            {
                                order.orderStatus === "delivered" || order.orderStatus === "Completed" ?
                                    <div className="w-full h-full rounded-full bg-prim"></div>
                                    : null
                            }
                        </div>
                    </div>

                    <div className="flex items-center justify-between mt-2">
                        <div className="text-xs">
                            <p className={"text-prim"}>Recieved At</p>
                        </div>
                        <div className="text-xs">
                            <p className={"text-prim"}>Processing</p>
                        </div>
                        <div className="text-xs">
                            <p className={order.orderStatus === "delivered" || order.orderStatus === "Completed" ? "text-prim" : "text-gray-600"}>Completed At</p>
                        </div>
                    </div>

                </div>
            }

        </div>
    )
}

export default OrderTimeLine
