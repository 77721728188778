import React from 'react';
import { Link } from 'react-router-dom';

import MobileNavbar from '../MobileNavbar';
import NormalNavbarMenu from './NormalNavbarMenu';

export default function NormalNavbar({ setCart, isAvailable }) {    
    return (
        <>
            <div className="hidden sm:hidden md:flex lg:flex px-10 items z-100 bg-nat w-full shadowlg py-2">
                <div className="flex w-full justify-between">
                <Link to="/home">
                    <img className="h-16 cursor-pointer" src="https://foodapps.uk/images/logos/logo.png" alt="foodapps.uk" />
                </Link>
                    <NormalNavbarMenu />
                </div>
            </div>
            <MobileNavbar setCart={setCart} isAvailable={isAvailable} />
        </>
    )
}