import React from "react";
import { Link } from "react-router-dom";

import BookingStatus from "../booking/bookingHeader/BookingStatus";
import MyOrderInfo from "./MyOrderInfo";
import moment from "moment";

export default function MyOrder(props) {
  const { order } = props;

  return (
    <div className="w-full sm:w-1/3 md:w-1/3 p-2">
      <div
        style={props.containerStyle}
        className={`rounded-lg border border-red-700 p-2 px-4 ${props.containerClasses}`}
      >
      
        <Link to={`/order-summary/${order._id}`}>
          <div className="py-2 flex text-xs items-center justify-between">
            <p className="text-sm font-bold">{order.orderNumber}</p>
            <BookingStatus
              status={order.orderStatus}
              containerClasses="text-red-700 bg-orange-300 px-3"
            />
          </div>
          <MyOrderInfo title="Order Time" value={moment(order.createdAt).format('h:mm A')} />
          <MyOrderInfo title="Order Date" value={moment(order.createdAt).format('L')} />
          <MyOrderInfo title="Order Type" value={order.orderType} />
          <MyOrderInfo title="Payment Method" value={order.paymentMethod} />
          
          <MyOrderInfo
            title="Item Price"
            value={`₤ ${Number.parseFloat(order?.totalPrice).toFixed(2)}`}
            containerClasses="border-b-2 border-gray-400 "
          />
          <MyOrderInfo title="Total" value={`₤ ${Number.parseFloat(order?.totalPrice).toFixed(2)}`} />
        </Link>
      </div>
    </div>
  );
}
