import counterReducer from "./reducer/counter";
import customerReducer from "./reducer/customer";
import formReducer from "./reducer/form";
import carousels from "./reducer/carousels";
import cuisines from './reducer/cuisine'
import categories from "./reducer/categories";
import foods from "./reducer/foods";
// import superAdminReducer from "./reducer/superAdmin";
import address from "./reducer/address";
import termsAndConditionsReducer from "./reducer/termsAndConditions";
import restaurantReducer from "./reducer/restaurant";
import roles from "./reducer/roles";
import allergies from "./reducer/allergies";
import specialOffers from "./reducer/specialOffer";
import restaurantInfo from "./reducer/restaurantInfo";
import restaurantTiming from "./reducer/restaurantTiming";
import restaurantDelivery from "./reducer/restaurantDelivery";
import order from "./reducer/order";
import notifications from "./reducer/notification";
import coupon from "./reducer/coupon";
import serviceCharge from "./reducer/serviceCharge";
import wallet from './reducer/wallet'
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

const rootReducer = combineReducers({
  counter: counterReducer,
  customer: customerReducer,
  termsAndConditions: termsAndConditionsReducer,
  form: formReducer,
  restaurant: restaurantReducer,
  carousels,
  cuisines,
  roles,
  categories,
  foods,
  allergies,
  specialOffers,
  restaurantInfo,
  restaurantTiming,
  restaurantDelivery,
  address,
  order,
  notifications,
  coupon,
  serviceCharge,
  wallet

});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);
export default store;
