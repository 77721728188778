import { FaTrash } from 'react-icons/fa';
import React from 'react';
import { useDispatch } from "react-redux";
import VisaImg from '../../../images/visa.png'
import MasterImg from "../../../images/masterCard.png";
import AmexImg from "../../../images/americanExpress.png";
import DiscoverImg from "../../../images/discover.png";
import DinersImg from "../../../images/dinersClub.png";
import JcbImg from "../../../images/jcb.png";
import UnionImg from "../../../images/unionPay.png";


import Icon from '../../../components/utilities/Icon';

import { getCards, getDeleteCard } from '../../../redux/actions/wallet';
import { getCookie } from '../../../components/utilities/cookie/cookie';

const ShowCard = ({ cards, deletable, selectable, selected, setSelected }) => {
    // const [expand, setExpand] = useState("");
    const dispatch = useDispatch();
    const token = getCookie('token');

    const handleDelete = async (data) => {
        await getDeleteCard(dispatch, JSON.parse(localStorage.getItem("customer"))._id, data?._id, token)
        getCards(dispatch, JSON.parse(localStorage.getItem("customer"))._id, token);
    }
    return (
        <>
            {cards?.map((data, index) =>
                <div className="mt-3 bg-gray-200 py-3px-3 rounded" key={index}>
                    {/* {(error || message) &&
                        <ErrorMessage classes="my-2" message={error || message} />
                    } */}
                    <div className="flex justify-between">
                        <div className="flex">
                            <div className="flex justify-between items-center">
                                {/* {expand === data._id ?
                                    <Icon onClick={() => setExpand("")} color="text-grey-900" icon={FaChevronUp} />
                                    :
                                    <Icon onClick={() => setExpand(data._id)} color="text-grey-900" icon={FaChevronDown} />
                                } */}

                                <div className="sm:ml-3">
                                    <img src={
                                        (JSON.parse(data.stripeCard)?.brand === "Visa") ? VisaImg : (JSON.parse(data.stripeCard)?.brand === "MasterCard") ? MasterImg : (JSON.parse(data.stripeCard)?.brand === "American Express") ? AmexImg : (JSON.parse(data.stripeCard)?.brand === "Discover") ? DiscoverImg : (JSON.parse(data.stripeCard)?.brand === "Diners Club") ? DinersImg : (JSON.parse(data.stripeCard)?.brand === "JCB") ? JcbImg : (JSON.parse(data.stripeCard)?.brand === "UnionPay") ? UnionImg : null} alt="visa card" className="h-12 w-12" />
                                </div>
                            </div>
                            <div className={`sm:ml-4 p-1`}>
                                <h3 className="flex items-center font-semibold">
                                    <div>{JSON.parse(data.stripeCard)?.brand}</div>
                                    <div className='pl-1 pr-1 mb-3 text-2xl align-middle h-full'> .... </div>
                                    <div>{JSON.parse(data.stripeCard)?.last4}</div>
                                </h3>


                                <h4 className="text-gray-700 block">Expires {JSON.parse(data.stripeCard)?.exp_month} {JSON.parse(data.stripeCard)?.exp_year}</h4>
                            </div>
                        </div>
                        {deletable &&
                            <div className='flex sm:px-3 justify-center items-center  rounded-lg shadow-2xl bg-red-600'>
                                <Icon classes="shadow-2xl sm:text-2xl" color="text-white" onClick={() => handleDelete(data)} icon={FaTrash} />
                            </div>}
                    </div>
                    {/* {expand === data._id &&
                        <div className="mt-8 w-1/2">
                            <div className="flex justify-between mb-3">
                                <p className="text-gray-00">Name</p>
                                <p className="text-gray-600">{JSON.parse(data.stripeCard)?.name}</p>
                            </div>
                            <div className="flex justify-between mb-3">
                                <p className="text-gray-00">Number</p>
                                <p className="text-gray-600">{JSON.parse(data.stripeCard)?.last$}</p>
                            </div>
                            <div className="flex justify-between mb-3">
                                <p className="text-gray-00">Fingerprint</p>
                                <p className="text-gray-600">{JSON.parse(data.stripeCard)?.fingerprint}</p>
                            </div>
                            <div className="flex justify-between mb-3">
                                <p className="text-gray-00">Expires</p>
                                <p className="text-gray-600">{JSON.parse(data.stripeCard)?.exp_month} {JSON.parse(data.stripeCard)?.exp_year}</p>
                            </div>
                            <div className="flex justify-between mb-3">
                                <p className="text-gray-00">Type</p>
                                <p className="text-gray-600">{JSON.parse(data.stripeCard)?.brand}</p>
                            </div>
                            <div className="flex justify-between mb-3">
                                <p className="text-gray-00">ID</p>
                                <p className="text-gray-600">{JSON.parse(data.stripeCard)?.id}</p>
                            </div>
                            <div className="flex justify-between mb-3">
                                <p className="text-gray-00">Phone</p>
                                <p className="text-gray-600">No name provided</p>
                            </div>
                            <div className="flex justify-between mb-3">
                                <p className="text-gray-00">Origin</p>
                                <p className="text-gray-600">{JSON.parse(data.stripeCard)?.country}</p>
                            </div>
                            <div className="flex justify-between mb-3">
                                <p className="text-gray-00">CVC check</p>
                                <p className="text-gray-600">{JSON.parse(data.stripeCard)?.cvc_check}</p>
                            </div>
                        </div>

                    } */}


                </div>
            )}
        </>
    )
}
export default ShowCard;