import React, {useState} from 'react';
import { Link, useHistory } from 'react-router-dom';

import { FiChevronDown, FiLogIn } from 'react-icons/fi';
import { BiLogOut } from 'react-icons/bi';
//
import OutsideClickHandler from 'react-outside-click-handler';
import Button from '../../../../components/utilities/buttons/Button';
import Dropdown from './Dropdown'
import { getCookie } from '../../../../components/utilities/cookie/cookie';
import { useJwt } from 'react-jwt';
import {useDispatch} from 'react-redux';
import { customerLogOut } from '../../../../redux/actions/customer';
const dropdown = [
    {
        title : "Partner",
        url : "https://partner.foodapps.uk",
    },
    {
        title : "Agent",
        url : "https://admin.foodapps.uk",
    },
    {
        title : "Rider",
        url : "https://rider.foodapps.uk",
    },

]

export default function IncogMobileNavbar() {
    const history = useHistory();
    const dispatch=useDispatch();
    const {isExpired}=useJwt(getCookie('token'))
    const name = localStorage.getItem('customer') ? JSON.parse(localStorage.getItem('customer'))?.name?JSON.parse(localStorage.getItem('customer'))?.name.split(" ")[0] : "Profile" : "Profile"
    const [openDropdown, setOpenDropdown] = useState(false);
    const logout = async() => {
        await customerLogOut(dispatch,history)        
        
    }
    const handleClick = (e) => {
        // setBecome(e.target.value)
        setOpenDropdown(!openDropdown)
    }
    
    return (
        <div className="bg-white px-5 py-3 mt-1 border-t">
            <div className="flex items-center justify-between py-2 border-b">
                <div onClick={() => setOpenDropdown(!openDropdown)} className={`ml-1 rounded trans cursor-pointer flex`}>
                    <p>Grow with us</p>
                    <FiChevronDown className="inline-block text-lg font-bold mt-1" />
                </div>
                <OutsideClickHandler onOutsideClick={() => {
                            setOpenDropdown(false)
                        }}>
                    <Dropdown onClick={handleClick} openDropdown={openDropdown} items={dropdown} />
                </OutsideClickHandler>
                <Link to="/sign-in" className={(!isExpired) ? "hidden" : "block"} >
                    <FiLogIn className="inline text-prim" /> <span className='font-semibold '>Login</span>
                </Link>
                <button className={(!isExpired) ? "block" : "hidden"} onClick={logout}><BiLogOut className="inline text-prim" /> Logout</button>

                {name &&(!isExpired)&& <Link to="/user">
                    <Button style={{paddingTop: ".5rem", paddingBottom: ".5rem"}}>{name}</Button>
                </Link>}
                {/* <div className="ml-2">
                   <Icon onClick={handleCheckout} icon={FiShoppingCart} />
                </div> */}
            </div>
        </div>
    )
}