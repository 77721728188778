import React from "react";

import AppCard from "../../../components/utilities/cards/AppCard";

export default function AppSection() {
  return (
    <>
      <div className="w-full md:w-1/2 flex items-center justify-center p-2 md:p-20">
        <div className="relative">
          <div className="w-64">
            <img
              alt="food delivery apps"
              className="w-full h-full object-cover"
              src="https://foodapps.uk/images/assets/apps.png"
            />
          </div>
        </div>
      </div>
      <div className="hidden md:flex w-1/2 items-center justify-center p-20">
        <div className="text-center">
          <h1 className="block md:hidden text-xl font-bold pb-2">
            Download Our Apps
          </h1>
          <h1 className="hidden md:block text-2xl font-bold py-2">
            Save Your Flavour For Future
          </h1>
          <h1 className="hidden md:block py-2 text-lg">
            Download to save your Favourite Dine for Booking & Discount
          </h1>
          <div className="block md:flex items-center justify-center py-2">
            <AppCard
              url="https://apps.apple.com/us/app/foodapps-uk/id1545836700"
              img="https://foodapps.uk/images/icons/apple.png"
              imgAlt="foodapps playstore"
              text="App Store"
              containerClasses="px-3 py-2"
            />
            <AppCard
              url="https://play.google.com/store/apps/details?id=com.foodAppsUK.app"
              img="https://foodapps.uk/images/icons/playstore.png"
              imgAlt="foodapps playstore"
              text="Google Play"
              containerClasses="px-3 py-2"
            />
          </div>
          <div className="block md:flex items-center justify-center py-2">
            <img src="qr-code.png" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}
