/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import { useForm } from "react-hook-form";
import { FiX } from "react-icons/fi";
import Modal from "react-modal";

import { customStyles } from "../../layout/PopupLayout/";
import Heading from "../../utilities/Heading";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCookie } from "../../utilities/cookie/cookie";
import { postCustomerBookingOrders } from "../../../redux/actions/order";

function BookingPopup({ openModal, setOpenModal, restaurant }) {
  const history = useHistory();

  const { register } = useForm();
  

  const { _id: restaurantId } = restaurant;
  

  const { order: { success } } = useSelector((state) => state);
  const dispatch = useDispatch();
  const token = getCookie("token");
  
  // useEffect(() => {
  //   getLoggedCustomerInfo(dispatch, token);
  // }, [dispatch, token]);

  const [formData, setFormData] = useState({
    restaurant: restaurantId,
    customerName: "",
    mobileNo: "",
    bookingDate: "",
    guestNo: "",
    bookingTime: "",
    bookingFor: "Breakfast",
  });
  useEffect(() => {
    setFormData({
      ...formData,
      restaurant: restaurantId,
    });
  },[])
  

  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
    
  };
  const handleSubmit = async (e) => {
    e.preventDefault()
      postCustomerBookingOrders(dispatch, token, formData, history);
    if (success) {
      history.push("/booking");
    }

  };

  return (
    <Modal
      isOpen={openModal}
      style={customStyles}
      className="w-full outline-none absolute border border-none z-100"
      ariaHideApp={false}
    >
      <div className="w-full h-screen">
        <div className="w-full h-full flex items-center justify-center">
          <form
            onSubmit={handleSubmit}
            className="w-full md:w-1/3 bg-white rounded-lg mx-2 p-3 md:p-6 mt-10 md:mt-4"
          >
            <div className="flex justify-end">
              <FiX
                onClick={() => setOpenModal(false)}
                className="cursor-pointer text-gray-600 hover:text-black trans text-lg"
              />
            </div>
            <Heading classes="text-center">Booking</Heading>
            <div className="md:mt-3">
              <p>Name</p>
              <input
                // {...register("name", { required: true })}
                name="customerName"
                placeholder="Ex. Jhon"
                type="text"
                className="w-full mt-2 rounded border border-red-700 outline-none px-3 py-2"
                onChange={handleChange}
              />
            </div>
            <div className="mt-3">
              <p>Contact No</p>
              <input
                // {...register("contact", { required: true })}
                name="mobileNo"
                placeholder="Ex. 123456456"
                type="number"
                className="w-full mt-2 rounded border border-red-700 outline-none px-3 py-2"
                onChange={handleChange}
                min="1"
                minLength="11"
                required
              />
            </div>
            {/* <BookingPopupBookingFor bookingFor={bookingFor} setBookingFor={setBookingFor} /> */}
            <div className="mt-3">
              <p>Booking For</p>
              <select 
                onChange={handleChange}
                className="w-full mt-2 rounded border border-red-700 outline-none px-3 py-2"
                name="bookingFor"
              >
                <option value="Breakfast">Breakfast</option>
                <option value="Lunch">Lunch</option>
                <option value="Dinner">Dinner</option>
                
              </select>
              {/* <input
                name="bookingFor"
                placeholder="Lunch"
                type="text"
                className="w-full mt-2 rounded border border-red-700 outline-none px-3 py-2"
                onChange={handleChange}
              /> */}
            </div>
            <div className="mt-3">
              <p>Number of Guests</p>
              <input
                {...register("guestNo", { required: true })}
                name="guestNo"
                type="number"
                className="w-full mt-2 rounded border border-red-700 outline-none px-3 py-2"
                onChange={handleChange}
                min="1"
                required
              />
            </div>
            <div className="mt-3">
              <p>Reservation Date</p>
              <input
                type="date"
                name="bookingDate"
                id=""
                className="w-full mt-2 rounded border border-red-700 outline-none px-3 py-2"
                onChange={handleChange}
              />
            </div>
            <div className="mt-3">
              <p>Booking Time</p>
              <input
                name="bookingTime"
                placeholder="12:00 PM"
                type="time"
                className="w-full mt-2 rounded border border-red-700 outline-none px-3 py-2"
                onChange={handleChange}
              />
            </div>
            <div className="text-center">
              <input
                type="submit"
                value="Confirm"
                className="bg-prim px-5 py-2 rounded text-white my-3 cursor-pointer"
              />
            </div>
            {/* <div className="px-1">
              <div className="mt-4 flex items-center justify-center">
                <input style={{ borderRadius: ".25rem" }} classes="w-1/3" type="submit" value="Confirm">
                  Confirm
                </input>
              </div>
            </div> */}
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(BookingPopup)