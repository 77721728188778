import React from "react";
import {
    FiBell,
    FiFileText,
    FiHeart,
    FiLogOut,
    FiShoppingCart,
    FiUser,
} from "react-icons/fi";
import { GiTable } from "react-icons/gi";
import { BiWallet } from "react-icons/bi";
import { useLocation, useHistory } from "react-router";
import { deleteCookie } from '../../utilities/cookie/cookie';

import SidebarButton from "../../utilities/buttons/SidebarButton";
export const userTabs = [
    {
        id: 1,
        text: "My Profile",
        route: "/user",
        Icon: FiUser,
    },
    {
        id: 2,
        text: "Favourite",
        route: "/favourite",
        Icon: FiHeart,
    },
    {
        id: 3,
        text: "My Bookings",
        route: "/booking",
        Icon: GiTable,
    },
    {
        id: 4,
        text: "My Orders",
        route: "/my-order",
        Icon: FiShoppingCart,
    },
    {
        id: 5,
        text: "My Wallet",
        route: "/my-wallet",
        Icon: BiWallet,
    },
    {
        id: 6,
        text: "Notifications",
        route: "/notifications",
        Icon: FiBell,
    },
    {
        id: 7,
        text: "Terms of Use",
        route: "/terms-of-use",
        Icon: FiFileText,
    }
];
export default function UserSidebar() {
    const history = useHistory();
    const url = useLocation();
    const selectedTab = userTabs.find((tab) => tab.route === url.pathname);
    const logout = () => {
        deleteCookie("customer");
        window.localStorage.removeItem("customer");
        window.localStorage.removeItem("token");
        history.push('/');
    }
    return (
        <>
            <div className="w-16 sm:w-2/12 md:w-2/12 h-full px-1 sm:px-6 md:px-6 py-4 ml-2 sm:ml-12 md:ml-12"></div>
            <div className="fixed sm:w-2/12 md:w-2/12 h-full bg-white px-1 sm:px-6 md:px-6 py-4 ml-2 sm:ml-0 md:ml-0 rounded-lg shadow-md">
                {userTabs.map((tab) => (
                    <SidebarButton
                        key={tab.id}
                        id={tab.id}
                        route={tab.route}
                        icon={tab.Icon}
                        text={tab.text}
                        active={selectedTab === tab ? true : false}
                    />
                ))}
                <SidebarButton containerClasses="block sm:hidden" onClick={logout} icon={FiLogOut} text="Log out" />
            </div>
        </>
    );
}
