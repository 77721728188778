import React from "react";
import { useHistory } from "react-router";
import { FiArrowLeft } from "react-icons/fi";

const Packages = () => {
  const history = useHistory();
  return (
    <div>
      <img
        className="h-16 cursor-pointer mx-auto"
        src="https://foodapps.uk/images/logos/logo.png"
        alt="foodapps.uk"
      />
      <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
        {/* <!--Card 1--> */}
        <div className="rounded overflow-hidden shadow-lg">
          <img
            className="w-full"
            src="img/values-1.png"
            alt="Show your Business Online"
          />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">
              Show your Business Online
            </div>
            <p className="text-gray-700 text-base">
              <ul className="list-disc">
                <li className="ml-5">Digital Menu with QR code </li>
                <li className="ml-5">
                  Branding and Menu design for new Business
                </li>
                <li className="ml-5">Rebranding and Menu Design</li>
                <li className="ml-5">
                  Web Design to present online ordering platform{" "}
                </li>
              </ul>
            </p>
          </div>
          <div className="px-6 pt-4 pb-2">
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
              #qrcode
            </span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
              #branding
            </span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
              #webdesign
            </span>
          </div>
        </div>
        {/* <!--Card 2--> */}
        <div className="rounded overflow-hidden shadow-lg">
          <img
            className="w-full"
            src="img/values-2.png"
            alt="Attract Your Customers"
          />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">Attract Your Customers</div>
            <p className="text-gray-700 text-base">
              <ul className="list-disc">
                <li className="ml-5">
                  Digital Menu with QR code to connect with exiting customer{" "}
                </li>
                <li className="ml-5">Create Social Networks </li>
                <li className="ml-5">
                  Total Digital Marketing to engage new customers
                </li>
                <li className="ml-5">
                  Facebook Marketing with customer support{" "}
                </li>
              </ul>
            </p>
          </div>
          <div className="px-6 pt-4 pb-2">
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
              #digitalmenu
            </span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
              #digitalmarketing
            </span>
          </div>
        </div>

        {/* <!--Card 3--> */}
        <div className="rounded overflow-hidden shadow-lg">
          <img
            className="w-full"
            src="img/values-3.png"
            alt="Grow Your Business Digitally"
          />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">
              Grow Your Business Digitally
            </div>
            <p className="text-gray-700 text-base">
              <ul className="list-disc">
                <li className="ml-5"> QR Table Top</li>
                <li className="ml-5"> Unique Waiter App </li>
                <li className="ml-5"> 7 Inch Tablet with Bluetooth Printer </li>
                <li className="ml-5"> Partner App to Manage ordering system</li>
                <li className="ml-5">
                  {" "}
                  Digital Loyalty Code to involve more customers
                </li>
                <li className="ml-5">
                  {" "}
                  Integrate web & App to connect online Booking & orders
                </li>
              </ul>
            </p>
          </div>
          <div className="px-6 pt-4 pb-2">
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
              #qrtabletop
            </span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
              #waiterapp
            </span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
              #orderingsystem
            </span>
          </div>
        </div>
      </div>
      <div className="text-center">
        <button
          className="bg-prim px-5 py-2 rounded text-white cursor-pointer"
          onClick={() => history.push("")}
        >
          <FiArrowLeft className="inline-block mr-1" />
          Back
        </button>
      </div>
    </div>
  );
};

export default Packages;
