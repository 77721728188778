/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import "react-credit-cards/es/styles-compiled.css";

import UserLayout from "./../../../components/layout/UserLayout/index";
import Heading from "./../../../components/utilities/Heading";
import Button from "./../../../components/utilities/buttons/Button";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

import { getNewCard } from "../../../redux/actions/wallet";
import { getCookie } from '../../../components/utilities/cookie/cookie';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AddUserCard = () => {
  const dispatch = useDispatch();
  const [focus, setFocus] = useState("");
  const [expTime, setExpTime] = useState("");
  const token = getCookie('token');
  const history = useHistory()
  const [formData, setFormData] = useState({
    email: "",
    mobileNo: "",
    cardNumber: "",
    name: "",
    exp_month: "11",
    exp_year: "2023",
    cvc: "",
    walletId: ""
  });
  const { error } = useSelector(state => state.wallet);
  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const customerInfo = JSON.parse(localStorage.getItem("customer"));
  useEffect(() => {
    setFormData({ ...formData, email: customerInfo.email, walletId: customerInfo.wallet, mobileNo: customerInfo.mobileNo })
  }, [])

  formData.exp_month = Number(expTime.split('/')[0])
  formData.exp_year = Number("20" + expTime.split('/')[1])


  const handleSubmit = (e) => {
    e.preventDefault();
    getNewCard(dispatch, customerInfo._id, formData, token, history)
    
  };
  useEffect(() => {
    toast.error(error);
  }, [error])
  return (
    <HelmetProvider>
      <Helmet>
        <title>FoodApps | Partner Bookings</title>
      </Helmet>
      <UserLayout>
      <ToastContainer />
        <Heading>Add Card Details</Heading>
        <Cards
          number={formData.cardNumber}
          name={formData.name}
          expiry={expTime}
          cvc={formData.cvc}
          focused={focus}
        />


        {/* {error &&
          <ErrorMessage classes="my-2" message={error} />
        } */}
        <form onSubmit={handleSubmit}>
          <div className="sm:flex md:flex flex-wrap items-center mt-0">
            <div className="p-2 w-full sm:w-2/3 md:w-full">
              <div className="text-sec rounded">
                <p className="mt-3 mx-1 text-gray-700">Card Number</p>
                <input
                  className="border border-prim rounded p-2 m-1 w-full"
                  type="tel"
                  name="cardNumber"
                  placeholder="Card Number"
                  onChange={handleChange}
                  onFocus={(e) => setFocus(e.target.name)}
                />
              </div>
            </div>
            <div className="p-2 w-full sm:w-2/3 md:w-full">
              <div className="text-sec rounded">
                <p className="mt-3 mx-1 text-gray-700">Cardholder Name</p>
                <input
                  className="border border-prim rounded p-2 m-1 w-full"
                  type="text"
                  name="name"
                  placeholder="Name"
                  onChange={handleChange}
                  onFocus={(e) => setFocus(e.target.name)}
                />
              </div>
            </div>
            <div className="p-2 md:w-2/4 sm:w-full">
              <div className="text-sec rounded">
                <p className="mt-3 mx-1 text-gray-700">MM/YY</p>
                <input
                  className="border border-prim rounded p-2 m-1 w-full"
                  type="text"
                  name="exp_year"
                  placeholder="MM/YY Expiry"
                  onChange={(e) => setExpTime(e.target.value)}
                  onFocus={(e) => setFocus(e.target.name)}
                />
              </div>
            </div>
            <div className="p-2 md:w-2/4 sm:w-full">
              <div className="text-sec rounded">
                <p className="mt-3 mx-1 text-gray-700">CVC</p>
                <input
                  className="border border-prim rounded p-2 m-1 w-full"
                  type="tel"
                  name="cvc"
                  placeholder="CVC"
                  onChange={handleChange}
                  onFocus={(e) => setFocus(e.target.name)}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <Button>Submit Card Details</Button>
          </div>
        </form>
      </UserLayout>
    </HelmetProvider>
  );
};

export default AddUserCard;
