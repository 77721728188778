import {
    GET_ORDER_SUCCESS,
    GET_ORDER_FAILURE,
    GET_ALL_ORDERS_SUCCESS,
    GET_MESSAGE_SUCCESS,
    GET_ORDER_LOADING,
} from "../types/order/type";
import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";
import { payByCard } from "./wallet";

export const getCustomerOrders = async (dispatch, customerId, token) => {
    try {
        const {
            data: { data },
        } = await axios.get(
            `${apiBaseUrl}${apiVersionPrefix}/order/get-all-customer-orders/${customerId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (data) {
            dispatch({ type: GET_ALL_ORDERS_SUCCESS, payload: data });
        }
    } catch (err) {
        const {
            response: {
                data: { error },
            },
        } = err;
        dispatch({ type: GET_ORDER_FAILURE, payload: error });
    }
};

export const postCustomerBookingOrders = async (
    dispatch, token, formData, history
) => {
    try {
        const {
            data: { data },
        } = await axios.post(
            `${apiBaseUrl}${apiVersionPrefix}/order/create-booking-order`, formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (data) {
            dispatch({ type: GET_ORDER_SUCCESS, payload: data });
            history.push("/booking");
        }
    } catch (err) {
        const {
            response: {
                data: { error },
            },
        } = err;
        dispatch({ type: GET_ORDER_FAILURE, payload: error });
    }
};

export const getCustomerBookingOrders = async (dispatch, customerId, token) => {
    try {
        const {
            data: { data },
        } = await axios(
            `${apiBaseUrl}${apiVersionPrefix}/order/get-all-customer-booking-orders/${customerId}`,
            {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (data) {
            dispatch({ type: GET_ALL_ORDERS_SUCCESS, payload: data });
        }
    } catch (err) {
        const {
            response: {
                data: { error },
            },
        } = err;
        dispatch({ type: GET_ORDER_FAILURE, payload: error });
    }
};

export const cancelOrder = async (dispatch, orderId, token) => {
    try {
        const {
            data: { data },
        } = await axios(
            `${apiBaseUrl}${apiVersionPrefix}/order/customer-cancel-order/${orderId}`,
            {
                method: "PUT",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (data) {
            dispatch({ type: GET_MESSAGE_SUCCESS, payload: data });
        }
    } catch (err) {
        const {
            response: {
                data: { error },
            },
        } = err;
        dispatch({ type: GET_ORDER_FAILURE, payload: error });
    }
};

export const removeBookingOrder = async (dispatch, orderId, token) => {
    try {
        const {
            data: { data },
        } = await axios(
            `${apiBaseUrl}${apiVersionPrefix}/order/customer-remove-booking-order/${orderId}`,
            {
                method: "PUT",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (data) {
            dispatch({ type: GET_ALL_ORDERS_SUCCESS, payload: data });
        }
    } catch (err) {
        const {
            response: {
                data: { error },
            },
        } = err;
        
        dispatch({ type: GET_ORDER_FAILURE, payload: error });
    }
};

export const getSingleOrder = async (dispatch, orderId, token) => {
    try {
        const { data: { data } } = await axios(`${apiBaseUrl}${apiVersionPrefix}/order/get-single-order/${orderId}`, {
            method: "GET",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                "Authorization": `Bearer ${token}`,
            },
        })
        if (data) {
            dispatch({ type: GET_ORDER_SUCCESS, payload: data })
        }
    } catch (err) {

        const { response: { data: { error } } } = err;
        
        dispatch({ type: GET_ORDER_FAILURE, payload: error })
    }
}


export const placeOrder = async (dispatch, token, localCard, option, history) => {
    try {
        const orderData = JSON.parse(localStorage.getItem('order'));
        
        const { data: { data } } = await axios.post(`${apiBaseUrl}${apiVersionPrefix}/order/create-customer-order`, orderData, {
            
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                "Authorization": `Bearer ${token}`,
            },
        })
        if (data) {
            dispatch({ type: GET_ORDER_SUCCESS, payload: data })

            if (option === "Card") {
                
                localCard.orderId=data._id
                payByCard(dispatch, token, localCard);

            }
            history.push("/my-order");
            localStorage.removeItem('cart');
            localStorage.removeItem('order');
        }
    } catch (err) {
        console.log(err, "err")
        // const { response: { data: { error } } } = err;
        // dispatch({ type: GET_ORDER_FAILURE, payload: error })
    }
}



export const placeDineOrder = async (dispatch, token, option, localCard, history, setOpenModal, setClickable) => {
    try {
        const orderData = JSON.parse(localStorage.getItem('order'));

        const { data: { data } } = await axios.post(`${apiBaseUrl}${apiVersionPrefix}/order/create-customer-dine-in`, orderData, {
            
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                "Authorization": `Bearer ${token}`,
            },
        })
        setClickable(false);
        if (data) {
            dispatch({ type: GET_ORDER_SUCCESS, payload: data })
            setOpenModal(true);
            if (option === "Card") {
                localCard.orderId=data._id
                payByCard(dispatch, token, localCard);
            }
            // history.push(`/order-summary/${data._id}`);
            localStorage.removeItem('cart');
            localStorage.removeItem('order');
            localStorage.removeItem('currentPage');
            localStorage.removeItem('restaurant');
        } else {
            dispatch({ type: GET_ORDER_LOADING})
        }
    } catch (err) {
        console.log(err, "err")
        // const { response: { data: { error } } } = err;
        // dispatch({ type: GET_ORDER_FAILURE, payload: error })
    }
}


