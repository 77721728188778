import React, { useState } from 'react'
import firebase from "../../config/firebase";
import { Redirect } from "react-router-dom";


import { ToastContainer, toast } from 'react-toastify';
import { FiMail } from "react-icons/fi";
import { useDispatch } from "react-redux";

import { getCustomerByPhone } from "../../redux/actions/customer";

import ukFlag from "../../images/uk-flag.png"
import Image from "../../components/utilities/images/Image";
import LoginFormHeader from "./LoginFormHeader";
import LoginFormFooter from "./LoginFormFooter";

import 'react-toastify/dist/ReactToastify.css';

function ForgetPassParent() {
    const [formData, setFormData] = useState({
        mobileNo: "",
        otp: ""
    })
    const [isRedirect, setIsRedirect] = useState(false);
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const [isShowOtp, setIsShowOtp] = useState(false);
    const dispatch = useDispatch();

    const configureCaptcha = () => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
                onSignInSubmit();
            },
            defaultCountry: "IN"
        }); 
    }
    const onSignInSubmit = (e) => {
        e.preventDefault()
        configureCaptcha()
        const phoneNumber = "+44"+formData.mobileNo;
        const appVerifier = window.recaptchaVerifier;
        
        firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult
                getCustomerByPhone(dispatch, formData);
                toast.success("Otp has been sent");
                setIsShowOtp(true);
            }).catch((error) => {
                toast.error("otp is not sent"+error)
            });
    }
    const onSubmitOTP = (e) => {
        e.preventDefault()
        const code = formData.otp;
        window.confirmationResult.confirm(code).then((result) => {
            toast.success("Success! Your code is correct!");
            setIsRedirect(true);

        }).catch((error) => {
            toast.error("Token is not correct! please try again")
        });
    }

    return (
        <div className="w-full h-screen bg-nat">
            <ToastContainer style={{ fontSize: "1.5rem" }} />
            <div className="relative w-full h-full flex items-center justify-center">
                <div className="absolute top-0 right-0 w-4/12 mt-10 hidden sm:block md:block">
                    <Image
                        src="https://foodapps.uk/images/backgrounds/food4.png"
                        alt=""
                    />
                </div>
                <div className="absolute bottom-0 left-0 w-4/12 mt-10 hidden sm:block md:block">
                    <Image
                        src="https://foodapps.uk/images/backgrounds/food1.png"
                        alt=""
                    />
                </div>

                <div className="rounded-lg w-full mx-4 sm:mx-0 md:mx-0 p-4 bg-white shadow-md sm:1/3 md:w-1/3">
                    <LoginFormHeader />
                    {
                        !isShowOtp ? (
                            <form className="py-2" onSubmit={onSignInSubmit}>
                                <div id="sign-in-button"></div>
                                <div className="py-2">
                                    <div className="bg-white shadow flex items-center p-3 rounded-lg">
                                        <img src={ukFlag} alt="" className="w-6 h-6 mr-2" />
                                        <input name="mobileNo" onChange={handleChange} value={formData.mobileNo} type="text" className="outline-none px-3 w-full" placeholder="Mobile Number" />
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="w-full font-semibold rounded-lg py-3 px-6 bg-red-700 text-white outline-none"
                                >
                                    Sent Token
                                </button>
                            </form>
                        ) : (
                            <form className="py-2" onSubmit={onSubmitOTP}>
                                <div className="py-2">
                                    <div className="bg-white shadow flex items-center p-3 rounded-lg">
                                        <FiMail className="text-lg" />
                                        <input name="otp" onChange={handleChange} value={formData.otp} type="text" className="outline-none px-3 w-full" placeholder="Otp Token" />
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="w-full font-semibold rounded-lg py-3 px-6 bg-red-700 text-white outline-none"
                                >
                                    Submit Token
                                </button>
                            </form>
                        )
                    }
                    <LoginFormFooter />
                </div>

                <div className="absolute bottom-0 right-0 w-4/12 mt-10 hidden sm:block md:block">
                    <Image
                        src="https://foodapps.uk/images/backgrounds/food5.png"
                        alt=""
                    />
                </div>
                <div className="absolute top-0 left-0 mt-10 w-4/12 hidden sm:block md:block">
                    <Image
                        src="https://foodapps.uk/images/backgrounds/food2.png"
                        alt=""
                    />
                </div>
            </div>
            {isRedirect ? <Redirect to="/reset-password" /> : null}
        </div>
    )

}
export default ForgetPassParent;