import React from "react";

import { useJwt } from 'react-jwt'
import { Link, useHistory } from "react-router-dom";
import { BiBlock } from "react-icons/bi";

import Button from "../../../components/utilities/buttons/Button";
import table from "../../../images/table.png";

import { getCookie } from "../../../components/utilities/cookie/cookie";

export default function RestuantButton({ setOpenModal, restaurant }) {

  const { isExpired } = useJwt(getCookie('token'));
  const history = useHistory();
  const  restaurantTiming = restaurant?.restaurantTiming?.restaurantTiming
  const date = new Date();
  const closeOrOpen = restaurantTiming?.available?.find((it) => it.day === date.getDay());
  const start=new Date(Date.now()).setHours(closeOrOpen?.startTime.split(':')[0],closeOrOpen?.startTime.split(':')[1]);
      const end=new Date(Date.now()).setHours(closeOrOpen?.endTime.split(':')[0],closeOrOpen?.endTime.split(':')[1]);
      const currentTime=new Date(Date.now());
      let available=false;
      (closeOrOpen?.isOpen)?(start<currentTime && end>currentTime)?available=true:available=false:available=false;
  const handleBooking = () => {
        if(!isExpired) {
            setOpenModal(true);
        } else {
            history.push("/sign-in");
        }
    }
    return (
        <>
            <div className="hidden w-full md:w-1/5 sm:flex md:flex items-end">
                <div className="w-full flex items-center justify-end px-8">
                    <div className="mt-2 md:mt-0 flex w-full items-center justify-center md:inline">
                        <Link to={`/order/${restaurant.uniId}`}>
                            <Button
                                bgColor="bg-gray-700"
                                
                                classes={`mx-1 w-full text-sm `}
                            >
                                {!available && <BiBlock className="inline text-lg"></BiBlock>}
                                <span className="font-bold text-center mx-2">Order Online</span>
                            </Button>
                        </Link>
                        <div
                            onClick={handleBooking}
                            className="w-1/2 md:w-full mx-1 md:mt-1 cursor-pointer rounded-lg font-semibold flex items-center justify-center px-4 bg-red-700 text-white border shadow-lg py-1"
                        >
                            <img
                                alt="book your table"
                                className="object-cover  w-12 h-8 image-invert"
                                src={table}
                            />
                            <p className="text-sm text-center pl-2">Booking </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
