import React,{useEffect} from 'react';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import {useSelector, useDispatch} from 'react-redux';

import {getLoggedCustomerInfo} from "../../../redux/actions/customer";
import { getCookie } from '../../../components/utilities/cookie/cookie';

import UserLayout from '../../../components/layout/UserLayout';
import UserProfileAddress from './UserProfileAddress';
import UserProfileInfo from './UserProfileInfo';
import UserProfileContact from './UserProfleContact';

export default function UserProfile() {
    const {customer} = useSelector(state => state.customer);
    const dispatch = useDispatch();
    const token = getCookie('token');
    
    useEffect(()=>{
        getLoggedCustomerInfo(dispatch,token)
    },[dispatch, token]);

    return (
        <HelmetProvider>
        <Helmet>
            <title>FoodApps | My Profile</title>
        </Helmet>
        <UserLayout>
            <UserProfileInfo customer={customer} />
            <UserProfileContact customer={customer} />
            <UserProfileAddress customer={customer} />
        </UserLayout>
        </HelmetProvider>
    )
}