import React from 'react'

export default function Dropdown(props) {
    const user = JSON.parse(localStorage.getItem('customer'))

    return (
        <div>
            {props.openDropdown &&
            <div 
            style={{ ...props.containerStyle }} 
            onMouseEnter ={() => props.setOpenDropdown(true)}
            onMouseLeave ={() => props.setOpenDropdown(false)}
            className={`z-50 w-36 outline-none fixed ${user? "right-60": "right-40"} mt-4  ${props.containerClasses}`}>
                <div style={{ ...props.mainStyle }} className={`bg-gray-200 text-prim rounded-lg border shadow-lg ${props.mainClasses}`}>
                {props.items.map((tm, i) => 
                    <a
                        key={i}
                        onClick={props.onClick}
                        style={props.style} className={`py-2 px-5 w-full hover:text-white hover:bg-prim rounded trans cursor-pointer outline-none block text-sm font-bold text-center ${props.classes}`}
                        href={tm.url}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {tm.title}
                    </a>
                )}       
                </div>
            </div>}
       </div>
    )
}