import React from 'react'

import RoundedImg from '../images/RoundedImg'

export default function CategoryCard(props) {
    return (
        <div onClick={props.onClick} style={props.containerStyle} className="cursor-pointer text-center bg-white rounded-lg overflow-hidden focus:border-8 focus:border-prim ">
            <div style={props.imgContainerStyle} className={`ml-0 ${props.imgContainerClasses}`}>
                <RoundedImg src={props.img} alt={props.title} />
            </div>
            <div style={props.btnContainerStyle} className={`bg-white ${props.btnContainerClasses}`}>
                <p style={props.btnStyle} className={`text-center font-bold text-black ${props.btnClasses}`}>{props.title}</p>
            </div>
        </div>
    )
}