import React from "react";
import { Redirect, Route } from "react-router";
import { useJwt } from 'react-jwt'
import { getCookie } from "../components/utilities/cookie/cookie";

const PrivateRoute = ({ component: Component, ...rest }) => { 
  
  const { isExpired } = useJwt(getCookie('token'));
  console.log(isExpired, "expiration")
  return (
    <Route
      {...rest}
      render={({ location }) =>
        (!isExpired) ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: {
                from: location,
              },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
