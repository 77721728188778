import { GET_CATEGORY_FAILURE, GET_ALL_CATEGORY_SUCCESS, GET_CATEGORY_SUCCESS } from "../types/category/type";
import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from 'axios';



export const getAllCategories = async (dispatch) => {
  try {
    const { data: { data } } = await axios.get(`${apiBaseUrl}${apiVersionPrefix}/category/get-all-category`);
    if (data) {
      dispatch({ type: GET_ALL_CATEGORY_SUCCESS, payload: data });
    }
  } catch (err) {
    //console.log(err);
    const { response: { data: { error } } } = err;
    dispatch({ type: GET_CATEGORY_FAILURE, payload: error });
  }
}


export const getRestaurantAllCategories = async (dispatch, id, token) => {
  try {
    const response = await axios.get(`${apiBaseUrl}${apiVersionPrefix}/category/get-restaurant-own-category/${id}`);

    if (response) {
      dispatch({ type: GET_ALL_CATEGORY_SUCCESS, payload: response.data.data });
    }
  } catch (err) {
    //console.log(err);
    const { response: { data: { error } } } = err;
    dispatch({ type: GET_CATEGORY_FAILURE, payload: error });
  }
}




export const getSingleCategory = async (dispatch, categoryId) => {
  try {
    const { data: { data } } = await axios.get(`${apiBaseUrl}${apiVersionPrefix}/category/get-single-category/${categoryId}`);
    if (data) {
      dispatch({ type: GET_CATEGORY_SUCCESS, payload: data })
    }
  } catch (err) {
    const { response: { data: { error } } } = err;
    dispatch({ type: GET_CATEGORY_FAILURE, payload: error });
  }
}


export const getRestaurantCategories = async (dispatch, restaurantId,) => {
  try {
    const response = await axios.get(`${apiBaseUrl}${apiVersionPrefix}/category/get-restaurant-categories/${restaurantId}`);
    if (response) {
      // console.log(response.data.data)
      dispatch({ type: GET_ALL_CATEGORY_SUCCESS, payload: response.data.data });
    }
  } catch (err) {
    //console.log(err);
    const { response: { data: { error } } } = err;
    dispatch({ type: GET_CATEGORY_FAILURE, payload: error });
  }
}
