import React from 'react'

const OrderSummuryItems = (props) => {
    const { value, itemName, quantity, isNegative } = props;
    return (
        <div className="flex justify-between mt-5">
            <div>
                <p className="font-medium text-sm">{quantity === 0 ? itemName : `${quantity} X ${itemName}`}</p>
            </div>
            <div>
                <p className="font-medium">{isNegative ? "-" : null} &#163; {Number.parseFloat(value).toFixed(2)}</p>
            </div>
        </div>
    )
}

export default OrderSummuryItems
