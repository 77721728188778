import {
  GET_FOOD_FAILURE,
  GET_ALL_FOOD_SUCCESS,
  DELETE_FOOD_SUCCESS,
  GET_FOOD_SUCCESS,
} from "../types/food/type";
import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";

export const getAllFoods = async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.get(`${apiBaseUrl}${apiVersionPrefix}/food/get-all-foods`);
    if (data) {
      dispatch({ type: GET_ALL_FOOD_SUCCESS, payload: data });
    }
  } catch (err) {
    //console.log(err);
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_FOOD_FAILURE, payload: error });
  }
};

export const getFoodsByCategory = async (dispatch, categoryId) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/food/get-foods-by-category/${categoryId}`,
    );
    // console.log(data);
    if (data) {
      dispatch({ type: GET_ALL_FOOD_SUCCESS, payload: data });
    }
  } catch (err) {
    //console.log(err);
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_FOOD_FAILURE, payload: error });
  }
};

export const addFood = async (dispatch, { token, formData }) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${apiBaseUrl}${apiVersionPrefix}/food/create-food`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_ALL_FOOD_SUCCESS, payload: data });
    }
  } catch (err) {
    //console.log(err)
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_FOOD_FAILURE, payload: error });
  }
};

export const deleteFood = async (dispatch, token, id) => {
  try {
    const {
      data: { message },
    } = await axios.delete(
      `${apiBaseUrl}${apiVersionPrefix}/food/delete-food/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (message) {
      dispatch({ type: DELETE_FOOD_SUCCESS, payload: message });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_FOOD_FAILURE, payload: error });
  }
};

export const getSingleFood = async (dispatch, id) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/food/get-single-food/${id}`,
    );
    if (data) {
      dispatch({ type: GET_FOOD_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_FOOD_FAILURE, payload: error });
  }
};

export const updateFood = async (dispatch, { token, formData }, id) => {
  try {
    const {
      data: { data },
    } = await axios.put(
      `${apiBaseUrl}${apiVersionPrefix}/food/update-food/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_ALL_FOOD_SUCCESS, payload: data });
    }
  } catch (err) {
    //console.log(err)
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_FOOD_FAILURE, payload: error });
  }
};
