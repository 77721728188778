import React, { useState } from "react";
import { FiChevronDown, FiUser } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler";
import Dropdown from "./Dropdown";

const dropdown = [
  {
    title: "Partner",
    url: "https://partner.foodapps.uk",
  },
  {
    title: "Agent",
    url: "https://admin.foodapps.uk",
  },
  {
    title: "Rider",
    url: "https://rider.foodapps.uk",
  },
];
export default function SwitchToCustomer(props) {
  const [openDropdown, setOpenDropdown] = useState(false);
  const handleClick = (e) => {
    // setBecome(e.target.value)
    setOpenDropdown(false);
  };

  
  const handleTabDropDown = ()=>{
    if(window.innerWidth >= 768){
      setOpenDropdown(true);
    }
  }
  return (
    <div className="px-4 pr-6">
      <div className="flex items-center">
        <div className="flex items-center">
          <img
            alt="foodapps partner"
            className="object-cover w-5 h-5"
            src="https://foodapps.uk/images/icons/partner.png"
          />
          <div className="pl-2 cursor-pointer text-md font-bold text-prim-hover trans highlight-remove">
            <div className="text-sm mr-1 font-normal flex">
              <div
                onMouseEnter={() => setOpenDropdown(true)}
                onMouseLeave={() => setOpenDropdown(false)}
                onClick={()=> handleTabDropDown()}
                className={`ml-1 items-center rounded trans cursor-pointer flex hover:text-prim hover:trans`}
              >
                <div className="menu-dropdown relative text-base hover:text-prim hover:trans">
                  Grow with us
                </div>
                <FiChevronDown className="inline-block text-lg font-bold" />
              </div>
            </div>
          </div>
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            setOpenDropdown(false);
          }}
        >
          <Dropdown
            setOpenDropdown={setOpenDropdown}
            onClick={handleClick}
            openDropdown={openDropdown}
            items={dropdown}
          />
        </OutsideClickHandler>
        {props.type === "customer" && (
          <>
            <FiUser className="text-red-600" />
            <div className="cursor-pointer text-md font-bold text-prim-hover trans">
              <span className="text-sm mx-1 font-normal">Switch to </span>{" "}
              Customer
            </div>
          </>
        )}
      </div>
    </div>
  );
}
