import React from 'react';
export default function FbLikeButton() {
    return (
        <iframe
            title="3"
            src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Ffoodappsuk&width=92&layout=button_count&action=like&size=small&share=false&height=21&appId"
            width="92"
            height="21"
            style={{border:"none",overflow:"hidden"}}
            scrolling="no"
            frameBorder="0"
            allowFullScreen={true}
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>
    )
}