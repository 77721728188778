import React, { useState, useEffect } from "react";

export default function CheckoutCustomerInfo({ order, setOrder, isEdit, setIsEdit }) {
  const [formData, setFormData] = useState({
    customerName: ""
  })

  useEffect(() => {
    const customer = JSON.parse(localStorage.getItem('customer'))
    let localOrder = JSON.parse(localStorage.getItem('order'));
    localOrder = { ...localOrder, customerName: customer.name ? customer.name : "" };
    localStorage.setItem('order', JSON.stringify(localOrder));
    setOrder(localOrder)
  }, [setOrder])

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, customerName: e.target.value });

  }

  const handleClick = (e) => {
    e.preventDefault();
    let localOrder = JSON.parse(localStorage.getItem('order'));
    localOrder = { ...localOrder, customerName: formData.customerName };
    localStorage.setItem('order', JSON.stringify(localOrder));
    setOrder(localOrder)
    setIsEdit(false)
  }

  return (
    <div className="sm:flex md:flex flex-wrap items-center mt-3">
      <div className="p-2 w-full sm:w-2/3 md:2/3">
      <h1 className="mx-2 font-semibold text-xl">{order.customerName ? order.customerName : "Enter A Name"}</h1>
        {!isEdit ? (
          null
        ) : (
          <div className="sm:flex">
              <input type="text" className="border-2 border-prim rounded py-2 px-2" value={formData.customerName} onChange={handleChange} placeholder="Add another name" />
              <button onClick={handleClick} className="mt-2 bg-prim px-5 py-2 rounded mx-2 text-white">Add</button>
          </div>

        )}

      </div>
    </div>
  );
}
