import axios from "axios";

import { apiBaseUrl, apiVersionPrefix } from "../../config";
import { GET_CARDS_FAILURE, GET_CARDS_SUCCESS, GET_CARD_FAILURE, GET_CARD_SUCCESS, REMOVE_CARD_FAILURE, REMOVE_CARD_SUCCESS } from "../types/wallet/type";

export const getCards = async (dispatch, customer, token, currentPage, history) => {

    try {
        const { data: { data } } = await axios.get(`${apiBaseUrl}${apiVersionPrefix}/customer/${customer}/wallet/get-all-cards`, {
            headers: {
                Authorization: `Bearer ` + token
            }
        });
        if (data) {
            dispatch({ type: GET_CARDS_SUCCESS, payload: data })
            if (currentPage === "checkout" && data.length === 0) {
                history.push("/add-user-card")
            }
        }
    } catch (err) {
        const { response: { data: { error } } } = err;
        dispatch({ type: GET_CARDS_FAILURE, payload: error });
        // console.log(err)
    }
}

export const getNewCard = async (dispatch, customer, formData, varToken, history) => {
    try {
        const { data: { data } } = await axios.post(`${apiBaseUrl}${apiVersionPrefix}/customer/${customer}/wallet/add-card`, formData, {
            headers: {
                Authorization: `Bearer ` + varToken
            }
        });
        if (data) {
            dispatch({ type: GET_CARD_SUCCESS, payload: data })
            history.goBack();
        }
    } catch (err) {
        const { response: { data: { error } } } = err;
        dispatch({ type: GET_CARD_FAILURE, payload: error });
    }
}


export const getDeleteCard = async (dispatch, customerId, cardId, varToken) => {
    try {
        const { data: { data } } = await axios.delete(`${apiBaseUrl}${apiVersionPrefix}/customer/${customerId}/wallet/delete-card/${cardId}`,
            {
                headers: {
                    Authorization: `Bearer ` + varToken
                }
            });

        if (data) {
            dispatch({ type: REMOVE_CARD_SUCCESS, payload: data })
        }
    } catch (err) {
        const { response: { data: { error } } } = err;
        dispatch({ type: REMOVE_CARD_FAILURE, payload: error })
    }

}


export const payByCard = async (dispatch, token, localCard) => {
    try {
        const { data: { data } } = await axios.post(`${apiBaseUrl}${apiVersionPrefix}/customer-payment/set-payment`, localCard
            , {
                headers: {
                    Authorization: `Bearer ` + token
                }
            });

        if (data) {
            dispatch({ type: GET_CARD_SUCCESS, payload: data })
        }
    } catch (err) {
        const { response: { data: { error } } } = err;
        dispatch({ type: GET_CARDS_FAILURE, payload: error })
    }
}
