
import { GET_CARDS_SUCCESS, GET_CARDS_FAILURE, GET_CARD_FAILURE, GET_CARD_SUCCESS, REMOVE_CARD_SUCCESS, REMOVE_CARD_FAILURE } from "../types/wallet/type";

const initialState = {
    cards: [],
    card: {},
    error: null,
    loading: true,
    message: ""
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CARDS_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "successfull",
                cards: action.payload,
                error: {}
            }
        case GET_CARDS_FAILURE:
            return {
                ...state,
                loading: false,
                status: "Failed",
                error: action.payload
            };
        case GET_CARD_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                card: action.payload
            }
        case GET_CARD_FAILURE:
            return {
                ...state,
                loading: false,
                status: "Failed",
                error: action.payload
            };
        case REMOVE_CARD_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "deleted",
                message: action.payload,
                error: "",
            };
        case REMOVE_CARD_FAILURE:
            return {
                ...state,
                loading: false,
                status: "Failed to delete",
                error: action.payload,
            };
        default:
            return state;
    }
}
export default reducer;