import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { FiUser } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";

import { getCards } from "../../redux/actions/wallet";
import { getCookie } from "../../components/utilities/cookie/cookie"

import CardInfo from "./CardInfo";
import VisaImg from '../../images/visa.png'
import MasterImg from "../../images/masterCard.png";
import AmexImg from "../../images/americanExpress.png";
import DiscoverImg from "../../images/discover.png";
import DinersImg from "../../images/dinersClub.png";
import JcbImg from "../../images/jcb.png";
import UnionImg from "../../images/unionPay.png";

export default function PaymentOptionCard(props) {
  const [card, setCard] = useState(false);
  const [cardNum, setCardNum] = useState(null)
  const customer = JSON.parse(localStorage.getItem("customer"))._id;
  const { cards } = useSelector(state => state.wallet);
  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const token = getCookie('token')
  const currentPage = "checkout";

  const handleSelectCard = async (option, i) => {
    await getCards(dispatch, customer, token, currentPage, history)
    setPaymentType(option)
    props.setOption(option)
    setCardNum(i)
    setCard(true)

  }

  const handleSelectCash = (option, i) => {
    setPaymentType(option)
    option === "Terminal" && setPaymentType("Card") ;
    props.setOption(option)
    setCardNum(i);
    setCard(false)
  }

  const setPaymentType = (option) => {
    const order = JSON.parse(localStorage.getItem('order'));
    order.paymentMethod = option;
    localStorage.setItem('order', JSON.stringify(order))
  }

  const handleSelect = (el) => {
    const order = JSON.parse(localStorage.getItem('order'));
    setSelected(el._id);
    props.setLocalCard({ total: order.totalPrice, customerId: el.stripeCustomerId, customer: customer })
  }

  return (
    <div>
      <div className="sm:flex md:flex flex-wrap items-center mt-4">
        <div className="mb-5 ml-3 flex items-center">
          <FiUser className="rounded-full w-10 h-10 flex items-center justify-center bg-prim text-white p-2" />
          <div className="px-4 flex-grow">
            <p className="text-lg sm:text-2xl md:text-2xl">Payment Options</p>
          </div>
        </div>
        <div className="sm:flex md:flex flex-wrap items-center w-full">
          {props.options?.map((op, i) => (
            <div key={i} className="p-2 px-3 w-full sm:w-1/2 md:w-1/2">
              {op === "Card" && (
                <div
                  className={cardNum === i ? "flex items-center justify-center text-sec rounded p-4 cursor-pointer border border-red-700 bg-gray-300 " : "flex items-center justify-center text-sec rounded p-4 cursor-pointer border border-red-700 "}
                  onClick={() => handleSelectCard(op, i)}
                >
                  <p
                    style={props.style}
                    className={`text-4xl font-thin italic ${props.classes}`}
                  >
                    Card
                  </p>
                </div>
              )}
              {op !== "Card" && (
                <div
                  className={cardNum === i ? "flex items-center justify-center text-sec rounded p-4 cursor-pointer border border-red-700 bg-gray-300" : "flex items-center justify-center text-sec rounded p-4 cursor-pointer border border-red-700 "}
                  onClick={() => handleSelectCash(op, i)}
                >
                  <p
                    style={props.style}
                    className={`text-4xl font-thin italic ${props.classes}`}
                  >
                    {op}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {cards && card && cards.length === 0 ? (
        <div>
          <CardInfo setCheck={props.setCheck} check={props.check} />
        </div>
      ) : card ? (
        <>
          {cards?.map((el, index) => (
            <div onClick={() => handleSelect(el)} className={`mt-3 bg-white border-2 border-red-700 py-3 px-3 rounded cursor-pointer ${selected === el._id ? "bg-gray-300 rounded text-black" : ""} ${selected ? "cursor-pointer" : ""}`} key={index} >
              <div className="flex justify-between items-center">
                <div className="flex">
                  <div className="flex items-center">
                    <div className="ml-3">
                      <img src={
                        (JSON.parse(el.stripeCard)?.brand === "Visa") ? VisaImg : (JSON.parse(el.stripeCard)?.brand === "MasterCard") ? MasterImg : (JSON.parse(el.stripeCard)?.brand === "American Express") ? AmexImg : (JSON.parse(el.stripeCard)?.brand === "Discover") ? DiscoverImg : (JSON.parse(el.stripeCard)?.brand === "Diners Club") ? DinersImg : (JSON.parse(el.stripeCard)?.brand === "JCB") ? JcbImg : (JSON.parse(el.stripeCard)?.brand === "UnionPay") ? UnionImg : null} alt="visa card" className="h-12 w-12" />
                    </div>
                  </div>
                  <div className={`ml-4 p-1`}>
                    <h3 className="block font-semibold">{JSON.parse(el.stripeCard)?.brand}****{JSON.parse(el.stripeCard)?.last4}</h3>
                    <h4 className="text-gray-700 block">Expires {JSON.parse(el.stripeCard)?.exp_month} {JSON.parse(el.stripeCard)?.exp_year}</h4>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
}
