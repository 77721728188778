import React from 'react';

export default function RestaurantDetails({ restaurant }) {
    const restaurantTiming = restaurant?.restaurantTiming?.restaurantTiming;
    const date = new Date();
    const closeOrOpen = restaurantTiming?.available?.find((it) => it.day === date.getDay());
    const amOrPm = (time) => {
      const hours = time.split(':')[0];
      const minutes = time.split(':')[1];
      const amOrPm = hours >= 12 ? 'PM' : 'AM';
      return `${hours % 12 || 12}:${minutes} ${amOrPm}`;
  }
  const start=new Date(Date.now()).setHours(closeOrOpen?.startTime.split(':')[0],closeOrOpen?.startTime.split(':')[1]);
  const end=new Date(Date.now()).setHours(closeOrOpen?.endTime.split(':')[0],closeOrOpen?.endTime.split(':')[1]);
  const currentTime=new Date(Date.now());
  let available=false;
  (closeOrOpen?.isOpen)?(start<currentTime && end>currentTime)?available=true:available=false:available=false;
  // console.log(available, "available", start, end, currentTime, closeOrOpen);
  
  return (
    <div className="w-full truncate px-2">
      <div className="flex items-center">
        <p className="text-md sm:text-xl md:text-xl font-semibold text-sec truncate">
          {restaurant.name}
        </p>

        <div className="px-4 text-center">
        {(restaurant.discount?.discountValue)?<p className="text-sm md:text-2xl text-prim">
            <span>{restaurant.discount?.discountValue}% </span>
            <span className="text-sm md:text-xl">off </span>
          </p>:null}
        </div>
      </div>
      <div className="sm:flex md:flex items-center">
        <p className="py-1 text-gray-600 text-sm font-normal truncate">
          {restaurant.address ? restaurant.address.address : null},{' '}
          {restaurant.address ? restaurant.address.postCode : null}
        </p>
      </div>
      {/* <div className="sm:flex md:flex items-center">
        <p className="py-1 text-gray-600 text-sm font-normal truncate">
          {restaurant.postCode ? restaurant.postCode : null}
        </p>
      </div> */}
      <div className="w-full flex">
        {available? (
          <p className="text-xs text-gray-600">
            {amOrPm(closeOrOpen.startTime)} to {amOrPm(closeOrOpen.endTime)}
          </p>
        ) : (
          <p className="text-prim font-bold">Closed, <span className="text-green-600">Booking Only</span></p>
        )}
      </div>
    </div>
  );
}
