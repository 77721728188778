import React, { useState } from "react";

import CheckoutTableNumberField from './CheckoutTableNumberField';
import { BiFoodMenu } from "react-icons/bi";
import Icon from "../../../components/utilities/Icon";
import { FiEdit2, FiX } from "react-icons/fi";

const CheckoutTableNumber = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [tableNo, setTableNo] = useState(localStorage.getItem("tableNo") || null)
  return (
    <div>
      <div className="flex items-center px-2">
        <BiFoodMenu className="rounded-full w-10 h-10 flex items-center justify-center bg-prim text-white p-2" />
        <div className="px-4 flex-grow">
          <p className="text-lg sm:text-2xl md:text-2xl">Table Number</p>
        </div>
        {!isEdit && (
          <Icon onClick={() => setIsEdit(true)} icon={FiEdit2} />
        )}
        {isEdit && (
          <Icon onClick={() => setIsEdit(false)} icon={FiX} />
        )}
      </div>
      <div className="ml-6 mt-2 font-semibold text-xl">
        {tableNo ? tableNo : "Enter a table no "}
      </div>
      <CheckoutTableNumberField setTableNo={setTableNo} isEdit={isEdit} setIsEdit={setIsEdit} />
      {isEdit && <CheckoutTableNumberField setIsEdit={setIsEdit} />}
    </div>
  );
};

export default CheckoutTableNumber;
