import React, { useState } from 'react';
import {useDispatch} from 'react-redux';
import { getCookie } from '../../../components/utilities/cookie/cookie';
import { updateCustomer } from '../../../redux/actions/customer';

export default function UserNameUpdate({customer}) {
    const dispatch = useDispatch();
    
    const [isEitable,setIsEditable]=useState(false);

    const initialState={
        name:"",
        customerId:customer._id
    }

  
  
    const [formData,setFormData]=useState(initialState);

    const token=getCookie('token');

    const handelChange = (e) => {
        
        setFormData({name:e.target.value,customerId:customer._id});
        
    }

    const {name}=formData;

    const handleSubmitForm=()=>{
        
        updateCustomer(dispatch,{formData,token});
        setIsEditable(false);
    }


    
    return (
        <>
            <div className="name-div">
                {
                  !isEitable?(
                    <p className="customer-name">{customer.name}</p>
                  ):(
                    <input type="text" className="name-input" name={name} value={name} onChange={handelChange}/>
                  )
                }
                {
                  isEitable?(
                    <button onClick={handleSubmitForm} className="address-submit name">Save</button>
                  ):(
                    <button onClick={()=>setIsEditable(true)} className="address-submit name">Edit</button>
                  )
                }
               
            </div>
        </>
    )
}
