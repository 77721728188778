import { FiFacebook, FiInstagram, FiTwitter, FiYoutube } from "react-icons/fi";
import restaurant from "../../../images/location.png"
import table from "../../../images/table.png"
import booking from "../../../images/booking.png"
export const footerCardData = [
    {
        id: 1,
        title: "Find",
        desc: "Favourite Dine",
        // img: "https://foodapps.uk/images/assets/restaurant-new.png",
        img:restaurant
    },
    {
        id: 2,
        title: "Book",
        desc: "Your Table",
        img: table,
    },
    {
        id: 3,
        title: "Enjoy",
        desc: "Your Dinner",
        img: booking,
    }
]

export const footerLinks = [
    { id: 1, text: 'Terms and Conditions', url: '/user-policy' },
    { id: 2, text: 'FAQs', url: '/user-faq' },
    { id: 3, text: 'Our Packages', url: '/our-packages' },
    // { id: 4, text: 'Chat', url: '/' }
    // {id}
];
export const socialMedia = [
    { bg: 'facebook', url: 'https://facebook.com/foodappsuk', icon: FiFacebook },
    { bg: 'instagram', url: 'https://instagram.com/foodappsuk', icon: FiInstagram },
    { bg: 'twitter', url: 'https://twitter.com/foodappsuk', icon: FiTwitter },
    { bg: 'youtube', url: 'https://www.youtube.com/channel/UCDCwDA873ighpp3TlD0Bakw', icon: FiYoutube },
];