import React from "react";

import RestuantContent from "./ResturantContent";

export default function Restaurants({ restHeading, restaurants, error, status }) {

  return (
    <div className="bg-nat xs:flex-col sm:flex md:flex" id="restaurants">
      <div className="w-full py-5 px-2 md:px-12 md:mx-3 flex flex-wrap items-center flex-col">
        <div className="px-5 text-xl pb-3">
          <p className="font-semibold">{restHeading}</p>
        </div>
        <div className="pt-2 w-full">
          {status === 'success' && restaurants.length > 0 ? (
            restaurants.map((restaurant, index) => (
              <RestuantContent
                restaurant={restaurant.restaurant}
                key={index}
              />
            ))
          ) :
            status === 'success' && restaurants.length === 0?
            <span className="text-red-700 font-bold text-lg">No Restaurants Found!</span>
            :
           status === "Failed" ?
              <span className="text-red-700 font-bold text-lg">{error}!!</span>
              :
              <span className="text-red-700 font-bold text-lg">Loading</span>
          }
        </div>
      </div>
      {/* {restaurants && restaurants.length > 0 ? (
        restaurants.map((restaurant, index) => (
          <BookingPopup
            setOpenModal={setOpenModal}
            restaurant={restaurant}
            key={index}
          />
        ))
      ) : (
        <span className="text-red-700 font-bold text-lg">{error}!!</span>
      )} */}
      {/* {restaurants && (
        restaurants.map((restaurant) => (
          <BookingPopup
            openModal={openModal}
            setOpenModal={setOpenModal}
            restaurant={restaurant}
          />
        ))
      )} */}
    </div>
  );
}
