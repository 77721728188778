import React from 'react';
import { BrowserRouter as Router, Switch } from "react-router-dom";
// Routes
import userRoutes from './UserRoutes';
// Components
import RouteWithSubRoutes from './RouteWithSubRoutes';
import PrivateRoute from './PrivateRoute';

import userPrivateRoute from './userPrivateRoute';

const MasterRoute = () => {
    return (
        <Router>
            <Switch>
                {userRoutes.map((route, i) => <RouteWithSubRoutes key={i} {...route} />)}
                {userPrivateRoute.map((route,i)=><PrivateRoute key={i} {...route} />)}
            </Switch>
        </Router>
    )
}

export default MasterRoute;
