import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import BannerCard from "../../../components/utilities/cards/BannerCard";
import { getAllCarousels } from "../../../redux/actions/carousels";

export default function Banner() {
  const dispatch = useDispatch();
  const type = "banner";
  useEffect(() => {
    getAllCarousels(dispatch, type);
  }, [dispatch, type]);

  const { carousels } = useSelector((state) => state.carousels);
  return (
    <div className="hidden sm:inline md:inline inherit z-10 border-b">
      <div className="my-6 mx-10 flex">
        {carousels?.map((data) => (
          <BannerCard
            key={data._id}
            // onClick={() => alert("Button Clicked!")}
            title={data.title}
            buttonText={data.buttonTitle}
            img={data.image}
            backgroundColor={data.backgroundColor}
            buttonTextColor={data.buttonTextColor}
            route={data.link}
            alt={data.title}
          />
        ))}
      </div>
    </div>
  );
}
