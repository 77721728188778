import React from 'react';
import Modal from 'react-modal';

export const customStyles = {
    content : {
      top                   : '5px',
      left                  : 'auto',
      right                 : 'auto',
      bottom                : 'auto',
      zIndex                : '100'
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, .8)'
      },
      modal: {
          outline: "none"
      }
 };

export default function PopupLayout({ openModal, setOpenModal },props) {
    return (
        <Modal
            isOpen={openModal}
            style={customStyles}
            className="w-full outline-none absolute border border-none z-100"
        >
            <div className="w-full h-screen">
                <div className="w-full h-full flex items-center justify-center">
                    {props.children}
                </div>
            </div>
        </Modal>
    )
}
