import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import CategoryCard from "../../../components/utilities/cards/CategoryCard";
import Heading from "../../../components/utilities/Heading";

import { getAllCuisines } from "../../../redux/actions/cuisine";
// const fakeData = [
//   {
//     restaurant: {
//       name: "The Kitchen",
//       id: ["5f0e8f9b9d8f8e0017c8f8f6" ,"5f0e8f9b9d8f8e0017c8f8f7"],
//     },
//   },
//    {
//      restaurant: {
//       name: "The Kitchen",
//       id: [ "5f0e8f9b9d8f8e0017c8f8f7" , "5f0e8f9b9d8f8e0017c8f8f8" ],
//     },
//   },
//    {
//      restaurant: {
//       name: "The Kitchen",
//       id: ["5f0e8f9b9d8f8e0017c8f8f6","5f0e8f9b9d8f8e0017c8f8f7"],
//     }
//   }
// ]
export default function CuisineSlider({ allRestaurants, setAllRestaurants }) {
  const handleCuisine = (id) => {
    const data = allRestaurants.filter((item) =>
      item.restaurant.cuisines.includes(id)
    );
    setAllRestaurants(data);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    getAllCuisines(dispatch);
  }, [dispatch]);

  const { cuisines } = useSelector((state) => state?.cuisines);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };
  return (
    <div className="pb-4">
      <div className="flex items-center flex-wrap pb-4">
        <div className="w-full pb-3 px-6">
          <Heading>Browse your flavour</Heading>
          <Carousel
            renderButtonGroupOutside={true}
            responsive={responsive}
            infinite={true}
            arrows={true}
            slidesToSlide={1}
            autoPlay={false}
            transitionDuration={100}
            minimumTouchDrag={5}
            removeArrowOnDeviceType={["tablet", "mobile"]}
          >
            {cuisines?.map((data, index) => (
              <div className="w-full" key={index}>
                <CategoryCard
                  title={data.type}
                  nnnn
                  img={data.banner}
                  id={data._id}
                  onClick={() => handleCuisine(data._id)}
                />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}
