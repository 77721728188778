import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";
import {
  GET_RESTAURANT_INFO_FAILURE,
  GET_RESTAURANT_INFO_SUCCESS,
  GET_ALL_RESTAURANTS_INFO_SUCCESS,
  GET_SINGLE_RESTAURANTS_INFO_SUCCESS,
  GET_SINGLE_RESTAURANTS_INFO_FAILURE,
} from "../types/restaurantInfo/type";

export const getAllRestaurants = async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant/get-all-restaurants`,
    );

    if (data) {
      dispatch({ type: GET_ALL_RESTAURANTS_INFO_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_INFO_FAILURE, payload: error });
  }
};

export const createRestaurant = async (dispatch, formData, token) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant/create-restaurant`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_RESTAURANT_INFO_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_INFO_FAILURE, payload: error });
  }
};

export const getRestaurantOwnInfo = async (dispatch, token) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant/get-own-restaurant-info`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_RESTAURANT_INFO_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_INFO_FAILURE, payload: error });
  }
};

export const updateRestaurant = async (
  dispatch,
  formData,
  restaurantInfoId,
  token,
) => {
  try {
    const {
      data: { data },
    } = await axios.put(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant/update-restaurant/${restaurantInfoId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_RESTAURANT_INFO_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_INFO_FAILURE, payload: error });
  }
};

export const uploadImage = async (dispatch, formData, token) => {
  try {
    const {
      data: { data },
    } = await axios.put(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant/upload-image`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_RESTAURANT_INFO_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_INFO_FAILURE, payload: error });
  }
};

export const getRestaurantsByPostCode = async (dispatch, formData) => {
  console.log(formData);
  try {
    const {
      data: { data },
    } = await axios.post(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant/search-restaurant-by-post-code?postCode=${formData.postCode}`,
    );
    if (data) {
      dispatch({ type: GET_ALL_RESTAURANTS_INFO_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_INFO_FAILURE, payload: error });
  }
};


export const getRestaurantsByLocaiton = async (dispatch, locationData) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant/search-restaurant-by-location`, locationData
    );
    if (data) {
      dispatch({ type: GET_ALL_RESTAURANTS_INFO_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_INFO_FAILURE, payload: error });
  }
};

export const getSingleRestaurant = async (dispatch, restaurantId) => {
  try {
    const { data: { data } } = await axios.get(`${apiBaseUrl}${apiVersionPrefix}/restaurant/get-restaurant-info/${restaurantId}`);
    if (data) {
      dispatch({ type: GET_SINGLE_RESTAURANTS_INFO_SUCCESS, payload: data })
    }
  } catch (err) {
    const { response: { data: { error } } } = err;
    dispatch({ type: GET_SINGLE_RESTAURANTS_INFO_FAILURE, payload: error });
  }
}