import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getDeliveryCharge } from "../../../redux/actions/restaurantDelivery";

const CheckoutDeliveryAddressField = ({ order, setOrder, isEdit, setIsEdit }) => {
  const [formData, setFormData] = useState({
    deliveredAt: "",
    postCode: ""
  })

  const dispatch = useDispatch();

  const { deliveryCharge } = useSelector(state => state.restaurantDelivery)

  const { restaurantInfo } = useSelector(state => state.restaurantInfo);

  const customer = JSON.parse(localStorage.getItem('customer'))

  const [mount, setMount] = useState(true);


  const [deliveryFormData, setDeliveryFormData] = useState({
    postCode: customer.address ? customer.address?.postCode : "",
    restaurantPostCode: restaurantInfo?.address?.postCode,
    restaurant: restaurantInfo._id

  })

  useEffect(() => {
    getDeliveryCharge(dispatch, deliveryFormData)
  }, [dispatch, deliveryFormData]);

  useEffect(() => {
    const customer = JSON.parse(localStorage.getItem('customer'))
    let localOrder = JSON.parse(localStorage.getItem('order'));
    if (mount) {
      localOrder = {
        ...localOrder,
        deliveredAt: customer.address ? customer.address?.address : "",
        postCode: customer.address ? customer.address?.postCode : "",
        deliveryCharge: deliveryCharge
      };
    } else {
      localOrder = {
        ...localOrder,
        deliveryCharge: deliveryCharge
      };
    }
    localStorage.setItem('order', JSON.stringify(localOrder));
    setOrder(localOrder)
  }, [deliveryCharge, setOrder, mount])

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // setDeliveryFormData({ ...deliveryFormData, [e.target.name]: e.target.value });
  }

  const handleClick = (e) => {
    e.preventDefault();
    let localOrder = JSON.parse(localStorage.getItem('order'));
    localOrder = {
      ...localOrder, deliveredAt: formData.address, postCode: formData.postCode
    };
    localStorage.setItem('order', JSON.stringify(localOrder));
    setOrder(localOrder)
    setDeliveryFormData({ ...deliveryFormData, postCode: formData.postCode })
    getDeliveryCharge(dispatch, { ...deliveryFormData, postCode: formData.postCode })
    setIsEdit(false)
    setMount(false)
  }

  return (
    <div className="sm:flex md:flex flex-wrap items-center mt-8">
      <div className="p-2 w-full sm:w-2/3 md:2/3">
          {!isEdit ? 
          <h1 className="mx-2">{order.deliveredAt ? order.deliveredAt : "Enter An Address "} <br />{order.postCode ? order.postCode : "Enter A Post code "}</h1>
          :
          <div className="ml-14">
            <input type="text" className="border-2 border-prim rounded p-2 mx-1 mb-2" placeholder="Address" value={formData.address} onChange={handleChange} name="address" />
  
            <input type="text" className="border-2 border-prim rounded p-2 mx-1" value={formData.postCode} onChange={handleChange} placeholder="Post Code" name="postCode" />
            <button className="bg-prim px-5 py-2 rounded text-white mx-1" onClick={handleClick}>Add</button>
          </div>
          }
      </div>
    </div>
  );
};

export default CheckoutDeliveryAddressField;
