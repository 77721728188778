import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useDispatch } from 'react-redux';

import UserLayout from '../../../components/layout/UserLayout';
import { getTermsAndCondition } from '../../../redux/actions/termsAndConditions';

import "./index.css";
import UserPolicy from './UserPolicy';

export default function UserTermsOfUse() {
    const dispatch = useDispatch();

    useEffect(() => {
        getTermsAndCondition(dispatch, "User");
    }, [dispatch])

    return (
        <HelmetProvider>
            <Helmet>
                <title>FoodApps | Terms of Use</title>
            </Helmet>
            <UserLayout>
                <UserPolicy />
            </UserLayout>
        </HelmetProvider>
    )
}